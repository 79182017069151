// src/services/ContenedorService.js
import axiosInstance from '../utils/axiosInstance';
import config from '../config';

const API_URL = `${config.API_URL}/contenedores`;

class ContenedorService {
    obtenerTodos() {
        return axiosInstance.get(API_URL);
    }

    obtenerPorId(id) {
        return axiosInstance.get(`${API_URL}/${id}`);
    }

    guardar(contenedor) {
        return axiosInstance.post(API_URL, contenedor);
    }

    actualizar(id, contenedor) {
        return axiosInstance.put(`${API_URL}/${id}`, contenedor);
    }

    eliminar(id) {
        return axiosInstance.delete(`${API_URL}/${id}`);
    }

    // Métodos para gestionar reportes embebidos en contenedor
    obtenerReportes(contenedorId) {
        return axiosInstance.get(`${API_URL}/${contenedorId}/reportes`);
    }

    agregarReporte(contenedorId, reporte) {
        return axiosInstance.post(`${API_URL}/${contenedorId}/reportes`, reporte);
    }

    eliminarReporte(contenedorId, reporteId) {
        return axiosInstance.delete(`${API_URL}/${contenedorId}/reportes/${reporteId}`);
    }

    // Métod para actualizar un reporte dentro de un contenedor
    actualizarReporte(contenedorId, reporteId, reporteActualizado) {
        return axiosInstance.put(`${API_URL}/${contenedorId}/reportes/${reporteId}`, reporteActualizado);
    }

    obtenerAlquileresDeContenedor(id) {
        return axiosInstance.get(`${API_URL}/${id}/alquileres`);
    }

    obtenerAlquileresVinculados(id) {
        return axiosInstance.get(`${API_URL}/${id}/alquileres-vinculados`);
    }

}

export default new ContenedorService();
