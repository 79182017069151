import React, { useState, useEffect } from 'react';
import PagoService from '../../services/PagoService';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Upload, Typography, message, Space } from 'antd';
import { UploadOutlined, RightOutlined } from "@ant-design/icons";
import axiosInstanceMultipart from '../../utils/axiosInstanceMultipart';

const { Title } = Typography;

const PagoForm = () => {
    const { idPago } = useParams();
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [alquiler, setAlquiler] = useState('');

    useEffect(() => {
        if (idPago) {
            PagoService.obtenerPorId(idPago)
                .then(response => {
                    const data = response.data;

                    form.setFieldsValue({
                        fechaPago: data.fechaPago,
                        montoPagado: data.montoPagado,
                        comentarios: data.comentarios || "",
                    });

                    if (data.alquiler) setAlquiler(data.alquiler);

                    // 👇 Cargar comprobante existente
                    if (data.urlComprobante) {
                        setFileList([
                            {
                                uid: "-1",
                                name: "comprobante.jpg",
                                status: "done",
                                url: data.urlComprobante,
                            },
                        ]);
                    }
                })
                .catch(() => {
                    setError(true);
                    message.error('Error al obtener el pago');
                    navigate("/pagos");
                })
                .finally(() => setLoading(false));
        } else {
            form.setFieldsValue({
                fechaPago: new Date().toISOString().split('T')[0]
            });
            setLoading(false);
        }
    }, [idPago, form, navigate]);


    const handleUploadChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const onFinish = async (values) => {
        try {
            const formData = new FormData();
            formData.append("alquiler.id", alquiler?.id);
            formData.append("montoPagado", values.montoPagado);
            formData.append("fechaPago", values.fechaPago);
            formData.append("comentarios", values.comentarios || "");

            if (fileList.length > 0 && fileList[0].originFileObj) {
                formData.append("comprobante", fileList[0].originFileObj);
            }

            if (idPago) {
                await axiosInstanceMultipart.put(`/pagos/${idPago}`, formData);
                message.success("Pago actualizado exitosamente");
            } else {
                await axiosInstanceMultipart.post("/pagos", formData);
                message.success("Pago registrado exitosamente");
            }

            navigate('/pagos');
        } catch (error) {
            message.error("Error al guardar el pago");
        }
    };

    if (loading) return <p style={{ padding: 20 }}>Cargando...</p>;
    if (error) return <p style={{ padding: 20, color: "red" }}>No se pudo cargar el pago.</p>;

    return (
        <div style={{ maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem' }}>
            {idPago && <Title level={2}>Editar pago</Title>}

            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item label="Alquiler">
                    <Space.Compact style={{ width: '100%' }}>
                        <Input value={alquiler?.empresa?.razonSocial || "Sin datos"} disabled />
                        <Button
                            icon={<RightOutlined />}
                            onClick={() => navigate(`/alquileres/${alquiler?.id}`)}
                        />
                    </Space.Compact>
                </Form.Item>

                <Form.Item name="montoPagado" label="Monto" rules={[{ required: true, message: "Campo obligatorio" }]}>
                    <Input type="number" min={0} prefix="$" />
                </Form.Item>

                <Form.Item name="fechaPago" label="Fecha" rules={[{ required: true, message: "Campo obligatorio" }]}>
                    <Input type="date" />
                </Form.Item>

                <Form.Item name="comprobante" label="Comprobante">
                    <Upload
                        beforeUpload={() => false}
                        fileList={fileList}
                        onChange={handleUploadChange}
                        maxCount={1}
                        listType="text"
                    >
                        <Button icon={<UploadOutlined />} type={fileList.length > 0 ? "dashed" : "primary"}>
                            {fileList.length > 0 ? "Modificar comprobante" : "Subir comprobante"}
                        </Button>
                    </Upload>
                </Form.Item>



                <Form.Item name="comentarios" label="Comentarios">
                    <Input.TextArea rows={3} />
                </Form.Item>

                <Button type="primary" htmlType="submit">Guardar</Button>
            </Form>
        </div>
    );
};

export default PagoForm;
