// src/components/MarcadorSimpleMapa.jsx
import React from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';

const MarcadorSimpleMapa = ({ lat, lon, iconUrl = "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png" }) => {
    const customIcon = new L.Icon({
        iconUrl,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
        shadowSize: [41, 41],
    });

    return lat && lon ? (
        <Marker position={[lat, lon]} icon={customIcon} />
    ) : null;
};

export default MarcadorSimpleMapa;
