import React, { useEffect, useState } from "react";
import { Form, InputNumber, Button, Typography, Switch, message } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useThemeMode } from "../../utils/ThemeContext";
import ConfiguracionSistemaService from "../../services/ConfiguracionSistemaService";

const { Title, Paragraph } = Typography;

const ConfiguracionSistema = () => {
    const navigate = useNavigate();
    const { isDarkMode, toggleTheme } = useThemeMode();

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        setLoading(true);
        ConfiguracionSistemaService.obtener()
            .then(res => {
                form.setFieldsValue(res.data);
            })
            .catch(() => {
                message.error("No se pudo cargar la configuración");
            })
            .finally(() => setLoading(false));
    }, [form]);

    const onFinish = (values) => {
        setSaving(true);
        ConfiguracionSistemaService.actualizar(values)
            .then(() => {
                message.success("Configuración guardada correctamente");
            })
            .catch(() => {
                message.error("Error al guardar la configuración");
            })
            .finally(() => setSaving(false));
    };

    return (
        <div style={{maxWidth: 450, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2}>Configuración del sistema</Title>

            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    label="Días de anticipación para generación de período"
                    name="diasGeneracionPeriodo"
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                >
                    <InputNumber min={0} />
                </Form.Item>

                <Form.Item
                    label="Penalidad por mora (%)"
                    name="penalidadMoraPorcentual"
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                >
                    <InputNumber min={0} max={100} step={0.1} />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={saving}>
                        Guardar configuración
                    </Button>
                </Form.Item>

                <Paragraph>Modo visual (claro/oscuro)</Paragraph>
                <Switch
                    checked={isDarkMode}
                    onChange={toggleTheme}
                    checkedChildren="Oscuro"
                    unCheckedChildren="Claro"
                />
            </Form>

            <div style={{ marginTop: "3rem" }}>
                <Button
                    type="link"
                    icon={<ArrowLeftOutlined />}
                    onClick={() => navigate("/home")}
                >
                    Volver al inicio
                </Button>
            </div>
        </div>
    );
};

export default ConfiguracionSistema;
