import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Upload, Typography, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axiosInstanceMultipart from '../../utils/axiosInstanceMultipart';

const PagoForm = ({ alquiler }) => {
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        form.setFieldsValue({
            montoPagado: alquiler.montoInicial,
            fechaPago: new Date().toISOString().split('T')[0]
        });
    }, [form]);

    const handleUploadChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const onFinish = async (values) => {
        try {
            const formData = new FormData();
            formData.append("alquiler.id", alquiler?.id);
            formData.append("montoPagado", values.montoPagado);
            formData.append("fechaPago", values.fechaPago);
            formData.append("comentarios", values.comentarios || "");

            if (fileList.length > 0 && fileList[0].originFileObj) {
                formData.append("comprobante", fileList[0].originFileObj);
            }

            await axiosInstanceMultipart.post("/pagos", formData);
            message.success("Pago registrado exitosamente");
            navigate('/pagos');
        } catch (error) {
            message.error("Error al guardar el pago: " + error);
        }
    };

    return (
        <div style={{maxWidth: 450, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="montoPagado"
                    label="Monto"
                    rules={[{ required: true, message: "Campo obligatorio" }]}
                >
                    <Input type="number" min={0} prefix="$" />
                </Form.Item>

                <Form.Item
                    name="fechaPago"
                    label="Fecha"
                    rules={[{ required: true, message: "Campo obligatorio" }]}
                >
                    <Input type="date" />
                </Form.Item>

                <Form.Item name="comprobante" label="Comprobante">
                    <Upload
                        beforeUpload={() => false}
                        fileList={fileList}
                        onChange={handleUploadChange}
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>
                            {fileList.length > 0 ? "Modificar comprobante" : "Subir comprobante"}
                        </Button>
                    </Upload>
                </Form.Item>

                <Form.Item name="comentarios" label="Comentarios">
                    <Input.TextArea rows={3} />
                </Form.Item>

                <Button type="primary" htmlType="submit">Guardar</Button>
            </Form>
        </div>
    );
};

export default PagoForm;
