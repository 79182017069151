import React, { useState, useEffect } from "react";
import PagoService from "../../services/PagoService";
import { List, Typography, Button, message } from "antd";
import PagoListView from "./sub/PagoListView";
import PagoChartView from "./sub/PagoChartView";

const { Title } = Typography;

const PagoList = () => {
    const [pagos, setPagos] = useState([]);

    useEffect(() => {
        PagoService.obtenerTodos()
            .then(response => {
                setPagos(response.data);
            })
            .catch(error => {
                message.error("Error al obtener la lista de pagos");
            });
    }, []);

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <PagoChartView pagos={pagos} /> {/* Gráfico con botón flotante */}
            <Title level={2}>Pagos realizados</Title>
            <PagoListView pagos={pagos} />
        </div>
    );
};

export default PagoList;
