import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ConfigProvider, theme } from 'antd';

import { ThemeContext } from './utils/ThemeContext'; // nuevo

// Componentes...
import LoginPage from './components/LoginPage';
import ConfigPage from './components/configuracion/ConfigPage';
import LandingPage from './components/LandingPage';
import HomePage from './components/HomePage';

import ModeloContratoList from './components/modelo-contrato/ModeloContratoList';
import ModeloContratoForm from './components/modelo-contrato/ModeloContratoForm';

import ComentarioList from './components/ComentarioList';
import ComentarioForm from './components/ComentarioForm';

import ListAlquiler from './components/alquiler/ListAlquiler';
import ViewAlquiler from './components/alquiler/ViewAlquiler';
import ContratoDoc from './components/ContratoDoc';

import ClienteList from './components/cliente/ClienteList';
import ClienteForm from './components/cliente/ClienteForm';

import EmpresaList from './components/empresa/EmpresaList';
import EmpresaForm from './components/empresa/EmpresaForm';

import PagoList from './components/pago/PagoList';
import PagoForm from './components/pago/PagoForm';

import ContenedorList from './components/contenedor/ContenedorList';
import ContenedorForm from './components/contenedor/ContenedorForm';
import ReporteList from './components/ReporteList';

import FacturaList from './components/FacturaList';
import FacturaForm from './components/FacturaForm';
import FacturaCDoc from './components/FacturaCDoc';

import EmisorList from './components/emisor/EmisorList';
import EmisorForm from './components/emisor/EmisorForm';

import PresupuestoList from './components/presupuesto/PresupuestoList';
import PresupuestoForm from './components/presupuesto/PresupuestoForm';
import PresupuestoDoc from './components/presupuesto/PresupuestoDoc';

import PrivateRoute from './components/PrivateRoute';
import ProtectedLayout from './components/layout/ProtectedLayout';
import PrintableProtectedLayout from './components/layout/PrintableProtectedLayout';

import MapaDirecciones from './components/mapa/MapaDirecciones';

import Unauthorized from './components/Unauthorized';
import AltaClienteView from './components/solicitudes-cliente/AltaClienteView';
import AltaClienteList from './components/solicitudes-cliente/AltaClienteList';
import SolicitudClienteForm from './components/solicitudes-cliente/SolicitudClienteForm';
import NuevoAlquiler from './components/alquiler/NuevoAlquiler';
import PagoView from './components/pago/PagoView';

function App() {
    const savedTheme = localStorage.getItem('theme');
    const initialTheme = savedTheme === 'dark';
    const [isDarkMode, setIsDarkMode] = useState(initialTheme);

    useEffect(() => {
        localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');

        document.body.style.backgroundColor = isDarkMode ? '#141414' : '#ffffff';
        document.body.style.color = isDarkMode ? '#ffffff' : '#000000';
    }, [isDarkMode]);

    const toggleTheme = () => setIsDarkMode(prev => !prev);

    return (
        <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
            <ConfigProvider
                theme={{
                    algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
                    token: {
                        colorBgBase: isDarkMode ? '#141414' : '#ffffff',
                        colorTextBase: isDarkMode ? '#ffffff' : '#000000',
                    },
                }}
            >
                <Router>
                    <Routes>
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/unauthorized" element={<Unauthorized />} />
                        <Route path="/formularios/alta-cliente" element={<AltaClienteView />} />

                        <Route element={<PrivateRoute><PrintableProtectedLayout /></PrivateRoute>}>
                            <Route path="/facturas/:id/ver" element={<FacturaCDoc />} />
                            <Route path="/alquileres/:id/contrato/:idContrato/ver" element={<ContratoDoc />} />
                            <Route path="/presupuestos/:id/ver" element={<PresupuestoDoc />} />
                        </Route>

                        <Route element={<PrivateRoute><ProtectedLayout /></PrivateRoute>}>
                            <Route path="/home" element={<HomePage />} />
                            <Route path="/config" element={<ConfigPage />} />
                            <Route path="/mapa" element={<MapaDirecciones />} />

                            <Route path="/solicitudes/clientes" element={<AltaClienteList />} />
                            <Route path="/solicitudes/clientes/:id" element={<SolicitudClienteForm />} />

                            <Route path="/modelosContrato" element={<ModeloContratoList />} />
                            <Route path="/modelosContrato/nuevo" element={<ModeloContratoForm />} />
                            <Route path="/modelosContrato/:id" element={<ModeloContratoForm />} />

                            <Route path="/comentarios" element={<ComentarioList />} />
                            <Route path="/comentarios/nuevo" element={<ComentarioForm />} />
                            <Route path="/comentarios/:id" element={<ComentarioForm />} />

                            <Route path="/alquileres" element={<ListAlquiler />} />
                            <Route path="/alquileres/nuevo" element={<NuevoAlquiler />} />
                            <Route path="/alquileres/:id" element={<ViewAlquiler />} />

                            <Route path="/clientes" element={<ClienteList />} />
                            <Route path="/clientes/nuevo" element={<ClienteForm />} />
                            <Route path="/clientes/:id" element={<ClienteForm />} />

                            <Route path="/empresas" element={<EmpresaList />} />
                            <Route path="/empresas/nuevo" element={<EmpresaForm />} />
                            <Route path="/empresas/:id" element={<EmpresaForm />} />

                            <Route path="/pagos" element={<PagoList />} />
                            <Route path="/pagos/:idPago" element={<PagoView />} />

                            <Route path="/contenedores" element={<ContenedorList />} />
                            <Route path="/contenedores/nuevo" element={<ContenedorForm />} />
                            <Route path="/contenedores/:id" element={<ContenedorForm />} />

                            <Route path="/reportes" element={<ReporteList />} />

                            <Route path="/facturas" element={<FacturaList />} />
                            <Route path="/facturas/nuevo" element={<FacturaForm />} />
                            <Route path="/facturas/nuevo/alquiler/:idAlquiler" element={<FacturaForm />} />
                            <Route path="/facturas/:id" element={<FacturaForm />} />

                            <Route path="/emisores" element={<EmisorList />} />
                            <Route path="/emisores/nuevo" element={<EmisorForm />} />
                            <Route path="/emisores/:id" element={<EmisorForm />} />

                            <Route path="/presupuestos" element={<PresupuestoList />} />
                            <Route path="/presupuestos/nuevo" element={<PresupuestoForm />} />
                            <Route path="/presupuestos/:id" element={<PresupuestoForm />} />
                        </Route>

                        <Route path="*" element={<Navigate to="/login" />} />
                    </Routes>
                </Router>
            </ConfigProvider>
        </ThemeContext.Provider>
    );
}

export default App;
