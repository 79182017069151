export const obtenerContenedoresConUltimoAlquiler = (alquileres) => {
    const mapa = new Map();

    alquileres.forEach((alquiler) => {
        const idContenedor = alquiler.contenedor.id;
        if (!mapa.has(idContenedor)) {
            mapa.set(idContenedor, []);
        }
        mapa.get(idContenedor).push(alquiler);
    });

    const resultado = [];

    mapa.forEach((alquileresContenedor) => {
        const ordenados = alquileresContenedor.sort(
            (a, b) => new Date(b.fechaInicio) - new Date(a.fechaInicio)
        );
        const contenedor = ordenados[0].contenedor;
        resultado.push({
            ...contenedor,
            ultimoAlquiler: ordenados[0],
        });
    });

    return resultado;
};