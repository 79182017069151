import React, { useState, useEffect } from 'react';
import { Button, Modal, Select, Divider, Spin, message } from 'antd';
import { FileDoneOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
import AlquilerService from "../../../services/AlquilerService";
import ModeloContratoService from "../../../services/ModeloContratoService";
import ContratoUploader from "./ContratoUploader";

const { Option } = Select;

const ContratoModal = ({ alquiler, modeloElegido, setModeloElegido }) => {
    const id = alquiler.id;
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);
    const [modelosContrato, setModelosContrato] = useState([]);
    const [loading, setLoading] = useState(false);

    const abrirModal = async () => {
        setModalVisible(true);
        setLoading(true);
        try {
            const res = await ModeloContratoService.obtenerTodos();
            setModelosContrato(res.data);
        } catch (error) {
            message.error("No se pudieron cargar los modelos de contrato");
        } finally {
            setLoading(false);
        }
    };

    const handleGenerarContrato = () => {
        if (modeloElegido) {
            AlquilerService.verContrato(id, modeloElegido)
                .then(() => navigate(`/alquileres/${id}/contrato/${modeloElegido}/ver`))
                .catch(error => message.error('Error al generar contrato'));
        } else {
            message.warning('Elegí un modelo de contrato.');
        }
    };

    const handleEditarContrato = () => {
        if (modeloElegido) {
            navigate(`/modelosContrato/${modeloElegido}`);
        } else {
            message.warning('Elegí un modelo para editar.');
        }
    };

    return (
        <>
            <Button
                icon={<FileDoneOutlined />}
                style={{ height: '50px' }}
                onClick={abrirModal}
            >
                Contrato
            </Button>

            <Modal
                title="Contrato"
                open={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
                maskClosable
                closable
            >
                {loading ? (
                    <Spin tip="Cargando modelos de contrato..." />
                ) : (
                    <>
                        <Select
                            style={{ width: '100%' }}
                            onChange={value => setModeloElegido(value)}
                            placeholder="Seleccione un modelo de contrato"
                            value={modeloElegido}
                        >
                            {modelosContrato.map(modelo => (
                                <Option key={modelo.id} value={modelo.id}>{modelo.titulo}</Option>
                            ))}
                        </Select>

                        <Button type="primary" onClick={handleGenerarContrato}>
                            Armar contrato
                        </Button>
                        <Button style={{ margin: '0.8rem' }} onClick={handleEditarContrato}>
                            Editar
                        </Button>

                        <Divider />
                        <ContratoUploader id={id} alquiler={alquiler} />
                    </>
                )}
            </Modal>
        </>
    );
};

export default ContratoModal;
