import React, {useEffect, useState} from "react";
import {Button} from "antd";
import {HistoryOutlined} from "@ant-design/icons";
import HistorialContenedorModal from "./HistorialContenedorModal";
import ContenedorService from "../../../services/ContenedorService";

const BotonHistorialContenedorSmallIcon = ({ contenedor }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [alquileres, setAlquileres] = useState([]);

    useEffect(() => {
        if (contenedor?.id) {
            ContenedorService.obtenerAlquileresDeContenedor(contenedor.id).then(res => {
                setAlquileres(res.data);
            });
        }
    }, [contenedor]);

    if (alquileres.length === 0) return null;  // No mostrar el botón si no hay historial

    return (
        <>
            <Button
                icon={<HistoryOutlined />}
                type="text"
                onClick={() => setModalVisible(true)}
                size="small"
            />
            <HistorialContenedorModal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                contenedor={contenedor}
            />
        </>
    );
};


export default BotonHistorialContenedorSmallIcon;
