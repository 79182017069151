import React from 'react';
import { Card, Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import MapaAlquiler from "./MapaAlquiler";

const MapaAlquilerWrapper = ({ coordenadas, cargandoMapa }) => {
    return (
        <Card
            style={{
                marginTop: 16,
                minHeight: 400,
                textAlign: "center",
                position: "relative",
                zIndex: 0 // 🔧 agregá esto si hay problemas con z-index heredados
            }}
            styles={{ body: { padding: 0 } }}
        >

            <div style={{ width: "100%", height: "100%", position: "relative" }}>
                {cargandoMapa ? (
                    <div style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                    }}>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} />} />
                    </div>
                ) : coordenadas ? (
                    <MapaAlquiler coordenadas={coordenadas} />
                ) : (
                    <p style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        margin: 0
                    }}>
                        No hay coordenadas disponibles
                    </p>
                )}
            </div>
        </Card>
    );
};

export default MapaAlquilerWrapper;
