import React from 'react';
import { Typography } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.locale('es');
dayjs.extend(advancedFormat);

const { Text } = Typography;

const TextoProximoPago = ({ fecha, type = "long" }) => {
    const diasFaltantes = dayjs(fecha).diff(dayjs(), 'day');

    const textoRelativo =
        diasFaltantes === 0
            ? 'hoy'
            : diasFaltantes === 1
                ? 'mañana'
                : `en ${diasFaltantes} días`;

    const fechaFormateada = dayjs(fecha)
        .format('dddd, D [de] MMMM [de] YYYY');

    const color = diasFaltantes < 7 ? 'red' : undefined;

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 16,
            }}
        >
            <Text type="secondary" style={{ color: color }}>
                <ClockCircleOutlined style={{ marginRight: 6 }} />
                Próximo pago {textoRelativo}
            </Text>
            {type !== "short" ? (
                <Text type="secondary" style={{ fontStyle: 'italic', opacity: 0.7 }}>
                    (el {fechaFormateada})
                </Text>
            ) : null}
        </div>
    );
};

export default TextoProximoPago;
