// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthService from '../services/AuthService';

function PrivateRoute({ children, requiredRoles }) {
  const user = AuthService.getCurrentUser(); // Asumiendo que AuthService tiene un método para obtener el usuario autenticado.

  if (!AuthService.isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  // Verificar si el usuario tiene uno de los roles requeridos
  if (requiredRoles && !requiredRoles.includes(user.role)) {
    return <Navigate to="/unauthorized" />; // Redirigir a una página de acceso denegado si no tiene el rol adecuado
  }

  return children;
}

export default PrivateRoute;
