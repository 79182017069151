import React from "react";
import { useNavigate } from "react-router-dom";
import BaseSidebarNavbar from "./BaseSideNavbar";

const LandingNavbar = () => {
    const navigate = useNavigate();
    const handleNavigate = (hash) => {
        navigate("/");
        setTimeout(() => {
            window.location.hash = hash;
        }, 100);
    };

    const menuItems = (
        <>
            <a onClick={() => handleNavigate("#home")} className="w3-bar-item w3-button w3-hover-white">Inicio</a>
            <a onClick={() => handleNavigate("#servicios")} className="w3-bar-item w3-button w3-hover-white">Servicios</a>
            <a onClick={() => handleNavigate("#contacto")} className="w3-bar-item w3-button w3-hover-white">Contacto</a>
            <a href="https://wa.me/5491158379669" className="w3-bar-item w3-button w3-hover-teal">WhatsApp</a>
            <a href="https://www.facebook.com/reefersdelsur/" className="w3-bar-item w3-button w3-hover-indigo">Facebook</a>
            <a href="https://www.instagram.com/reefersdelsur" className="w3-bar-item w3-button w3-hover-pink">Instagram</a>
            <a href="/login" className="w3-bar-item w3-button w3-hover-white">Acceso</a>
        </>
    );

    return <BaseSidebarNavbar onNavigate={handleNavigate} menuItems={menuItems} />;
};

export default LandingNavbar;
