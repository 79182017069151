import React, { useState } from 'react';
import { Form, Input, Button, DatePicker, Select, Divider, Spin, message, Modal, Tooltip } from 'antd';
import dayjs from 'dayjs';
import AlquilerService from '../../../services/AlquilerService';
import { CheckCircleTwoTone, CloseCircleTwoTone, EnvironmentOutlined } from '@ant-design/icons';


const { Option } = Select;

const FormNuevoAlquiler = ({ clientes = [], empresas = [], contenedores = [], emisores = [], navigate }) => {
    const [modalVisible, setModalVisible] = useState(false);

    const [alquiler, setAlquiler] = useState({
        clienteId: '',
        empresaId: '',
        contenedorId: '',
        emisorId: '',
        ubicacion: '',
        coordenadas: '',
        montoInicial: '',
        fechaInicio: '',
        fechaFin: '',
    });
    const [cargandoMapa, setCargandoMapa] = useState(false);
    const coordenadasValidas = alquiler.coordenadas && alquiler.coordenadas !== '0, 0';

    const limpiarDireccion = (direccion) => {
        return direccion
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/[^a-zA-Z0-9ñÑ,\s']/g, ' ')
            .replace(/\s+/g, ' ')
            .trim();
    };

    const obtenerCoordenadas = async (direccion) => {
        if (!direccion.trim()) {
            return;
        }

        const direccionLimpia = limpiarDireccion(direccion);
        setCargandoMapa(true);
        try {
            const res = await fetch(
                `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(direccionLimpia)}`
            );
            if (!res.ok) {
                message.error(`Error del servidor (${res.status}). Intenta más tarde.`);
                setAlquiler(prev => ({ ...prev, coordenadas: "0, 0" }));
                return;
            }

            const datos = await res.json();
            if (datos.length > 0) {
                setAlquiler(prev => ({ ...prev, coordenadas: `${datos[0].lat}, ${datos[0].lon}` }));
            } else {
                setAlquiler(prev => ({ ...prev, coordenadas: "0, 0" }));
                message.error("No se encontraron coordenadas para la dirección ingresada.");
            }
        } catch (error) {
            console.error("Error obteniendo coordenadas:", error);
            message.error("Error de conexión al buscar la ubicación.");
            setAlquiler(prev => ({ ...prev, coordenadas: "0, 0" }));
        } finally {
            setCargandoMapa(false);
        }
    };

    const handleSubmit = async () => {
        const alquilerData = {
            ...alquiler,
            cliente: { id: alquiler.clienteId },
            empresa: { id: alquiler.empresaId },
            emisor: { id: alquiler.emisorId },
            contenedor: { id: alquiler.contenedorId },
            ubicacion: alquiler.ubicacion,
            coordenadas: alquiler.coordenadas,
            montoInicial: alquiler.montoInicial,
            fechaInicio: alquiler.fechaInicio,
            fechaFin: alquiler.fechaFin,
        };

        try {
            await AlquilerService.guardar(alquilerData);
            message.success("Alquiler creado correctamente.");
            navigate('/alquileres');
        } catch (error) {
            message.error("Ocurrió un error.");
        }
    };

    return (
        <Form layout="vertical" onFinish={handleSubmit}>
            <Form.Item
                label="Cliente"
                name="clienteId"
                rules={[{ required: true, message: 'Debe seleccionar un cliente.' }]}
            >
                <Select
                    value={alquiler.clienteId || undefined}
                    onChange={(value) => setAlquiler(prev => ({ ...prev, clienteId: value }))}
                    placeholder="Seleccione un cliente"
                >
                    {clientes.map(cliente => (
                        <Option key={cliente.id} value={cliente.id}>
                            {cliente.nombre}
                        </Option>
                    ))}
                </Select>
            </Form.Item>


            <Form.Item
                label="Empresa"
                name="empresaId"
                rules={[{ required: true, message: 'Debe seleccionar una empresa.' }]}
            >
                <Select
                    value={alquiler.empresaId || undefined}
                    onChange={(value) => setAlquiler(prev => ({ ...prev, empresaId: value }))}
                    placeholder="Seleccione una empresa"
                >
                    {empresas.map(empresa => (
                        <Option key={empresa.id} value={empresa.id}>
                            {empresa.razonSocial}
                        </Option>
                    ))}
                </Select>
            </Form.Item>


            <Form.Item
                label="Contenedor"
                name="contenedorId"
                rules={[{ required: true, message: 'Debe seleccionar un contenedor.' }]}
            >
                <Select
                    value={alquiler.contenedorId || undefined}
                    onChange={(value) => setAlquiler(prev => ({ ...prev, contenedorId: value }))}
                    placeholder="Seleccione un contenedor"
                >
                    {contenedores.map(contenedor => (
                        <Option key={contenedor.id} value={contenedor.id}>
                            {contenedor.codigo}
                        </Option>
                    ))}
                </Select>
            </Form.Item>


            <Form.Item
                label="Ubicación"
                name="ubicacion"
                rules={[{ required: true, message: 'Debe ingresar una ubicación.' }]}
            >
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Input
                        value={alquiler.ubicacion || ''}
                        onChange={(e) => setAlquiler(prev => ({ ...prev, ubicacion: e.target.value, coordenadas: '' }))}
                        onBlur={() => obtenerCoordenadas(alquiler.ubicacion)}
                        style={{ flex: 1 }}
                    />
                    {alquiler.coordenadas && alquiler.coordenadas !== '0, 0' ? (
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                    ) : (
                        alquiler.ubicacion && (
                            <CloseCircleTwoTone twoToneColor="#ff4d4f" />
                        )
                    )}
                </div>
                {alquiler.ubicacion && !coordenadasValidas && (
                    <div style={{ color: '#ff4d4f', marginTop: 4 }}>
                        No se pudo validar la ubicación ingresada.
                    </div>
                )}
            </Form.Item>
            {cargandoMapa && <Spin />}
            {alquiler.coordenadas && alquiler.coordenadas !== '0, 0' && (
                <Button
                    icon={<EnvironmentOutlined />}
                    size="small"
                    type="default"
                    onClick={() => setModalVisible(true)}
                >
                    Revisar ubicación
                </Button>
            )}


            <Form.Item
                label="Monto inicial"
                name="montoInicial"
                rules={[{ required: true, message: 'Debe ingresar un monto inicial.' }]}
                style={{marginTop: 16}}
            >
                <Input
                    type="number"
                    prefix="$"
                    value={alquiler.montoInicial || ''}
                    onChange={(e) => setAlquiler(prev => ({ ...prev, montoInicial: e.target.value }))}
                />
            </Form.Item>


            <Form.Item
                label="Fecha de inicio"
                name="fechaInicio"
                rules={[{ required: true, message: 'Debe seleccionar la fecha de inicio.' }]}
            >
                <DatePicker
                    style={{ width: '100%' }}
                    value={alquiler.fechaInicio ? dayjs(alquiler.fechaInicio) : null}
                    onChange={(date) =>
                        setAlquiler(prev => ({
                            ...prev,
                            fechaInicio: date ? date.format('YYYY-MM-DD') : null,
                        }))
                    }
                />
            </Form.Item>


            <Form.Item label="Fecha de fin">
                <DatePicker
                    style={{ width: '100%' }}
                    value={alquiler.fechaFin ? dayjs(alquiler.fechaFin) : null}
                    onChange={(date) =>
                        setAlquiler(prev => ({
                            ...prev,
                            fechaFin: date ? date.format('YYYY-MM-DD') : null,
                        }))
                    }
                />
            </Form.Item>

            <Form.Item
                label="Emisor de factura"
                name="emisorId"
                rules={[{ required: true, message: 'Debe seleccionar un emisor.' }]}
            >
                <Select
                    value={alquiler.emisorId || undefined}
                    onChange={(value) => setAlquiler(prev => ({ ...prev, emisorId: value }))}
                    placeholder="Seleccione un emisor"
                >
                    {emisores.map(emisor => (
                        <Option key={emisor.id} value={emisor.id}>
                            {emisor.razonSocial}
                        </Option>
                    ))}
                </Select>
            </Form.Item>


            <Divider />

            <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
                disabled={!coordenadasValidas || cargandoMapa}
            >
                Guardar
            </Button>



            <Modal
                title="Ubicación en el mapa"
                open={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
                width={600}
            >
                {alquiler.coordenadas && alquiler.coordenadas !== '0, 0' && (
                    <iframe
                        title="mapa"
                        width="100%"
                        height="400"
                        frameBorder="0"
                        style={{ border: 0 }}
                        src={`https://www.openstreetmap.org/export/embed.html?bbox=${parseFloat(alquiler.coordenadas.split(',')[1]) - 0.01},${parseFloat(alquiler.coordenadas.split(',')[0]) - 0.01},${parseFloat(alquiler.coordenadas.split(',')[1]) + 0.01},${parseFloat(alquiler.coordenadas.split(',')[0]) + 0.01}&layer=mapnik&marker=${alquiler.coordenadas}`}
                        allowFullScreen
                    />
                )}
            </Modal>

        </Form>

    );
};

export default FormNuevoAlquiler;
