import React, { useState, useRef, useEffect } from "react";
import { Switch, Divider } from "antd";
import logoSecondary from "../../assets/images/logo_secondary.png";
import "../../assets/css/landingStyles.css";
import { useThemeMode } from "../../utils/ThemeContext";

const BaseSidebarNavbar = ({ onNavigate, menuItems, headerTitle = "Reefers del Sur" }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { isDarkMode, toggleTheme } = useThemeMode();
    const sidebarRef = useRef(null);
    const menuRef = useRef(null);

    const toggleSidebar = () => setSidebarOpen(prev => !prev);
    const closeSidebar = () => setSidebarOpen(false);

    // Cerrar si se hace clic fuera del sidebar
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                sidebarOpen &&
                sidebarRef.current &&
                !sidebarRef.current.contains(event.target)
            ) {
                closeSidebar();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [sidebarOpen]);

    // Cerrar si se hace clic en cualquier ítem del menú
    useEffect(() => {
        const handleClickInsideMenu = (event) => {
            if (sidebarOpen && menuRef.current && menuRef.current.contains(event.target)) {
                closeSidebar();
            }
        };

        menuRef.current?.addEventListener("click", handleClickInsideMenu);
        return () => {
            menuRef.current?.removeEventListener("click", handleClickInsideMenu);
        };
    }, [sidebarOpen]);

    return (
        <>
            <nav
                ref={sidebarRef}
                className={`w3-sidebar w3-blue w3-top w3-large w3-padding ${sidebarOpen ? "open" : "w3-collapse"}`}
                style={{ zIndex: 3, width: 300, fontWeight: "bold" }}
                id="mySidebar"
            >
                <button
                    onClick={closeSidebar}
                    className="w3-button w3-hide-large w3-display-topleft w3-hover-white"
                    style={{ width: "100%", fontSize: "22px" }}
                >
                    ×
                </button>
                <div className="w3-container">
                    <h3>
                        <img
                            src={logoSecondary}
                            alt="Reefers del Sur"
                            style={{ width: "100%", cursor: "pointer" }}
                            onClick={() => onNavigate("/")}
                        />
                    </h3>
                </div>

                <div className="w3-bar-block" ref={menuRef}>
                    {menuItems}
                    <Divider style={{ margin: "10px 0" }} />
                    <div style={{ padding: "1rem", display: "flex", alignItems: "center", gap: "0.5rem" }}>
                        <span>Modo</span>
                        <Switch
                            checked={isDarkMode}
                            onChange={toggleTheme}
                            checkedChildren="Oscuro"
                            unCheckedChildren="Claro"
                        />
                    </div>
                </div>
            </nav>

            <header className="w3-container w3-top w3-hide-large w3-blue w3-xlarge w3-padding" style={{ whiteSpace: "nowrap" }}>
                <button onClick={toggleSidebar} className="w3-button w3-blue w3-margin-right w3-hover-white">
                    ☰
                </button>
                <span className="w3-xxlarge" style={{ cursor: "pointer" }} onClick={() => onNavigate("/")}>
                    {headerTitle}
                </span>
            </header>

            {sidebarOpen && (
                <div
                    className="w3-overlay w3-hide-large w3-hover-white"
                    onClick={closeSidebar}
                    style={{ cursor: "pointer" }}
                    title="Cerrar menú"
                />
            )}
        </>
    );
};

export default BaseSidebarNavbar;
