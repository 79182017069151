import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { List, Space, Input, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const { Title } = Typography;

const SolicitudesClienteView = ({ solicitudes }) => {
    const [busqueda, setBusqueda] = useState('');

    const solicitudesFiltradas = solicitudes.filter(solicitud =>
        solicitud.telefono.toLowerCase().includes(busqueda.toLowerCase()) ||
        solicitud.direccion.toLowerCase().includes(busqueda.toLowerCase())
    );

    return (
        <>
            <Input
                prefix={<SearchOutlined />}
                placeholder="Buscar por teléfono o dirección..."
                value={busqueda}
                onChange={(e) => setBusqueda(e.target.value)}
                style={{ marginBottom: '1rem' }}
            />
            <List
                bordered
                dataSource={solicitudesFiltradas}
                renderItem={(solicitud, index) => (
                    <List.Item
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer', // Hace que el cursor sea un puntero sobre todo el item
                            transition: 'background 0.2s',
                        }}
                        onClick={() => window.location.href = `/solicitudes/clientes/${solicitud.id}`} // Redirige al hacer clic
                    >
                        {/* Número grande a la izquierda */}
                        <div style={{ marginRight: '20px', textAlign: 'center' }}>
                            <Title level={2} style={{ margin: 0 }}>{index + 1}</Title>
                        </div>

                        {/* Contenido de la solicitud a la derecha */}
                        <div style={{ flexGrow: 1 }}>
                            <strong>Teléfono:</strong> {solicitud.telefono}
                            <br />
                            <strong>Dirección:</strong> {solicitud.direccion}
                            <br />
                            <Space size="small" direction="vertical">
                                {solicitud.dniFrontal && (
                                    <a href={solicitud.dniFrontal} target="_blank" rel="noopener noreferrer">
                                        Ver DNI Frontal
                                    </a>
                                )}
                                {solicitud.dniTrasero && (
                                    <a href={solicitud.dniTrasero} target="_blank" rel="noopener noreferrer">
                                        Ver DNI Trasero
                                    </a>
                                )}
                                {solicitud.poderNotarial && (
                                    <a href={solicitud.poderNotarial} target="_blank" rel="noopener noreferrer">
                                        Ver Poder Notarial
                                    </a>
                                )}
                            </Space>
                        </div>
                    </List.Item>

                )}
            />
        </>
    );
};

export default SolicitudesClienteView;
