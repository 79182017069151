import React, { useState } from 'react';
import { Button, message } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import ModalPeriodos from "./ModalPeriodos";
import AlquilerService from '../../../services/AlquilerService';
import PeriodoService from "../../../services/PeriodoService";

const BotonPeriodos = ({ alquilerId, navigate }) => {
    const [visible, setVisible] = useState(false);
    const [periodos, setPeriodos] = useState([]);
    const [pagos, setPagos] = useState([]);
    const [loading, setLoading] = useState(false);

    const abrirModal = async () => {
        setVisible(true);
        if (!periodos.length || !pagos.length) {
            setLoading(true);
            try {
                const [resPeriodos, resPagos] = await Promise.all([
                    PeriodoService.obtenerPorAlquiler(alquilerId),
                    AlquilerService.obtenerPagos(alquilerId)
                ]);
                setPeriodos(resPeriodos.data);
                setPagos(resPagos.data);
            } catch (error) {
                message.error('Error al cargar períodos o pagos');
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            <Button icon={<CalendarOutlined />} style={{ height: '50px' }} onClick={abrirModal}>
                Períodos
            </Button>
            <ModalPeriodos
                visible={visible}
                onClose={() => setVisible(false)}
                alquilerId={alquilerId}
                periodos={periodos}
                pagos={pagos}
                navigate={navigate}
                loading={loading}
            />
        </>
    );
};

export default BotonPeriodos;