import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import MarcadorSimpleMapa from "../../sub/MarcadorSimpleMapa";

const MapaAlquiler = ({ coordenadas }) => {
    const [lat, lon] = coordenadas ? coordenadas.split(',').map(parseFloat) : [null, null];
    const [mensajeEstado, setMensajeEstado] = useState("Cargando mapa...");

    useEffect(() => {
        if (!coordenadas) {
            setMensajeEstado("No hay coordenadas ingresadas.");
        } else if (isNaN(lat) || isNaN(lon)) {
            setMensajeEstado("Las coordenadas recibidas no son válidas.");
        } else {
            if (lat === 0 && lon === 0) {
                setMensajeEstado("El mapa no cargó, pruebe más tarde...");
            }
        }
    }, [coordenadas]);

    if (!lat || !lon || isNaN(lat) || isNaN(lon)) {
        return <p style={{ textAlign: 'center', fontSize: '16px', color: 'gray' }}>{mensajeEstado}</p>;
    }

    return (
        <div style={{
            height: "400px",
            position: "relative",
            borderRadius: "8px",
            overflow: "hidden"
        }}>
            <p style={{
                position: "absolute",
                top: 10,
                left: "50%",
                transform: "translateX(-50%)",
                background: "rgba(255,255,255,0.8)",
                padding: "5px 10px",
                borderRadius: "5px",
                fontWeight: "bold"
            }}>
                {mensajeEstado}
            </p>
            <MapContainer
                center={[lat, lon]}
                zoom={18}
                style={{ height: "100%", width: "100%" }}
            >
                <TileLayer
                    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    attribution='&copy; <a href="https://www.esri.com/">Esri</a> &mdash; Source: Esri, Maxar, Earthstar Geographics'
                />
                <MarcadorSimpleMapa lat={lat} lon={lon} />
            </MapContainer>
        </div>
    );
};

export default MapaAlquiler;
