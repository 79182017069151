import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider } from "antd";
import AuthService from "../../services/AuthService";
import BaseSidebarNavbar from "./BaseSideNavbar";

const Navbar = () => {
    const navigate = useNavigate();
    const [showMore, setShowMore] = useState(false);

    const user = AuthService.getCurrentUser();
    const role = user?.role || "";

    const handleNavigate = (path) => {
        navigate(path);
    };

    const toggleMore = () => setShowMore(prev => !prev);

    const menuItems = (
        <>
            <a onClick={() => handleNavigate("/home")} className="w3-bar-item w3-button">
                <i className="bi bi-house-door w3-margin-right"></i> Inicio
            </a>

            <Divider style={{ margin: "10px 0" }} />

            {["SUPERADMIN", "ADMIN", "TÉCNICO", "CLIENTE"].includes(role) && (
                <a onClick={() => handleNavigate("/mapa")} className="w3-bar-item w3-button">
                    <i className="bi bi-map w3-margin-right"></i> Mapa
                </a>
            )}

            {["SUPERADMIN", "ADMIN"].includes(role) && (
                <>
                    <a onClick={() => handleNavigate("/alquileres")} className="w3-bar-item w3-button">
                        <i className="bi bi-building w3-margin-right"></i> Alquileres
                    </a>
                    <a onClick={() => handleNavigate("/contenedores")} className="w3-bar-item w3-button">
                        <i className="bi bi-box-seam w3-margin-right"></i> Contenedores
                    </a>
                    <a onClick={() => handleNavigate("/pagos")} className="w3-bar-item w3-button">
                        <i className="bi bi-cash-coin w3-margin-right"></i> Pagos
                    </a>
                </>
            )}

            {role === "TÉCNICO" && (
                <a onClick={() => handleNavigate("/contenedores")} className="w3-bar-item w3-button">
                    <i className="bi bi-box-seam w3-margin-right"></i> Contenedores
                </a>
            )}

            {role === "CLIENTE" && (
                <a onClick={() => handleNavigate("/alquileres")} className="w3-bar-item w3-button">
                    <i className="bi bi-building w3-margin-right"></i> Ver Alquiler
                </a>
            )}

            <Divider style={{ margin: "10px 0" }} />

            {["SUPERADMIN", "ADMIN"].includes(role) && (
                <>
                    <a onClick={toggleMore} className="w3-bar-item w3-button">
                        <i className="bi w3-margin-right"></i> Más...
                    </a>

                    {showMore && (
                        <div className="w3-animate-opacity">
                            <a onClick={() => handleNavigate("/facturas")} className="w3-bar-item w3-button">
                                <i className="bi bi-receipt w3-margin-right"></i> Facturas
                            </a>
                            <a onClick={() => handleNavigate("/modelosContrato")} className="w3-bar-item w3-button">
                                <i className="bi bi-file-earmark-text w3-margin-right"></i> Modelos de contratos
                            </a>
                            <a onClick={() => handleNavigate("/solicitudes/clientes")} className="w3-bar-item w3-button">
                                <i className="bi bi-person-bounding-box w3-margin-right"></i> Solicitudes de clientes
                            </a>
                            <a onClick={() => handleNavigate("/clientes")} className="w3-bar-item w3-button">
                                <i className="bi bi-people w3-margin-right"></i> Clientes
                            </a>
                            <a onClick={() => handleNavigate("/empresas")} className="w3-bar-item w3-button">
                                <i className="bi bi-shop w3-margin-right"></i> Empresas
                            </a>
                            <a onClick={() => handleNavigate("/emisores")} className="w3-bar-item w3-button">
                                <i className="bi bi-envelope-paper w3-margin-right"></i> Emisores
                            </a>
                            <a onClick={() => handleNavigate("/reportes")} className="w3-bar-item w3-button">
                                <i className="bi bi-graph-up w3-margin-right"></i> Reportes
                            </a>
                            <a onClick={() => handleNavigate("/presupuestos")} className="w3-bar-item w3-button">
                                <i className="bi bi-journal-text w3-margin-right"></i> Presupuestos
                            </a>
                        </div>
                    )}
                </>
            )}

            <Divider style={{ margin: "10px 0" }} />

            <a onClick={() => handleNavigate("/logout")} className="w3-bar-item w3-button w3-hover-red">
                <i className="bi bi-box-arrow-right w3-margin-right"></i> Cerrar sesión
            </a>

            <a onClick={() => handleNavigate("/config")} className="w3-bar-item w3-button">
                <i className="bi bi-gear w3-margin-right"></i> Configuración
            </a>
        </>
    );

    return <BaseSidebarNavbar onNavigate={handleNavigate} menuItems={menuItems} />;
};

export default Navbar;
