import React, { useEffect, useState } from "react";
import {Modal, List, Typography, Spin, Button} from "antd";
import ContenedorService from "../../../services/ContenedorService";
import {
    MapContainer,
    TileLayer,
    Polyline,
    Marker,
    Popup,
    useMap
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import {useThemeMode} from "../../../utils/ThemeContext";
import {ArrowRightOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";

const { Text } = Typography;

// Componente para ajustar el zoom automáticamente
const AjustarVista = ({ puntos }) => {
    const map = useMap();

    useEffect(() => {
        if (puntos.length > 0) {
            const bounds = L.latLngBounds(puntos);
            map.fitBounds(bounds, { padding: [40, 40] });
        }
    }, [puntos, map]);

    return null;
};

// Íconos diferenciados
const getIcono = (tipo) =>
    new L.Icon({
        iconUrl:
            tipo === "inicio"
                ? "https://cdn-icons-png.flaticon.com/512/190/190411.png" // verde
                : tipo === "fin"
                    ? "https://cdn-icons-png.flaticon.com/512/190/190406.png" // rojo
                    : "https://cdn-icons-png.flaticon.com/512/190/190416.png", // azul (actual)
        iconSize: [28, 28],
        iconAnchor: [14, 28],
        popupAnchor: [0, -28],
    });

const HistorialContenedorModal = ({ visible, onClose, contenedor }) => {
    const [alquileres, setAlquileres] = useState([]);
    const [loading, setLoading] = useState(false);
    const { isDarkMode } = useThemeMode();
    const navigate = useNavigate();
    useEffect(() => {
        if (visible && contenedor?.id) {
            setLoading(true);
            ContenedorService.obtenerAlquileresDeContenedor(contenedor.id)
                .then(res => {
                    const datosFiltrados = res.data
                        .filter(a => a.coordenadas)
                        .map(a => ({
                            ...a,
                            coords: a.coordenadas.split(",").map(Number)
                        }))
                        .filter(a => a.coords.length === 2 && a.coords.every(n => !isNaN(n)));

                    setAlquileres(datosFiltrados);
                })
                .finally(() => setLoading(false));
        }
    }, [visible, contenedor]);

    const puntos = alquileres.map(a => a.coords);

    return (
        <Modal
            open={visible}
            onCancel={onClose}
            title={`Historial del contenedor ${contenedor?.codigo}`}
            footer={null}
            width={800}
            bodyStyle={{ padding: 12 }}
        >
            {loading ? (
                <Spin />
            ) : (
                <>
                    <List
                        size="small"
                        dataSource={alquileres}
                        bordered
                        style={{ marginBottom: 12 }}
                        renderItem={(a, index) => (
                            <List.Item>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                    <span>{index + 1}. {a.apodo ? a.apodo : (a.empresa?.razonSocial || "Empresa desconocida")}</span>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Text type="secondary" style={{ marginRight: 12 }}>
                                            {a.fechaInicio} - {a.fechaFin || "actual"}
                                        </Text>
                                        <Button
                                            type="link"
                                            icon={<ArrowRightOutlined style={{ fontSize: "16px" }} />}
                                            onClick={() => navigate(`/alquileres/${a.id}`)}
                                        />
                                    </div>
                                </div>
                            </List.Item>



                        )}
                    />

                    {puntos.length > 1 && (
                        <MapContainer
                            style={{ height: 300, width: "100%" }}
                            center={puntos[0]}
                            zoom={5}
                            scrollWheelZoom={false}
                        >
                            <TileLayer
                                attribution='&copy; OpenStreetMap'
                                url={
                                    isDarkMode
                                        ? "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                                        : "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                }
                            />
                            <AjustarVista puntos={puntos} />
                            <Polyline
                                positions={puntos}
                                color="#1890ff"
                                weight={3}
                                opacity={0.7}
                                smoothFactor={2}
                            />
                            {alquileres.map((a, i) => {
                                const tipo =
                                    i === 0
                                        ? "inicio"
                                        : i === alquileres.length - 1 && a.activo
                                            ? "actual"
                                            : i === alquileres.length - 1
                                                ? "fin"
                                                : "intermedio";

                                return (
                                    <Marker
                                        key={i}
                                        position={a.coords}
                                        icon={getIcono(tipo)}
                                    >
                                        <Popup>
                                            <b>{a.empresa?.razonSocial}</b><br />
                                            {a.ubicacion}<br />
                                            {a.fechaInicio} - {a.fechaFin || "actual"}
                                        </Popup>
                                    </Marker>
                                );
                            })}
                        </MapContainer>
                    )}
                </>
            )}
        </Modal>
    );
};

export default HistorialContenedorModal;
