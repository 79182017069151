import React from 'react';
import { Button, Modal, Form, Checkbox, Select, Space, message, Spin } from 'antd';
import AlquilerService from "../../../services/AlquilerService";

const { Option } = Select;

const ModalFacturacion = ({ visible, onClose, alquiler, setAlquiler, emisores, navigate, loadingEmisores }) => {

    const handleSubmitFacturacion = async () => {
        const alquilerData = {
            ...(alquiler.emisor && { emisor: { id: alquiler.emisor.id } }),
            facturable: alquiler.facturable,
            facturaAutomatica: alquiler.facturaAutomatica,
            notificarFactura: alquiler.notificarFactura,
        };

        try {
            await AlquilerService.actualizar(alquiler.id, alquilerData);
            message.success("Configuración de facturación actualizada correctamente.");
            onClose();
        } catch (error) {
            message.error("Error actualizando configuración de facturación: " + error);
        }
    };

    const handleVerEmisor = () => {
        if (alquiler.emisor && alquiler.emisor.id) {
            navigate(`/emisores/${alquiler.emisor.id}`);
        } else {
            alert('Por favor, seleccione un emisor primero.');
        }
    };

    return (
        <Modal
            title="Facturación"
            open={visible}
            onCancel={onClose}
            footer={null}
            width={500}
        >
            {loadingEmisores ? (
                <Spin />
            ) : (
                <Form layout="vertical" onFinish={handleSubmitFacturacion}>
                    <Form.Item>
                        <Checkbox
                            checked={alquiler.facturable}
                            onChange={e => setAlquiler(prev => ({ ...prev, facturable: e.target.checked }))}
                        >
                            Con factura
                        </Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Checkbox
                            checked={alquiler.facturaAutomatica}
                            onChange={e => setAlquiler(prev => ({ ...prev, facturaAutomatica: e.target.checked }))}
                        >
                            Factura automática
                        </Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Checkbox
                            checked={alquiler.notificarFactura}
                            onChange={e => setAlquiler(prev => ({ ...prev, notificarFactura: e.target.checked }))}
                        >
                            Enviar mail al cliente al facturar
                        </Checkbox>
                    </Form.Item>

                    <Form.Item label="Emisor de factura">
                        <Space.Compact style={{ width: '100%' }}>
                            <Select
                                value={alquiler.emisor ? alquiler.emisor.id : undefined}
                                onChange={(value) => {
                                    const selectedEmisor = emisores.find(emisor => emisor.id === value);
                                    setAlquiler(prev => ({ ...prev, emisor: selectedEmisor }));
                                }}
                                placeholder="Seleccione un emisor"
                            >
                                {emisores.map(emisor => (
                                    <Option key={emisor.id} value={emisor.id}>
                                        {emisor.razonSocial}
                                    </Option>
                                ))}
                            </Select>
                            <Button type="default" onClick={handleVerEmisor}>
                                Ver
                            </Button>
                        </Space.Compact>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                            Guardar configuración
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
};

export default ModalFacturacion;
