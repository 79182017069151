import React, { useState, useEffect } from 'react';
import AlquilerService from '../../services/AlquilerService';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Divider, message, Skeleton, Col, Row } from 'antd';
import Loader from '../sub/Loader';
import EstadoAlquiler from './sub/EstadoAlquiler';
import BotonFacturacion from './facturacion-config/BotonFacturacion';
import BotonFormAlquiler from './formulario/BotonFormAlquiler';
import BotonNuevoPago from './pago/pagoNuevo/BotonNuevoPago';
import ListaPagosModal from './pago/pagos/ListaPagosModal';
import MapaAlquilerWrapper from './mapa/MapaAlquilerWrapper';
import ContratoModal from './contrato/ContratoModal';
import BotonHistorialContenedor from "../contenedor/historialContenedor/BotonHistorialContenedor";
import 'dayjs/locale/es';
import TextoProximoPago from "./pago/pagoProximo/TextoProximoPago";
import BotonPeriodos from "./periodos/BotonPeriodos";

const ViewAlquiler = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { Title } = Typography;

    const [alquiler, setAlquiler] = useState(null);
    const [modeloElegido, setModeloElegido] = useState({ id: null });

    const [cargando, setCargando] = useState(true);
    const [cargandoMapa] = useState(false);

    useEffect(() => {
        const cargarAlquiler = async () => {
            try {
                if (id) {
                    const alquilerRes = await AlquilerService.obtenerPorId(id);
                    setAlquiler(alquilerRes.data);
                }
            } catch (error) {
                message.error('Error al cargar el alquiler: ' + error);
            } finally {
                setCargando(false);
            }
        };
        cargarAlquiler();
    }, [id]);

    if (cargando) {
        return <Loader mensaje="Cargando alquiler..." />;
    }

    const botonSkeleton = <Skeleton.Button active={true} size={"large"} shape={"square"} style={{height: 50, width: 130}} />;

    return (
        <div style={{ maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem' }}>
            <Title level={2}>{id ?
                (alquiler.apodo ? alquiler.apodo : `Alquiler ${alquiler.contenedor?.codigo}` )
                : 'Nuevo alquiler'}</Title>

            {id && alquiler && (
                <>
                    <EstadoAlquiler alquiler={alquiler} setAlquiler={setAlquiler} />

                    <TextoProximoPago fecha={alquiler.fechaProximoPago}/>

                    <Row gutter={[8, 8]}>
                        <Col>{<BotonFormAlquiler alquiler={alquiler} setAlquiler={setAlquiler} cargandoMapa={cargandoMapa} navigate={navigate} />}</Col>
                        <Col>{<BotonPeriodos alquilerId={alquiler.id} navigate={navigate} />}</Col>
                        <Col>{<BotonFacturacion alquiler={alquiler} setAlquiler={setAlquiler} navigate={navigate} />}</Col>
                        <Col>{<ContratoModal
                            alquiler={alquiler}
                            modeloElegido={modeloElegido}
                            setModeloElegido={setModeloElegido}
                        />}</Col>
                        <Col>{<ListaPagosModal alquilerId={alquiler.id}/>}</Col>
                    </Row>
                    <Divider />
                    <Row gutter={[8, 8]}>
                        <Col><BotonNuevoPago alquiler={alquiler} /></Col>
                        <Col><BotonHistorialContenedor contenedor={alquiler.contenedor}/></Col>
                    </Row>
                </>
            )}

            <Divider />
            {alquiler?.coordenadas && (
                <MapaAlquilerWrapper coordenadas={alquiler.coordenadas} cargandoMapa={cargandoMapa} />
            )}
        </div>
    );
};

export default ViewAlquiler;