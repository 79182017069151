import React, {useState, useEffect} from 'react';
import ModeloContratoService from '../../services/ModeloContratoService';
import {Link} from 'react-router-dom';
import {List, Typography, Button, message} from 'antd';

const {Title} = Typography;

const ModeloContratoList = () => {
    const [modeloContratos, setModeloContratos] = useState([]);

    useEffect(() => {
        ModeloContratoService.obtenerTodos().then(response => {
            setModeloContratos(response.data);
        }).catch(error => {
            message.error('Error al obtener los modelos de contrato:', error);
        });
    }, []);

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2}>Lista de modelos de contrato</Title>
            <List
                bordered
                dataSource={modeloContratos}
                renderItem={(modeloContrato, index) => (
                    <List.Item>
                        <Link to={`/modelosContrato/${modeloContrato.id}`}>
                            <b>{index + 1}.</b> {modeloContrato.titulo}
                        </Link>
                    </List.Item>
                )}
            />

            <div style={{marginTop: '1rem'}}>
                <Link to="/modelosContrato/nuevo">
                    <Button type="primary">Crear nuevo modelo</Button>
                </Link>
            </div>
        </div>
    );
};

export default ModeloContratoList;
