import React from "react";
import { useNavigate } from "react-router-dom";
import BaseSidebarNavbar from "./BaseSideNavbar";

const LoginNavbar = () => {
    const navigate = useNavigate();
    const handleNavigate = (path) => {
        navigate(path);
    };

    const menuItems = (
        <a onClick={() => handleNavigate("/")} className="w3-bar-item w3-button">Volver al menú</a>
    );

    return <BaseSidebarNavbar onNavigate={handleNavigate} menuItems={menuItems} />;
};

export default LoginNavbar;
