import { useEffect, useState } from 'react';
import {Modal, List, Tag, Typography, Divider, Button, Space, message, Tooltip} from 'antd';
import dayjs from 'dayjs';
import {ContainerOutlined, FileTextOutlined, RightOutlined} from '@ant-design/icons';
import EmpresaService from '../../services/EmpresaService';
import {Link} from "react-router-dom"; // Ajustá ruta si es necesario

const { Text } = Typography;

const AlquileresDeEmpresaModal = ({ empresaId }) => {
    const [visible, setVisible] = useState(false);
    const [alquileres, setAlquileres] = useState([]);

    useEffect(() => {
        if (visible && empresaId) {
            EmpresaService.obtenerAlquileres(empresaId)
                .then(res => setAlquileres(res.data))
                .catch(() => message.error("Error al obtener alquileres"));
        }
    }, [visible, empresaId]);

    const activos = alquileres.filter(a => a.activo);
    const inactivos = alquileres.filter(a => !a.activo);

    const renderItem = (item) => (
        <List.Item
            actions={[
                <Link to={`/alquileres/${item.id}`}>
                    <Tooltip title="Ver alquiler">
                        <RightOutlined />
                    </Tooltip>
                </Link>,
                <Link to={`/contenedores/${item.contenedorId}`}>
                    <Tooltip title="Ver contenedor">
                        <ContainerOutlined />
                    </Tooltip>
                </Link>,
            ]}
        >
            <Space direction="vertical" size={0}>
                <Text strong>{item.contenedor}</Text>
                <Text type="secondary">
                    {dayjs(item.fechaInicio).format("DD/MM/YYYY")} –{" "}
                    {item.fechaFin ? dayjs(item.fechaFin).format("DD/MM/YYYY") : "Actual"}
                </Text>
            </Space>
        </List.Item>
    );


    return (
        <>
            <Button type="primary" onClick={() => setVisible(true)} icon={<FileTextOutlined />} style={{ marginBottom: 16 }}>
                Ver alquileres
            </Button>

            <Modal
                open={visible}
                onCancel={() => setVisible(false)}
                onOk={() => setVisible(false)}
                title="Alquileres de la empresa"
                width={600}
            >
                <Divider orientation="left">🟢 Activos</Divider>
                <List
                    size="small"
                    dataSource={activos}
                    renderItem={renderItem}
                    locale={{ emptyText: "Sin alquileres activos" }}
                />

                <Divider orientation="left">⚪ Inactivos</Divider>
                <List
                    size="small"
                    dataSource={inactivos}
                    renderItem={renderItem}
                    locale={{ emptyText: "Sin alquileres inactivos" }}
                />
            </Modal>
        </>
    );
};

export default AlquileresDeEmpresaModal;
