import React from 'react';
import { Link } from 'react-router-dom';
import { List, Space, Tooltip, Typography, Divider } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
dayjs.locale('es');

const PagoListView = ({ pagos }) => {
    const formatoPesos = new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
        minimumFractionDigits: 2
    });

    // Agrupar pagos por fecha (día)
    const pagosPorFecha = pagos.reduce((acc, pago) => {
        const fecha = dayjs(pago.fechaPago).format("dddd, DD MMMM");
        if (!acc[fecha]) {
            acc[fecha] = [];
        }
        acc[fecha].push(pago);
        return acc;
    }, {});

    // Ordenar fechas cronológicamente
    const fechasOrdenadas = Object.keys(pagosPorFecha).sort((a, b) => {
        const fechaA = dayjs(pagosPorFecha[a][0].fechaPago);
        const fechaB = dayjs(pagosPorFecha[b][0].fechaPago);
        return fechaB - fechaA; // ahora de más nuevo a más viejo
    });


    return (
        <>
            {fechasOrdenadas.length > 0 ? (
                fechasOrdenadas.map((fecha) => (
                    <div key={fecha}>
                        <Divider
                            orientation="left"
                            style={{
                                textTransform: "capitalize",
                                margin: "8px 0",
                                fontSize: "16px"
                            }}
                        >
                            {fecha}
                        </Divider>
                        <List
                            size="small"
                            bordered={false}
                            style={{ marginBottom: 12 }}
                            dataSource={pagosPorFecha[fecha].sort((a, b) => new Date(a.fechaPago) - new Date(b.fechaPago))}
                            renderItem={(pago) => {
                                const hora = dayjs(pago.fechaPago).format("HH:mm");
                                return (
                                    <List.Item
                                        style={{ padding: "6px 8px" }}
                                        actions={
                                            pago.urlComprobante
                                                ? [
                                                    <Tooltip title="Tiene comprobante" key="comprobante">
                                                        <FileTextOutlined />
                                                    </Tooltip>
                                                ]
                                                : []
                                        }
                                    >
                                        <Link to={`/pagos/${pago.id}`} style={{ width: '100%' }}>
                                            <Space
                                                direction="vertical"
                                                size={0}
                                                style={{ width: "100%" }}
                                            >
                                                <Typography.Text
                                                    type="secondary"
                                                    style={{ fontSize: 12 }}
                                                >
                                                    {hora} - {pago.alquiler.empresa.razonSocial}
                                                </Typography.Text>
                                                <Typography.Text style={{ fontSize: 13 }}>
                                                    {formatoPesos.format(pago.montoPagado)}
                                                </Typography.Text>
                                                {pago.comentarios && (
                                                    <Typography.Text
                                                        type="secondary"
                                                        style={{ fontSize: 11 }}
                                                    >
                                                        {pago.comentarios}
                                                    </Typography.Text>
                                                )}
                                            </Space>
                                        </Link>
                                    </List.Item>
                                );
                            }}
                        />
                    </div>
                ))
            ) : (
                <Typography.Text>No hay pagos registrados.</Typography.Text>
            )}
        </>
    );
};

export default PagoListView;
