import React, { useState } from "react";
import { Popup } from "react-leaflet";
import { Badge, Modal, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { WarningTwoTone, UserOutlined, HomeOutlined } from "@ant-design/icons";
import BotonHistorialContenedorSmallIcon from "../contenedor/historialContenedor/BotonHistorialContenedorSmallIcon";

const ContenedorPopup = ({ alquiler, puntos }) => {
    const navigate = useNavigate();
    const clave = alquiler.contenedor.clave || "default";
    const reportes = alquiler.contenedor.reportes?.filter(e => !e.resuelto) || [];
    const [modalVisible, setModalVisible] = useState(false);

    const abrirModal = () => setModalVisible(true);
    const cerrarModal = () => setModalVisible(false);

    const cliente = alquiler.cliente;
    const empresa = alquiler.empresa;

    return (
        <>
            <Popup minWidth={280} maxWidth={340}>
                <div style={{ display: "flex", flexDirection: "column", gap: 6 }}>
                    <h4 style={{ margin: 0 }}>
                        {alquiler.apodo || alquiler.contenedor.codigo}
                        <span style={{ marginLeft: 8, fontSize: 12, color: "#888" }}>({clave})</span>
                    </h4>

                    <span style={{ fontSize: 13, color: "#888" }}>{alquiler.ubicacion}</span>

                    {cliente && (
                        <div style={{ fontSize: 13 }}>
                            <UserOutlined style={{ marginRight: 6 }} />
                            <span
                                style={{ color: "#1890ff", cursor: "pointer" }}
                                onClick={() => navigate(`/clientes/${cliente.id}`)}
                            >
                                {cliente.nombre}
                            </span>
                        </div>
                    )}

                    {empresa && (
                        <div style={{ fontSize: 13 }}>
                            <HomeOutlined style={{ marginRight: 6 }} />
                            <span
                                style={{ color: "#1890ff", cursor: "pointer" }}
                                onClick={() => navigate(`/empresas/${empresa.id}`)}
                            >
                                {empresa.razonSocial}
                            </span>
                        </div>
                    )}

                    {reportes.length > 0 && (
                        <div style={{ marginTop: 6 }}>
                            <Badge count={reportes.length} style={{ backgroundColor: "#fa541c" }} />
                            <span style={{ fontSize: 13, marginLeft: 8 }}>
                                Hay reportes activos.
                                <span
                                    onClick={abrirModal}
                                    style={{ color: "#1890ff", cursor: "pointer", marginLeft: 6 }}
                                >
                                    Ver
                                </span>
                                <Tooltip title="Contenedor con problemas">
                                    <WarningTwoTone twoToneColor="#f5222d" style={{ marginLeft: 8 }} />
                                </Tooltip>
                            </span>
                        </div>
                    )}

                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
                        <span
                            onClick={() => navigate(`/alquileres/${alquiler.id}`)}
                            style={{ cursor: "pointer", color: "#1890ff", fontSize: 13 }}
                        >
                            Ver alquiler
                        </span>
                        <BotonHistorialContenedorSmallIcon contenedor={alquiler.contenedor} />
                    </div>
                </div>
            </Popup>

            <Modal
                title={`Reportes activos del contenedor ${alquiler.contenedor.codigo}`}
                open={modalVisible}
                onCancel={cerrarModal}
                footer={null}
            >
                {reportes.map((r, i) => (
                    <div key={i} style={{ marginBottom: 12 }}>
                        <div style={{ fontWeight: 500, color: r.tipo === "grave" ? "#cf1322" : "#595959" }}>
                            {r.fechaReporte} {r.tipo === "grave" && <WarningTwoTone twoToneColor="#f5222d" />}
                        </div>
                        <div style={{ fontSize: 14 }}>{r.descripcion || <i>Sin descripción</i>}</div>
                    </div>
                ))}
            </Modal>
        </>
    );
};

export default ContenedorPopup;
