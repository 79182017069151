import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';

const PagoChart = ({ pagos }) => {
    // Procesar los pagos para agrupar por mes
    const data = pagos.reduce((acc, pago) => {
        const fecha = new Date(pago.fechaPago);
        const mes = fecha.toLocaleString("es-AR", { month: "long", year: "numeric" });

        const existente = acc.find(item => item.mes === mes);
        if (existente) {
            existente.total += pago.montoPagado;
        } else {
            acc.push({ mes, total: pago.montoPagado });
        }
        return acc;
    }, []);

    // Ordenar los meses cronológicamente
    data.sort((a, b) => new Date(`01 ${a.mes}`) - new Date(`01 ${b.mes}`));

    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="mes" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="total" fill="#8884d8" />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default PagoChart;
