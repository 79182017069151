import axiosInstanceMultipart from '../utils/axiosInstanceMultipart';
import config from '../config';
import axiosInstance from "../utils/axiosInstance";

const API_URL = `${config.API_URL}`;
const SOLICITUDES_CLIENTES_FOLDER = "/solicitudes/clientes";

class FormularioService {
    altaCliente(data) {

        return axiosInstanceMultipart.post(`${API_URL}/formularios/alta-cliente`, data);
    }

    obtenerTodos() {
        return axiosInstance.get(`${API_URL}${SOLICITUDES_CLIENTES_FOLDER}`);
    }

    obtenerPorId(id) {
        return axiosInstance.get(`${API_URL}${SOLICITUDES_CLIENTES_FOLDER}/${id}`);
    }

    actualizar(id, pago) {
        return axiosInstanceMultipart.put(`${API_URL}${SOLICITUDES_CLIENTES_FOLDER}/${id}`, pago);
    }

    eliminar(id) {
        return axiosInstance.delete(`${API_URL}${SOLICITUDES_CLIENTES_FOLDER}/${id}`);
    }
}

export default new FormularioService();
