import React, { useState, useEffect } from 'react';
import { Typography, message, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import ClienteService from '../../services/ClienteService';
import EmpresaService from '../../services/EmpresaService';
import ContenedorService from '../../services/ContenedorService';
import FormEditarAlquiler from "./formulario/FormEditarAlquiler";
import EmisorService from "../../services/EmisorService";
import FormNuevoAlquiler from "./formulario/FormNuevoAlquiler";

const { Title } = Typography;

const NuevoAlquiler = () => {
    const navigate = useNavigate();

    const [clientes, setClientes] = useState([]);
    const [emisores, setEmisores] = useState([]);
    const [empresas, setEmpresas] = useState([]);
    const [contenedores, setContenedores] = useState([]);
    const [cargando, setCargando] = useState(false);

    useEffect(() => {
        const cargarDatos = async () => {
            setCargando(true);
            try {
                const [clientesRes, empresasRes, contenedoresRes, emisoresRes] = await Promise.all([
                    ClienteService.obtenerTodos(),
                    EmpresaService.obtenerTodos(),
                    ContenedorService.obtenerTodos(),
                    EmisorService.obtenerTodos()
                ]);
                setClientes(clientesRes.data);
                setEmpresas(empresasRes.data);
                setContenedores(contenedoresRes.data);
                setEmisores(emisoresRes.data);
            } catch (error) {
                message.error('Error al cargar los datos.');
            } finally {
                setCargando(false);
            }
        };
        cargarDatos();
    }, []);

    if (cargando) {
        return <Spin size="large" style={{ display: 'block', margin: 'auto', marginTop: '50px' }} />;
    }

    return (
        <div style={{maxWidth: 450, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2}>Nuevo Alquiler</Title>
            <FormNuevoAlquiler
                clientes={clientes}
                empresas={empresas}
                contenedores={contenedores}
                emisores={emisores}
                navigate={navigate}
            />
        </div>
    );
};

export default NuevoAlquiler;