import React, { useState } from "react";
import { Upload, Button, Typography, Divider, message, Modal } from "antd";
import { InboxOutlined, UploadOutlined, FilePdfOutlined } from "@ant-design/icons";
import AlquilerService from "../../../services/AlquilerService";
import { useNavigate } from "react-router-dom";

const ContratoUploader = ({ id, alquiler }) => {
    const [fileList, setFileList] = useState([]);
    const [subiendoContrato, setSubiendoContrato] = useState(false);
    const [modalContratoVisible, setModalContratoVisible] = useState(false);
    const navigate = useNavigate();
    // Eliminamos el estado urlContrato porque ahora siempre lo obtenemos desde el backend
    const handleSubirContrato = async () => {
        if (!fileList.length || !fileList[0].originFileObj) {
            message.warning("Selecciona un archivo PDF válido.");
            return;
        }

        setSubiendoContrato(true);
        try {
            const formData = new FormData();
            formData.append("archivo", fileList[0].originFileObj);

            await AlquilerService.subirContrato(id, formData);
            message.success("Contrato subido correctamente.");

            // Cierra el modal y resetea lista
            setModalContratoVisible(false);
            setFileList([]);
        } catch (error) {
            console.error("Error al subir el contrato:", error);
            message.error("Error al subir el contrato.");
        } finally {
            setSubiendoContrato(false);
        }
    };

    const abrirContrato = async () => {
        try {
            const response = await AlquilerService.obtenerContrato(id, {
                responseType: "blob",
            });


            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            window.open(url, "_blank", "noopener,noreferrer");
;
        } catch (err) {
            console.error(err);
            message.error("No se pudo obtener el contrato.");
        }
    };


    return (
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
            {alquiler.contratoUrl ? (
                <>
                    <Button
                        type="primary"
                        icon={<FilePdfOutlined style={{ fontSize: "1.5rem" }} />}
                        size="large"
                        onClick={abrirContrato}
                        style={{
                            width: "100%",
                            backgroundColor: "#52c41a",
                            borderColor: "#52c41a",
                            marginBottom: "10px",
                        }}
                    >
                        Ver contrato
                    </Button>

                    <Button
                        icon={<UploadOutlined />}
                        type="default"
                        size="large"
                        onClick={() => setModalContratoVisible(true)}
                        style={{
                            width: "100%",
                            opacity: 0.7,
                        }}
                    >
                        Subir nuevo contrato
                    </Button>
                </>
            ) : (
                <Button
                    icon={<FilePdfOutlined style={{ fontSize: "1.5rem" }} />}
                    type="primary"
                    size="large"
                    onClick={() => setModalContratoVisible(true)}
                    style={{ width: "100%" }}
                >
                    Subir contrato
                </Button>
            )}

            <Modal
                title="Subir contrato"
                open={modalContratoVisible}
                onCancel={() => setModalContratoVisible(false)}
                footer={null}
            >
                <Divider />
                <Typography.Title level={4}>Selecciona un contrato en PDF</Typography.Title>

                <Upload.Dragger
                    accept="application/pdf"
                    multiple={false}
                    maxCount={1}
                    beforeUpload={() => false}
                    onChange={({ fileList }) => setFileList(fileList)}
                    fileList={fileList}
                    style={{
                        border: "2px dashed #1890ff",
                        padding: "20px",
                        borderRadius: "10px",
                        background: "#f0f5ff",
                        textAlign: "center",
                    }}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined style={{ fontSize: 40, color: "#1890ff" }} />
                    </p>
                    <p className="ant-upload-text">
                        Arrastra un archivo PDF aquí o haz clic para seleccionar uno
                    </p>
                </Upload.Dragger>

                <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    onClick={handleSubirContrato}
                    loading={subiendoContrato}
                    style={{ marginTop: 10 }}
                    disabled={!fileList.length}
                >
                    Subir contrato
                </Button>
            </Modal>
        </div>
    );
};

export default ContratoUploader;
