import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {Form, Input, Upload, Button, message, Modal, Space} from 'antd';
import {UploadOutlined, DeleteOutlined, ExclamationCircleOutlined, UserAddOutlined} from '@ant-design/icons';
import FormulariosService from "../../services/FormulariosService";
import Navbar from "../layout/Navbar";
import Loader from "../sub/Loader";
import {BrowserMultiFormatReader} from '@zxing/browser';

const {confirm} = Modal;

const SolicitudClienteForm = () => {
    const {id} = useParams();
    const navigate = useNavigate(); // Para redirigir después de eliminar
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);

    // Estados para manejar las imágenes
    const [dniFrontal, setDniFrontal] = useState([]);
    const [dniTrasero, setDniTrasero] = useState([]);
    const [poderNotarial, setPoderNotarial] = useState([]);

    // Lector barras
    const [dniFrontalTexto, setDniFrontalTexto] = useState('');
    const [dniTraseroTexto, setDniTraseroTexto] = useState('');


    useEffect(() => {
        const cargarSolicitud = async () => {
            try {
                const {data} = await FormulariosService.obtenerPorId(id);

                form.setFieldsValue({
                    telefono: data.telefono,
                    direccion: data.direccion,
                    facturas: data.facturas
                });

                // Cargar imágenes en estado
                if (data.dniFrontalUrl) {
                    setDniFrontal([{uid: '-1', url: data.dniFrontalUrl, name: 'DNI Frontal'}]);
                    leerCodigoBarraDesdeImagen(data.dniFrontalUrl).then(texto => {
                        if (texto) setDniFrontalTexto(texto);
                    });
                }
                if (data.dniTraseroUrl) {
                    setDniTrasero([{uid: '-2', url: data.dniTraseroUrl, name: 'DNI Trasero'}]);
                    leerCodigoBarraDesdeImagen(data.dniTraseroUrl).then(texto => {
                        if (texto) setDniTraseroTexto(texto);
                    });
                }
                if (data.poderNotarialUrl) {
                    setPoderNotarial([{uid: '-3', url: data.poderNotarialUrl, name: 'Poder Notarial'}]);
                }

                setLoading(false);
            } catch (error) {
                message.error('Error al cargar la solicitud');
                setLoading(false);
            }
        };
        cargarSolicitud();
    }, [id, form]);

    const onFinish = async (values) => {
        try {
            const formData = new FormData();
            formData.append('telefono', values.telefono);
            formData.append('direccion', values.direccion);
            formData.append('facturas', values.facturas || '');

            // Solo enviá archivos si son nuevos (con originFileObj)
            if (dniFrontal?.[0]?.originFileObj) {
                formData.append('dniFrontal', dniFrontal[0].originFileObj);
            }
            if (dniTrasero?.[0]?.originFileObj) {
                formData.append('dniTrasero', dniTrasero[0].originFileObj);
            }
            if (poderNotarial?.[0]?.originFileObj) {
                formData.append('poderNotarial', poderNotarial[0].originFileObj);
            }


            await FormulariosService.actualizar(id, formData);
            message.success('Solicitud actualizada correctamente');
        } catch (error) {
            message.error('Error al actualizar la solicitud');
        }
    };

    // Código barras
    const leerCodigoBarraDesdeImagen = async (input) => {
        const image = new Image();

        return await new Promise((resolve, reject) => {
            image.onload = async () => {
                const codeReader = new BrowserMultiFormatReader();
                try {
                    const result = await codeReader.decodeFromImageElement(image);
                    resolve(result.getText());
                } catch (error) {
                    console.warn("No se encontró PDF417:", error);
                    resolve(null);
                }
            };
            image.onerror = reject;

            if (typeof input === 'string') {
                image.src = input; // URL desde backend
            } else {
                const reader = new FileReader();
                reader.onload = (e) => {
                    image.src = e.target.result;
                };
                reader.readAsDataURL(input);
            }
        });
    };


    // Función para confirmar y eliminar la solicitud
    const eliminarSolicitud = () => {
        confirm({
            title: "¿Estás seguro de eliminar esta solicitud?",
            icon: <ExclamationCircleOutlined/>,
            content: "Esta acción no se puede deshacer.",
            okText: "Sí, eliminar",
            okType: "danger",
            cancelText: "Cancelar",
            onOk: async () => {
                try {
                    await FormulariosService.eliminar(id);
                    message.success("Solicitud eliminada correctamente");
                    navigate("/solicitudes/clientes"); // Redirigir después de eliminar
                } catch (error) {
                    message.error("Error al eliminar la solicitud");
                }
            }
        });
    };

    const crearClienteDesdeDni = () => {
        const partes = dniFrontalTexto.split('@');
        if (partes.length >= 5) {
            const dni = partes[4];
            const sexo = partes[3];
            const nombre = `${partes[2]} ${partes[1]}`;
            const dniFrontalFile = dniFrontal?.[0]?.originFileObj;
            const dniTraseroFile = dniTrasero?.[0]?.originFileObj;

            navigate('/clientes/nuevo', {
                state: {
                    nombre,
                    cuit: calcularCuitDesdeDni(dni, sexo),
                    dniFrontalFile,
                    dniTraseroFile
                }
            });
        }
    };


    const calcularCuitDesdeDni = (dni, sexo = 'M') => {
        const intentos = sexo === 'F'
            ? [27, 23, 20]
            : [20, 23, 27];

        for (const prefijo of intentos) {
            const base = `${prefijo}${dni}`;
            const mult = [5,4,3,2,7,6,5,4,3,2];
            let suma = 0;

            for (let i = 0; i < base.length; i++) {
                suma += parseInt(base[i]) * mult[i];
            }

            let resto = suma % 11;
            let digito = 11 - resto;

            if (digito === 11) digito = 0;
            if (digito < 10) {
                return `${prefijo}-${dni}-${digito}`;
            }
        }

        // Si no funcionó con ningún prefijo, devolver algo parcial
        return `23-${dni}-?`;
    };



    if (loading) return <Loader/>;

    return (
        <>
            <Navbar/>
            <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
                <h2>Editar solicitud</h2>
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item name="telefono" label="Número de teléfono"
                               rules={[{required: true, message: 'Campo obligatorio'}]}>
                        <Input/>
                    </Form.Item>

                    <Form.Item name="direccion" label="Ubicación del contenedor"
                               rules={[{required: true, message: 'Campo obligatorio'}]}>
                        <Input/>
                    </Form.Item>

                    <Form.Item name="facturas" label="Encargados de recibir facturas y comunicados">
                        <Input.TextArea rows={3}/>
                    </Form.Item>

                    {/* DNI Frontal */}
                    <Form.Item label="DNI frontal">
                        <Upload
                            beforeUpload={() => false}
                            maxCount={1}
                            fileList={dniFrontal}
                            listType="picture-card"
                            showRemoveIcon={false}
                            onChange={async ({ fileList }) => {
                                setDniFrontal(fileList);
                                const file = fileList?.[0]?.originFileObj;
                                if (file) {
                                    const texto = await leerCodigoBarraDesdeImagen(file);
                                    setDniFrontalTexto(texto || 'No se detectó código PDF417');
                                }
                            }}
                        >
                            <Button icon={<UploadOutlined />}>Subir imagen</Button>
                        </Upload>

                        {dniFrontalTexto && (
                            <div style={{ marginTop: 12 }}>
                                <Button
                                    type="dashed"
                                    icon={<UserAddOutlined />}
                                    onClick={crearClienteDesdeDni}
                                    style={{ marginBottom: 8 }}
                                >
                                    Crear cliente a partir del DNI
                                </Button>
                                <div style={{ color: '#888', fontSize: 12 }}>
                                    Podés reemplazar la imagen subiendo otra. No se puede eliminar directamente desde aquí.
                                </div>
                            </div>
                        )}
                    </Form.Item>


                    {/* DNI Trasero */}
                    <Form.Item label="DNI Trasero">
                        <Upload
                            beforeUpload={() => false}
                            maxCount={1}
                            fileList={dniTrasero}
                            listType="picture-card"
                            showRemoveIcon={false}
                            onChange={async ({fileList}) => {
                                setDniTrasero(fileList);
                                const file = fileList?.[0]?.originFileObj;
                                if (file) {
                                    const texto = await leerCodigoBarraDesdeImagen(file);
                                    setDniTraseroTexto(texto || 'No se detectó código PDF417');
                                }
                            }}

                        >
                            <Button icon={<UploadOutlined/>}>Subir imagen</Button>
                        </Upload>
                        {dniTraseroTexto && <p style={{fontSize: 12, marginTop: 4}}>{dniTraseroTexto}</p>}
                        <small style={{color: '#888'}}>
                            Podés reemplazar la imagen subiendo otra. No se puede eliminar directamente desde aquí.
                        </small>
                    </Form.Item>

                    {/* Poder Notarial */}
                    <Form.Item label="Poder Notarial">
                        <Upload
                            beforeUpload={() => false}
                            maxCount={1}
                            fileList={poderNotarial}
                            listType="text"
                            showRemoveIcon={false}
                            onChange={({ fileList }) => setPoderNotarial(fileList)}
                        >
                            <Button icon={<UploadOutlined />}>Subir documento</Button>
                        </Upload>

                        <small style={{ color: '#888' }}>
                            Podés reemplazar la imagen subiendo otro. No se puede eliminar directamente desde aquí.
                        </small>
                    </Form.Item>


                    <Form.Item>
                        <Space size="middle">
                            <Button type="primary" htmlType="submit">Guardar cambios</Button>
                            <Button
                                type="default"
                                danger
                                icon={<DeleteOutlined/>}
                                onClick={eliminarSolicitud}
                            >
                                Eliminar solicitud
                            </Button>
                            <Button onClick={() => navigate('/solicitudes/clientes')}>
                                Volver
                            </Button>
                        </Space>
                    </Form.Item>


                </Form>
            </div>
        </>
    );
};

export default SolicitudClienteForm;
