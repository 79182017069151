import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EmpresaService from '../../services/EmpresaService';
import {
    List, Typography, Button, message, Tooltip,
    Card, Input, Segmented
} from 'antd';
import { RightOutlined, SearchOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const EmpresaList = () => {
    const [empresas, setEmpresas] = useState([]);
    const [busqueda, setBusqueda] = useState("");
    const [filtroIva, setFiltroIva] = useState("todas");

    useEffect(() => {
        EmpresaService.obtenerTodos()
            .then(response => setEmpresas(response.data))
            .catch(error => {
                message.error('Error al obtener la lista de empresas');
                console.error(error);
            });
    }, []);

    // Cálculo de condiciones IVA únicas
    const condiciones = Array.from(new Set(empresas.map(e => e.condicionIva || "Sin dato")));
    const contar = (cond) => empresas.filter(e => (e.condicionIva || "Sin dato") === cond).length;

    const opcionesFiltroIva = [
        { label: `Todas (${empresas.length})`, value: "todas" },
        ...condiciones.map(c => ({
            label: `${c} (${contar(c)})`,
            value: c
        }))
    ];

    const empresasFiltradas = empresas.filter(e => {
        const texto = `${e.cuit || ""} ${e.razonSocial || ""} ${e.condicionIva || ""} ${e.direccion || ""}`.toLowerCase();
        const coincideBusqueda = texto.includes(busqueda.toLowerCase());
        const coincideIva = filtroIva === "todas" || e.condicionIva === filtroIva;
        return coincideBusqueda && coincideIva;
    });

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={3} style={{ marginBottom: 16 }}>Lista de empresas</Title>

            <Input
                placeholder="Buscar..."
                prefix={<SearchOutlined />}
                value={busqueda}
                onChange={e => setBusqueda(e.target.value)}
                style={{ marginBottom: '1rem' }}
                allowClear
                size="small"
            />

            <Segmented
                size="small"
                options={opcionesFiltroIva}
                value={filtroIva}
                onChange={setFiltroIva}
                style={{ marginBottom: "1.5rem" }}
            />

            <List
                size="small"
                itemLayout="horizontal"
                dataSource={empresasFiltradas}
                renderItem={(empresa, index) => (
                    <List.Item style={{ padding: "6px 0" }}>
                        <Card
                            size="small"
                            bodyStyle={{ padding: "8px 12px" }}
                            style={{ width: "100%", borderRadius: 6 }}
                        >
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Link to={`/empresas/${empresa.id}`} style={{ fontWeight: 500 }}>
                                        {index + 1}. {empresa.razonSocial}
                                    </Link>
                                    <Text type="secondary" style={{ fontFamily: "monospace", fontSize: 13 }}>
                                        {empresa.cuit}
                                    </Text>
                                </div>

                                <Tooltip title={empresa.direccion || empresa.condicionIva}>
                                    <RightOutlined style={{ color: '#aaa', fontSize: 16 }} />
                                </Tooltip>
                            </div>
                        </Card>
                    </List.Item>
                )}
            />

            <div style={{ marginTop: '2rem', textAlign: "center" }}>
                <Link to="/empresas/nuevo">
                    <Button type="primary" size="small">Nueva empresa</Button>
                </Link>
            </div>
        </div>
    );
};

export default EmpresaList;
