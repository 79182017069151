import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {
    Button, Typography, message, List, Input, Tooltip, Segmented,
    Card, Badge, Popover, Divider, Select
} from 'antd';
import {
    WarningOutlined,
    CheckCircleOutlined,
    StopOutlined,
    SearchOutlined, ExclamationCircleOutlined
} from '@ant-design/icons';
import ContenedorService from '../../services/ContenedorService';
import BotonHistorialContenedorSmallIcon from "./historialContenedor/BotonHistorialContenedorSmallIcon";

const {Title, Text} = Typography;

const iconos = {
    "20HIGH": "/reefer/reefer20high.png",
    "20LOW": "/reefer/reefer20low.png",
    "40HIGH": "/reefer/reefer40high.png",
    "40LOW": "/reefer/reefer40low.png"
};

const ReportesList = ({reportes}) => (
    <List
        size="small"
        dataSource={reportes.filter(r => !r.resuelto)}
        renderItem={(reporte) => (
            <List.Item>
                <Text ellipsis style={{maxWidth: 200}}>{reporte.descripcion}</Text>
                <span style={{marginLeft: 8}}>
                    <Badge status="warning" text={reporte.fechaReporte}/>
                </span>
            </List.Item>
        )}
    />
);

const generarCodigoCorto = (contenedor) => {
    const tipo = contenedor.tipo?.toUpperCase().includes("REEFER") ? "R" :
        contenedor.tipo?.toUpperCase().includes("CONT") ? "C" : "X";

    const longitud = contenedor.longitud?.toUpperCase() || "";

    const tamanio = contenedor.pies ||
        (longitud.includes("CUAR") ? "40" :
            longitud.includes("VEIN") ? "20" : "??");

    const clase = contenedor.clase?.toUpperCase().includes("HIGH") ? "HC" :
        contenedor.clase?.toUpperCase().includes("LOW") ? "LOW" : "";

    return `${tipo}${tamanio}${clase}`.trim();
};

const ContenedorList = () => {
    const [contenedores, setContenedores] = useState([]);
    const [busqueda, setBusqueda] = useState("");
    const [filtroDisponibilidad, setFiltroDisponibilidad] = useState("todos");
    const [filtroTipo, setFiltroTipo] = useState("todos");
    const [filtroClase, setFiltroClase] = useState("todos");
    const [filtroLongitud, setFiltroLongitud] = useState("todos");

    useEffect(() => {
        ContenedorService.obtenerTodos()
            .then((response) => setContenedores(response.data))
            .catch(() => message.error('Error al obtener contenedores'));
    }, []);

    const contenedoresFiltrados = contenedores.filter(c => {
        const coincideFiltro =
            filtroDisponibilidad === "todos" ||
            (filtroDisponibilidad === "disponibles" && c.disponible) ||
            (filtroDisponibilidad === "alquilados" && !c.disponible);

        const texto = `${c.codigo} ${c.descripcion || ''} ${c.tipo || ''} ${c.clase || ''} ${c.longitud || ''}`.toLowerCase();
        const coincideBusqueda = texto.includes(busqueda.toLowerCase());

        const coincideTipo =
            filtroTipo === "todos" ||
            (filtroTipo === "Reefer" && c.tipo?.toUpperCase().includes("REEFER")) ||
            (filtroTipo === "Contenedor" && c.tipo?.toUpperCase().includes("CONT")) ||
            (filtroTipo === "Otro" && !(c.tipo?.toUpperCase().includes("REEFER") || c.tipo?.toUpperCase().includes("CONT")));

        const coincideClase =
            filtroClase === "todos" ||
            (filtroClase === "High" && c.clase?.toUpperCase().includes("HIGH")) ||
            (filtroClase === "Low" && c.clase?.toUpperCase().includes("LOW")) ||
            (filtroClase === "Otro" && !(c.clase?.toUpperCase().includes("HIGH") || c.clase?.toUpperCase().includes("LOW")));

        const coincideLongitud =
            filtroLongitud === "todos" || (c.longitud?.includes(filtroLongitud));

        return coincideFiltro && coincideBusqueda && coincideTipo && coincideClase && coincideLongitud;
    });

    // Dinámica para mostrar cantidad en filtros
    const contar = (fn) => contenedores.filter(fn).length;

    const opcionesTipo = [
        {label: `Reefer (${contar(c => c.tipo?.toUpperCase().includes("REEFER"))})`, value: "Reefer"},
        {label: `Contenedor (${contar(c => c.tipo?.toUpperCase().includes("CONT"))})`, value: "Contenedor"},
        {
            label: `Otro (${contar(c => !(c.tipo?.toUpperCase().includes("REEFER") || c.tipo?.toUpperCase().includes("CONT")))})`,
            value: "Otro"
        },
        {label: `Todos (${contenedores.length})`, value: "todos"}
    ];

    const opcionesClase = [
        {label: `High (${contar(c => c.clase?.toUpperCase().includes("HIGH"))})`, value: "High"},
        {label: `Low (${contar(c => c.clase?.toUpperCase().includes("LOW"))})`, value: "Low"},
        {
            label: `Otro (${contar(c => !(c.clase?.toUpperCase().includes("HIGH") || c.clase?.toUpperCase().includes("LOW")))})`,
            value: "Otro"
        },
        {label: "Todos", value: "todos"}
    ];

    const opcionesLongitud = [
        {label: `20 (${contar(c => c.longitud?.includes("VEIN"))})`, value: "20"},
        {label: `40 (${contar(c => c.longitud?.includes("CUAR"))})`, value: "40"},
        {label: "Todos", value: "todos"}
    ];

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2} style={{marginBottom: 16}}>Contenedores</Title>

            <Input
                placeholder="Buscar..."
                prefix={<SearchOutlined/>}
                value={busqueda}
                onChange={e => setBusqueda(e.target.value)}
                style={{marginBottom: '1rem'}}
                size="small"
                allowClear
            />

            <div style={{ marginBottom: "1.5rem" }}>
                <Text strong style={{ fontSize: 13, marginBottom: 4, display: "block" }}>
                    Filtros
                </Text>

                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: 8,
                    }}
                >
                    <div>
                        <Text type="secondary" style={{ fontSize: 11 }}>Disponibilidad</Text>
                        <Select
                            size="small"
                            value={filtroDisponibilidad}
                            onChange={setFiltroDisponibilidad}
                            options={[
                                { label: 'Todos', value: 'todos' },
                                { label: 'Disponibles', value: 'disponibles' },
                                { label: 'Alquilados', value: 'alquilados' }
                            ]}
                            style={{ width: "100%" }}
                        />
                    </div>

                    <div>
                        <Text type="secondary" style={{ fontSize: 11 }}>Tipo</Text>
                        <Select
                            size="small"
                            value={filtroTipo}
                            onChange={setFiltroTipo}
                            options={opcionesTipo}
                            style={{ width: "100%" }}
                        />
                    </div>

                    <div>
                        <Text type="secondary" style={{ fontSize: 11 }}>Clase</Text>
                        <Select
                            size="small"
                            value={filtroClase}
                            onChange={setFiltroClase}
                            options={opcionesClase}
                            style={{ width: "100%" }}
                        />
                    </div>

                    <div>
                        <Text type="secondary" style={{ fontSize: 11 }}>Longitud</Text>
                        <Select
                            size="small"
                            value={filtroLongitud}
                            onChange={setFiltroLongitud}
                            options={opcionesLongitud}
                            style={{ width: "100%" }}
                        />
                    </div>
                </div>
            </div>

            <Divider/>
            <List
                size="small"
                itemLayout="horizontal"
                dataSource={contenedoresFiltrados}
                renderItem={(contenedor, index) => {
                    const claveIcono = `${contenedor.pies}${contenedor.alto}`.toUpperCase();
                    const icono = iconos[claveIcono];
                    const codigoCorto = generarCodigoCorto(contenedor);

                    return (
                        <List.Item style={{padding: "6px 0"}}>
                            <Card
                                size="small"
                                bodyStyle={{padding: "6px 8px"}}
                                style={{width: "100%", borderRadius: 6}}
                            >
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 8,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden"
                                    }}>
                                        <Link to={`/contenedores/${contenedor.id}`}
                                              style={{fontWeight: 500, fontFamily: "monospace"}}>
                                            <b>{index + 1}.</b> {contenedor.codigo}
                                        </Link>
                                        <Text type="secondary" style={{fontFamily: "monospace"}}>
                                            {codigoCorto}
                                        </Text>
                                    </div>

                                    <div style={{display: "flex", alignItems: "center", gap: 10}}>
                                        {contenedor.cantidadReportesActivos > 0 && (
                                            <Popover
                                                content={<ReportesList reportes={contenedor.reportes || []}/>}
                                                title="Reportes pendientes"
                                                trigger="click"
                                            >
                                                <Badge
                                                    count={contenedor.cantidadReportesActivos}
                                                    style={{backgroundColor: '#faad14'}}
                                                >
                                                    <WarningOutlined style={{color: '#faad14'}}/>
                                                </Badge>
                                            </Popover>
                                        )}
                                        <Tooltip title="Ver historial">
                                            <BotonHistorialContenedorSmallIcon contenedor={contenedor}/>
                                        </Tooltip>
                                        <Tooltip title={contenedor.disponible ? "Disponible" : "Alquilado actualmente"}>
                                            {contenedor.disponible ? (
                                                <ExclamationCircleOutlined style={{color: "#f5222d", fontSize: 14}}/>
                                            ) : (
                                                <CheckCircleOutlined style={{color: "#52c41a", fontSize: 14}}/>
                                            )}
                                        </Tooltip>
                                        {icono && (
                                            <img
                                                src={icono}
                                                alt={claveIcono}
                                                style={{width: 24, opacity: 0.85, flexShrink: 0}}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Card>
                        </List.Item>
                    );
                }}
            />

            <div style={{marginTop: '1.5rem', textAlign: "center"}}>
                <Link to="/contenedores/nuevo">
                    <Button type="primary" size="small">Nuevo contenedor</Button>
                </Link>
            </div>
        </div>
    );
};

export default ContenedorList;
