// src/components/Unauthorized.js
import React from 'react';

function Unauthorized() {
    return (
        <div>
            <h1>Acceso denegado</h1>
            <p>No tienes permisos suficientes para acceder a esta página.</p>
        </div>
    );
}

export default Unauthorized;
