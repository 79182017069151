import axiosInstance from "../utils/axiosInstance";
import config from "../config";

const API_URL = `${config.API_URL}/config/settings`;

class ConfiguracionSistemaService {
    obtener() {
        return axiosInstance.get(API_URL);
    }

    actualizar(configuracion) {
        return axiosInstance.put(API_URL, configuracion);
    }
}

export default new ConfiguracionSistemaService();
