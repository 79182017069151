import React, { useState, useEffect } from 'react';
import { Modal, Spin, message } from 'antd';
import FormEditarAlquiler from './FormEditarAlquiler';
import EmpresaService from '../../../services/EmpresaService';
import EmisorService from '../../../services/EmisorService';

const ModalAlquiler = ({
                           navigate,
                           open,              // <- control del modal
                           setOpen,           // <- función para cerrarlo
                           alquiler,
                           setAlquiler,
                           clientes,
                           contenedores,
                           cargandoMapa
                       }) => {
    const [empresas, setEmpresas] = useState([]);
    const [emisores, setEmisores] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!open) return;

        const cargarDatos = async () => {
            try {
                const [empresasRes, emisoresRes] = await Promise.all([
                    EmpresaService.obtenerTodos(),
                    EmisorService.obtenerTodos()
                ]);
                setEmpresas(empresasRes.data);
                setEmisores(emisoresRes.data);
            } catch (error) {
                message.error("Error cargando empresas o emisores");
            } finally {
                setLoading(false);
            }
        };

        setLoading(true);
        cargarDatos();
    }, [open]);

    return (
        <Modal
            title="Editar alquiler"
            open={open}
            onCancel={() => setOpen(false)}
            footer={null}
            width={600}
            destroyOnClose
        >
            {loading ? (
                <div style={{ textAlign: 'center', padding: '2rem' }}>
                    <Spin tip="Cargando información..." />
                </div>
            ) : (
                <FormEditarAlquiler
                    alquilerInicial={alquiler}
                    setAlquiler={setAlquiler}
                    clientes={clientes}
                    empresas={empresas}
                    emisores={emisores}
                    contenedores={contenedores}
                    cargandoMapa={cargandoMapa}
                    navigate={navigate}
                />
            )}
        </Modal>
    );
};

export default ModalAlquiler;
