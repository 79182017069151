import React, { useState, useEffect } from 'react';
import ComentarioService from '../services/ComentarioService';
import { Link } from 'react-router-dom';
import { message } from 'antd';

const ComentarioList = () => {
    const [comentarios, setComentarios] = useState([]);

    useEffect(() => {
        ComentarioService.obtenerTodos().then(response => {
            setComentarios(response.data);
        }).catch(error => {
            message.error('Error fetching comentarios:', error);
        });
    }, []);

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <h2>Lista de Comentarios</h2>
            <ul>
                {comentarios.map(comentario => (
                    <li key={comentario.id}>

                        <Link to={`/reportes/${comentario.reporte.id}`}>
                            {comentario.fechaComentario}: {comentario.texto}
                        </Link>
                    </li>
                ))}
            </ul>
            <Link to="/comentarios/nuevo">
                <button>Crear</button>
            </Link>
        </div>
    );
}

export default ComentarioList;
