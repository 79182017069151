// src/services/PeriodoService.js
import axiosInstance from '../utils/axiosInstance';
import config from '../config';


const API_URL = `${config.API_URL}/periodos`;

class PeriodoService {
    obtenerTodos() {
        return axiosInstance.get(API_URL);
    }

    obtenerPorId(id) {
        return axiosInstance.get(`${API_URL}/${id}`);
    }

    guardar(pago) {
        return axiosInstance.post(API_URL, pago);
    }

    actualizar(id, pago) {
        return axiosInstance.put(`${API_URL}/${id}`, pago);
    }

    eliminar(id) {
        return axiosInstance.delete(`${API_URL}/${id}`);
    }

    obtenerPorAlquiler(alquilerId) {
        return axiosInstance.get(`${API_URL}/alquiler/${alquilerId}`);
    }
}

export default new PeriodoService();