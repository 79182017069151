import React, { useState } from "react";
import { Form, Input, Upload, Button, message, Layout, theme } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import logo from "../../assets/images/logo.png";
import FormulariosService from "../../services/FormulariosService";
import LandingNavbar from "../layout/LandingNavbar";
import Dragger from "antd/es/upload/Dragger";
import { useNavigate } from "react-router-dom";
import Loader from "../sub/Loader"; // Necesario para redireccionar

const { Content, Footer } = Layout;

const AltaClienteView = () => {
    const [form] = Form.useForm();
    const { token } = theme.useToken();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onFinish = async (values) => {
        try {
            setLoading(true);

            const formData = new FormData();
            formData.append("telefono", values.telefono);
            formData.append("direccion", values.direccion);
            formData.append("facturas", values.facturas);

            if (values.dniFrontal?.fileList?.[0]?.originFileObj) {
                formData.append("dniFrontal", values.dniFrontal.fileList[0].originFileObj);
            }
            if (values.dniTrasero?.fileList?.[0]?.originFileObj) {
                formData.append("dniTrasero", values.dniTrasero.fileList[0].originFileObj);
            }
            if (values.poderNotarial?.fileList?.[0]?.originFileObj) {
                formData.append("poderNotarial", values.poderNotarial.fileList[0].originFileObj);
            }

            await FormulariosService.altaCliente(formData);
            message.success("Formulario enviado correctamente");
            navigate("/"); // redirige al inicio
        } catch (error) {
            console.error(error);
            message.error("Error al enviar formulario");
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <Loader mensaje="Enviando formulario..." />;

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <LandingNavbar />

            <Content style={{ maxWidth: 500, margin: "auto", padding: "6rem 2rem 3rem 4rem" }}>
                <div style={{ width: "100%", maxWidth: 500 }}>
                    <h2>Documentación para alquiler</h2>

                    <Form form={form} layout="vertical" onFinish={onFinish}>
                        <Form.Item
                            name="telefono"
                            label="Número de teléfono"
                            rules={[{ required: true, message: "Campo obligatorio" }]}
                        >
                            <Input placeholder="Ingrese su número de teléfono" />
                        </Form.Item>

                        <Form.Item
                            name="direccion"
                            label="Ubicación exacta del contenedor"
                            rules={[{ required: true, message: "Campo obligatorio" }]}
                        >
                            <Input placeholder="Calle, altura, código postal / ciudad" />
                        </Form.Item>

                        <Form.Item
                            name="dniFrontal"
                            label="DNI (cara frontal o ambas caras juntas)"
                            rules={[{ required: true, message: "Campo obligatorio" }]}
                        >
                            <Dragger
                                name="dniFrontal"
                                beforeUpload={() => false}
                                maxCount={1}
                                onChange={({ fileList }) => form.setFieldsValue({ dniFrontal: { fileList } })}
                                accept=".jpg,.jpeg,.png,.pdf"
                            >
                                <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                <p className="ant-upload-text">Hacé click o arrastrá el archivo</p>
                                <p className="ant-upload-hint">Formato aceptado: JPG, PNG o PDF. Solo un archivo.</p>
                            </Dragger>
                        </Form.Item>

                        <Form.Item
                            name="dniTrasero"
                            label="DNI (cara trasera - opcional si ya subiste ambas juntas)"
                        >
                            <Dragger
                                name="dniTrasero"
                                beforeUpload={() => false}
                                maxCount={1}
                                onChange={({ fileList }) => form.setFieldsValue({ dniTrasero: { fileList } })}
                                accept=".jpg,.jpeg,.png,.pdf"
                            >
                                <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                <p className="ant-upload-text">Hacé click o arrastrá el archivo</p>
                                <p className="ant-upload-hint">Solo si no subiste ambas caras juntas.</p>
                            </Dragger>
                        </Form.Item>


                        <Form.Item
                            name="poderNotarial"
                            label="Poder notarial"
                            rules={[{ required: true, message: "Campo obligatorio" }]}
                        >
                            <Dragger
                                name="poderNotarial"
                                beforeUpload={() => false}
                                maxCount={1}
                                onChange={({ fileList }) => form.setFieldsValue({ poderNotarial: { fileList } })}
                                accept=".jpg,.jpeg,.png,.pdf"
                            >
                                <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                <p className="ant-upload-text">Hacé click o arrastrá el documento</p>
                                <p className="ant-upload-hint">Permitido: PDF o imagen escaneada</p>
                            </Dragger>
                        </Form.Item>

                        <Form.Item name="facturas" label="Encargados de recibir facturas y comunicados (opcional)">
                            <Input.TextArea placeholder="Nombre completo, cargo, WhatsApp/email" rows={3} />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>Enviar</Button>
                            <small style={{ color: '#888' }}>Puede tardar hasta treinta segundos.</small>
                        </Form.Item>
                    </Form>
                </div>
            </Content>

            <Footer
                style={{
                    padding: 32,
                    background: token.colorBgContainer,
                    textAlign: "center",
                }}
            >
                <p style={{ fontSize: "1.25rem", display: "flex", alignItems: "center", justifyContent: "center", gap: 10 }}>
                    <img src={logo} alt="Reefers del Sur" style={{ width: "92px" }} />
                    Reefers del Sur
                </p>
            </Footer>
        </Layout>
    );
};

export default AltaClienteView;
