import { useState } from "react";
import { Button, Modal } from "antd";
import { CreditCardOutlined } from "@ant-design/icons";
import PagoForm from "../../../pago/PagoForm";

const BotonNuevoPago = ({ alquiler }) => {
    const [modalPagoVisible, setModalPagoVisible] = useState(false);

    return (
        <>
            <Button
                icon={<CreditCardOutlined />}
                type="primary"
                style={{ height: "50px" }}
                onClick={() => setModalPagoVisible(true)}
            >
                Nuevo pago
            </Button>

            <Modal
                title="Nuevo pago"
                open={modalPagoVisible}
                onCancel={() => setModalPagoVisible(false)}
                footer={null}
                styles={{ body: { maxHeight: "80vh", overflowY: "auto" } }}
                maskClosable
                closable
            >
                <PagoForm alquiler={alquiler} onClose={() => setModalPagoVisible(false)} />
            </Modal>
        </>
    );
};

export default BotonNuevoPago;
