import React, { useState, useEffect } from 'react';
import {Form, Input, Button, message, Typography, Upload, Divider, Image, Tooltip, Space, Modal} from 'antd';
import {
    UploadOutlined,
    PhoneOutlined,
    MailOutlined,
    WhatsAppOutlined,
    EnvironmentOutlined,
    SearchOutlined,
    FileOutlined,
} from '@ant-design/icons';
import ClienteService from '../../services/ClienteService';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

const { Title } = Typography;

const ClienteForm = () => {
    const location = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [cliente, setCliente] = useState({});
    const [fileListFrontal, setFileListFrontal] = useState([]);
    const [fileListTrasero, setFileListTrasero] = useState([]);
    const [cuitInput, setCuitInput] = useState('');
    const [nombreInput, setNombreInput] = useState('');

    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        if (id) {
            // Modo edición
            ClienteService.obtenerPorId(id).then(response => {
                const data = response.data;
                setCliente(data);
                form.setFieldsValue(data);

                if (data.urlDniFrontal) {
                    setFileListFrontal([{
                        uid: '-1',
                        name: 'dni_frontal',
                        status: 'done',
                        url: data.urlDniFrontal
                    }]);
                }

                if (data.urlDniTrasero) {
                    setFileListTrasero([{
                        uid: '-2',
                        name: 'dni_trasero',
                        status: 'done',
                        url: data.urlDniTrasero
                    }]);
                }
            }).catch(() => {
                message.error('Error al obtener cliente');
            });
        } else {
            // Modo nuevo
            const state = location.state;
            if (state?.nombre || state?.cuit) {
                form.setFieldsValue({
                    nombre: state.nombre || '',
                    cuit: state.cuit || ''
                });
                setNombreInput(state.nombre || '');
                setCuitInput(state.cuit || '');
            }

            if (state?.dniFrontalFile) {
                setFileListFrontal([{
                    uid: '-temp-front',
                    name: 'dni_frontal',
                    status: 'done',
                    originFileObj: state.dniFrontalFile
                }]);
            }

            if (state?.dniTraseroFile) {
                setFileListTrasero([{
                    uid: '-temp-back',
                    name: 'dni_trasero',
                    status: 'done',
                    originFileObj: state.dniTraseroFile
                }]);
            }
        }
    }, [id, form, location.state]);

    const handleSubmit = async (values) => {
        try {
            const formData = new FormData();
            formData.append('nombre', values.nombre);
            formData.append('cuit', values.cuit);
            formData.append('direccion', values.direccion);
            formData.append('telefono', values.telefono);
            formData.append('email', values.email);

            if (fileListFrontal.length > 0 && fileListFrontal[0].originFileObj) {
                formData.append('dniFrontal', fileListFrontal[0].originFileObj);
            }

            if (fileListTrasero.length > 0 && fileListTrasero[0].originFileObj) {
                formData.append('dniTrasero', fileListTrasero[0].originFileObj);
            }

            if (id) {
                await ClienteService.actualizar(id, formData);
            } else {
                await ClienteService.guardar(formData);
            }

            message.success("Cliente guardado correctamente");
            navigate('/clientes');
        } catch (error) {
            message.error("Error al guardar cliente");
        }
    };

    const telefonoSanitizado = cliente.telefono?.replace(/\D/g, '');

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2}>{id ? `${cliente.nombre || ''}` : 'Crear cliente'}</Title>
            { id && (
            <Space size="large" style={{ marginBottom: 30 }}>
                <Tooltip title={cliente.telefono ? "Llamar" : "Teléfono no disponible"}>
                    {cliente.telefono ? (
                        <a href={`tel:${cliente.telefono}`}>
                            <PhoneOutlined style={{ fontSize: 22 }} />
                        </a>
                    ) : (
                        <span style={{ opacity: 0.4 }}><PhoneOutlined style={{ fontSize: 22 }} /></span>
                    )}
                </Tooltip>

                <Tooltip title={telefonoSanitizado ? "Enviar WhatsApp" : "WhatsApp no disponible"}>
                    {telefonoSanitizado ? (
                        <a href={`https://wa.me/${telefonoSanitizado}`} target="_blank" rel="noreferrer">
                            <WhatsAppOutlined style={{ fontSize: 22 }} />
                        </a>
                    ) : (
                        <span style={{ opacity: 0.4 }}><WhatsAppOutlined style={{ fontSize: 22 }} /></span>
                    )}
                </Tooltip>

                <Tooltip title={cliente.email ? "Enviar email" : "Email no disponible"}>
                    {cliente.email ? (
                        <a href={`mailto:${cliente.email}`}>
                            <MailOutlined style={{ fontSize: 22 }} />
                        </a>
                    ) : (
                        <span style={{ opacity: 0.4 }}><MailOutlined style={{ fontSize: 22 }} /></span>
                    )}
                </Tooltip>

                <Tooltip title={cliente.direccion ? "Ver en Google Maps" : "Dirección no disponible"}>
                    {cliente.direccion ? (
                        <a
                            href={`https://www.google.com/maps?q=${encodeURIComponent(cliente.direccion)}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <EnvironmentOutlined style={{ fontSize: 22 }} />
                        </a>
                    ) : (
                        <span style={{ opacity: 0.4 }}><EnvironmentOutlined style={{ fontSize: 22 }} /></span>
                    )}
                </Tooltip>

                <Tooltip title="Consultar CUIT en AFIP">
                    <a
                        href={`https://www.cuitonline.com/search.php?q=${cliente.cuit}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <SearchOutlined style={{ fontSize: 22 }} />
                    </a>
                </Tooltip>

                <Tooltip title={cliente.urlDniFrontal || cliente.urlDniTrasero ? "DNI cargado" : "Sin DNI"}>
                    {(cliente.urlDniFrontal || cliente.urlDniTrasero) ? (
                        <FileOutlined style={{ fontSize: 22, color: "#673ab7" }} />
                    ) : (
                        <span style={{ opacity: 0.4 }}><FileOutlined style={{ fontSize: 22 }} /></span>
                    )}
                </Tooltip>
            </Space>
                )
            }
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={cliente}
            >
                <Form.Item
                    label="Nombre"
                    name="nombre"
                    rules={[{ required: true, message: 'Ingrese el nombre' }]}
                >
                    <Input
                        placeholder="La lupa busca en CUIT Online"
                        value={nombreInput}
                        onChange={(e) => {
                            setNombreInput(e.target.value);
                            form.setFieldsValue({ nombre: e.target.value });
                        }}
                        suffix={
                            <Tooltip title="Buscar por nombre en CUIT Online">
                                <SearchOutlined
                                    onClick={() => {
                                        if (nombreInput.trim()) {
                                            setCuitInput(nombreInput.trim()); // Reutilizamos el modal
                                            setModalVisible(true);
                                        }
                                    }}
                                    style={{
                                        cursor: nombreInput.trim() ? 'pointer' : 'not-allowed',
                                        color: '#1890ff',
                                    }}
                                />
                            </Tooltip>
                        }
                    />
                </Form.Item>


                <Form.Item
                    label="CUIT"
                    name="cuit"
                    rules={[
                        { required: true, message: 'Ingrese el CUIT' },
                        {
                            pattern: /^\d{2}-\d{8}-\d{1}$/,
                            message: 'El CUIT debe tener el formato XX-XXXXXXXX-X',
                        },
                    ]}
                >
                    <Input
                        placeholder="La lupa busca por DNI o CUIT en CUIT Online"
                        value={cuitInput}
                        onChange={(e) => {
                            setCuitInput(e.target.value);
                            form.setFieldsValue({ cuit: e.target.value });
                        }}
                        suffix={
                            <Tooltip title="Buscar CUIT o DNI en CUIT Online">
                                <SearchOutlined
                                    onClick={() => {
                                        if (cuitInput.trim()) setModalVisible(true);
                                    }}
                                    style={{ cursor: cuitInput.trim() ? 'pointer' : 'not-allowed', color: '#1890ff' }}
                                />
                            </Tooltip>
                        }
                    />
                </Form.Item>



                <Form.Item label="Dirección" name="direccion">
                    <Input />
                </Form.Item>

                <Form.Item label="Teléfono" name="telefono">
                    <Input />
                </Form.Item>

                <Form.Item label="Email" name="email" rules={[{ type: 'email', message: 'Ingrese un email válido' }]}>
                    <Input />
                </Form.Item>

                <Divider />

                {cliente.urlDniFrontal && (
                    <div style={{ marginBottom: 10 }}>
                        <b>DNI frontal actual:</b><br />
                        {cliente.urlDniFrontal.endsWith('.pdf') ? (
                            <a href={cliente.urlDniFrontal} target="_blank" rel="noreferrer">Ver archivo PDF</a>
                        ) : (
                            <Image width={250} src={cliente.urlDniFrontal} />
                        )}
                    </div>
                )}
                <Form.Item label="Nuevo DNI frontal">
                    <Upload
                        beforeUpload={() => false}
                        fileList={fileListFrontal}
                        onChange={({ fileList }) => setFileListFrontal(fileList)}
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Subir nuevo DNI frontal</Button>
                    </Upload>
                </Form.Item>

                {cliente.urlDniTrasero && (
                    <div style={{ marginBottom: 10 }}>
                        <b>DNI trasero actual:</b><br />
                        {cliente.urlDniTrasero.endsWith('.pdf') ? (
                            <a href={cliente.urlDniTrasero} target="_blank" rel="noreferrer">Ver archivo PDF</a>
                        ) : (
                            <Image width={250} src={cliente.urlDniTrasero} />
                        )}
                    </div>
                )}
                <Form.Item label="Nuevo DNI trasero (opcional)">
                    <Upload
                        beforeUpload={() => false}
                        fileList={fileListTrasero}
                        onChange={({ fileList }) => setFileListTrasero(fileList)}
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Subir nuevo DNI trasero</Button>
                    </Upload>
                </Form.Item>

                <Divider />

                <Button type="primary" htmlType="submit">Guardar</Button>
                {id && (
                    <Button
                        type="danger"
                        onClick={() => {
                            if (window.confirm('¿Estás seguro de que deseas eliminar este cliente?')) {
                                ClienteService.eliminar(id).then(() => navigate('/clientes'));
                            }
                        }}
                        style={{ marginLeft: 10 }}
                    >
                        Eliminar
                    </Button>
                )}
            </Form>
            <Modal
                title="Buscar en CUIT Online"
                open={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
                width={800}
                style={{ top: 80 }}
                bodyStyle={{ padding: 0 }}
            >
                <iframe
                    src={`https://www.cuitonline.com/search.php?q=${encodeURIComponent(cuitInput.trim())}`}
                    width="100%"
                    height="600"
                    frameBorder="0"
                    title="CUIT Online"
                />
            </Modal>

        </div>
    );
};

export default ClienteForm;
