import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography, message, List, Input, Row, Col, Badge, Popover, Tag, Select, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ContenedorService from '../services/ContenedorService';

const { Title } = Typography;
const { Option } = Select;

const ReporteList = () => {
    const [contenedores, setContenedores] = useState([]);
    const [filter, setFilter] = useState('todos'); // Filtro para resueltos/no resueltos
    const [searchText, setSearchText] = useState(''); // Texto de búsqueda
    const [expandedReportes, setExpandedReportes] = useState([]); // Para manejar la expansión del texto

    useEffect(() => {
        ContenedorService.obtenerTodos()
            .then((response) => {
                const contenedoresConReportes = response.data.map((contenedor) => {
                    // Añadir el código del contenedor a cada reporte
                    const reportesConCodigo = contenedor.reportes?.map((reporte) => ({
                        ...reporte, // Mantener los campos del reporte
                        contenedorCodigo: contenedor.codigo, // Añadir el código del contenedor
                        contenedorId: contenedor.id, // Añadir el id del contenedor
                    }));

                    return { ...contenedor, reportes: reportesConCodigo }; // Devolver contenedor con reportes modificados
                });

                setContenedores(contenedoresConReportes);
            })
            .catch((error) => message.error('Error al obtener contenedores:', error));
    }, []);


    // Extraemos todos los reportes de los contenedores
    const todosLosReportes = contenedores
        .flatMap((contenedor) => contenedor.reportes || []) // Extraemos los reportes de cada contenedor
        .sort((a, b) => new Date(b.fechaReporte) - new Date(a.fechaReporte)); // Ordenamos los reportes por fecha

    // Agrupar los reportes por fecha
    const reportesPorFecha = todosLosReportes.reduce((acc, reporte) => {
        const fecha = reporte.fechaReporte;
        if (!acc[fecha]) acc[fecha] = [];
        acc[fecha].push(reporte);
        return acc;
    }, {});

    // Filtrar los reportes según resuelto/no resuelto y búsqueda por texto
    const reportesFiltrados = Object.entries(reportesPorFecha)
        .filter(([fecha, reportes]) => {
            return reportes.some((reporte) => {
                return (
                    (filter === 'todos' || (filter === 'resueltos' && reporte.resuelto) || (filter === 'no_resueltos' && !reporte.resuelto)) &&
                    reporte.descripcion.toLowerCase().includes(searchText.toLowerCase())
                );
            });
        })
        .map(([fecha, reportes]) => ({
            fecha,
            reportes: reportes.filter((reporte) => {
                return (
                    (filter === 'todos' || (filter === 'resueltos' && reporte.resuelto) || (filter === 'no_resueltos' && !reporte.resuelto)) &&
                    reporte.descripcion.toLowerCase().includes(searchText.toLowerCase())
                );
            }),
        }));

    // Manejar la expansión de texto
    const handleToggleText = (reporteId) => {
        setExpandedReportes((prevState) =>
            prevState.includes(reporteId) ? prevState.filter((id) => id !== reporteId) : [...prevState, reporteId]
        );
    };

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2}>Reportes</Title>

            {/* Filtro y barra de búsqueda */}
            <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
                <Col span={8}>
                    <Select
                        defaultValue="todos"
                        style={{ width: '100%' }}
                        onChange={(value) => setFilter(value)}
                    >
                        <Option value="todos">Todos</Option>
                        <Option value="resueltos">Resueltos</Option>
                        <Option value="no_resueltos">No Resueltos</Option>
                    </Select>
                </Col>

                <Col span={16}>
                    <Input
                        placeholder="Buscar por descripción"
                        prefix={<SearchOutlined />}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </Col>
            </Row>

            {/* Lista de reportes agrupados por fecha */}
            <List
                bordered
                dataSource={reportesFiltrados}
                renderItem={({ fecha, reportes }, index) => (
                    <List.Item key={fecha}>
                        <Title level={4}>{fecha}</Title> {/* Mostrar fecha agrupada */}
                        <List
                            size="small"
                            dataSource={reportes}
                            renderItem={(reporte, subIndex) => (
                                <List.Item key={reporte.id}>
                                    <Row style={{ width: '100%' }} align="middle" gutter={[16, 0]}>
                                        {/* Código del contenedor */}
                                        <Col style={{ fontWeight: 'bold', fontSize: '1rem', color: '#1776ff', }}>
                                            <Link type="link" to={"/contenedores/" + reporte.contenedorId}>{reporte.contenedorCodigo}</Link>
                                        </Col>


                                        {/* Descripción del reporte */}
                                        <Col flex="auto">
                                            <Typography.Text
                                                ellipsis={!expandedReportes.includes(reporte.id) ? { rows: 2, expandable: false } : false}
                                                style={{
                                                    maxWidth: 300,
                                                    whiteSpace: expandedReportes.includes(reporte.id) ? 'pre-line' : 'nowrap', // pre-line cuando está expandido
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: expandedReportes.includes(reporte.id) ? 'block' : 'inline-block'
                                                }}
                                            >
                                                {reporte.descripcion}
                                            </Typography.Text>
                                            {reporte.descripcion.length > 100 && (
                                                <Button
                                                    type="link"
                                                    onClick={() => handleToggleText(reporte.id)}
                                                    style={{ padding: 0 }}
                                                >
                                                    {expandedReportes.includes(reporte.id) ? "Ver menos" : "Ver más..."}
                                                </Button>
                                            )}
                                        </Col>

                                        {/* Estado del reporte */}
                                        <Col>
                                            {reporte.resuelto ? (
                                                <Tag color="green" style={{ fontSize: '0.8rem' }}>
                                                    Resuelto
                                                </Tag>
                                            ) : (
                                                <Tag color="orange" style={{ fontSize: '0.8rem' }}>
                                                    Pendiente
                                                </Tag>
                                            )}
                                        </Col>
                                    </Row>
                                </List.Item>
                            )}
                        />
                    </List.Item>
                )}
            />

            {/* Botón para agregar un nuevo reporte */}
            <div style={{ marginTop: '1rem' }}>
                <Link to="/reportes/nuevo">
                    <Button type="primary">Nuevo Reporte</Button>
                </Link>
            </div>
        </div>
    );
};

export default ReporteList;
