import React from 'react';
import { Modal, Skeleton } from 'antd';
import PeriodosAlquiler from './PeriodosAlquiler';

const ModalPeriodos = ({ visible, onClose, periodos, pagos, navigate, loading }) => (
    <Modal
        title="Períodos de Alquiler"
        open={visible}
        onCancel={onClose}
        footer={null}
        width={450}
    >
        {loading
            ? <Skeleton active paragraph={{ rows: 6 }} />
            : <PeriodosAlquiler periodos={periodos} pagos={pagos} navigate={navigate} /> // AQUÍ
        }
    </Modal>
);

export default ModalPeriodos;
