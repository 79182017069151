import React, { useState, useEffect } from 'react';
import {
    Form, Input, Button, DatePicker, Select, Divider,
    Spin, message, Typography, Space, Tooltip
} from 'antd';
import {
    PhoneOutlined,
    MailOutlined,
    WhatsAppOutlined,
    RightOutlined,
    EnvironmentOutlined,
    ClockCircleOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import AlquilerService from '../../../services/AlquilerService';

dayjs.locale('es');
dayjs.extend(relativeTime);

const { Option } = Select;
const { Text, Title } = Typography;

const FormEditarAlquiler = ({ alquilerInicial, clientes = [], empresas = [], contenedores = [], navigate }) => {
    const initialState = alquilerInicial
        ? {
            ...alquilerInicial,
            clienteId: alquilerInicial.cliente?.id || '',
            empresaId: alquilerInicial.empresa?.id || '',
            contenedorId: alquilerInicial.contenedor?.id || '',
            apodo: alquilerInicial.apodo,
            ubicacion: alquilerInicial.ubicacion,
            coordenadas: alquilerInicial.coordenadas,
            montoInicial: alquilerInicial.montoInicial,
            fechaInicio: alquilerInicial.fechaInicio,
            fechaFin: alquilerInicial.fechaFin,
        }
        : {
            apodo: '',
            clienteId: '',
            empresaId: '',
            contenedorId: '',
            ubicacion: '',
            coordenadas: '',
            montoInicial: '',
            fechaInicio: '',
            fechaFin: '',
        };

    const [alquiler, setAlquiler] = useState(initialState);
    const [cargandoMapa, setCargandoMapa] = useState(false);

    const clienteSeleccionado = clientes.find(c => c.id === alquiler.clienteId);
    const empresaSeleccionada = empresas.find(e => e.id === alquiler.empresaId);
    const contenedorSeleccionado = contenedores.find(ct => ct.id === alquiler.contenedorId);

    const limpiarDireccion = (direccion) => {
        return direccion
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/[^a-zA-Z0-9ñÑ,\s']/g, ' ')
            .replace(/\s+/g, ' ')
            .trim();
    };

    const obtenerCoordenadas = async (direccion) => {
        if (!direccion.trim()) return;
        const direccionLimpia = limpiarDireccion(direccion);
        setCargandoMapa(true);
        try {
            const res = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(direccionLimpia)}`);
            const datos = await res.json();
            if (datos.length > 0) {
                setAlquiler(prev => ({ ...prev, coordenadas: `${datos[0].lat}, ${datos[0].lon}` }));
            } else {
                setAlquiler(prev => ({ ...prev, coordenadas: "0, 0" }));
            }
        } catch {
            setAlquiler(prev => ({ ...prev, coordenadas: "0, 0" }));
        }
        setCargandoMapa(false);
    };

    useEffect(() => {
        if (alquiler.ubicacion && !alquiler.coordenadas) {
            obtenerCoordenadas(alquiler.ubicacion);
        }
    }, [alquiler.ubicacion]);

    const handleSubmit = async () => {
        const alquilerData = {
            cliente: { id: alquiler.clienteId },
            empresa: { id: alquiler.empresaId },
            contenedor: { id: alquiler.contenedorId },
            apodo: alquiler.apodo,
            ubicacion: alquiler.ubicacion,
            coordenadas: alquiler.coordenadas,
            montoInicial: alquiler.montoInicial,
            fechaInicio: alquiler.fechaInicio,
            fechaFin: alquiler.fechaFin,
        };

        try {
            await AlquilerService.actualizar(alquiler.id, alquilerData);
            message.success("Alquiler actualizado correctamente.");
            navigate('/alquileres');
        } catch {
            message.error("Ocurrió un error.");
        }
    };

    return (
        <Form layout="vertical" onFinish={handleSubmit}>
            <Form.Item label="Apodo">
                <Input
                    value={alquiler.apodo || ''}
                    onChange={(e) => setAlquiler(prev => ({ ...prev, apodo: e.target.value }))}
                    placeholder="Apodo del alquiler (opcional)"
                />
            </Form.Item>

            {/* CLIENTE */}
            <Form.Item label="Cliente">
                <Space.Compact style={{ width: '100%' }}>
                    <Select
                        style={{ flex: 1 }}
                        value={alquiler.clienteId || undefined}
                        onChange={(value) => setAlquiler(prev => ({ ...prev, clienteId: value }))}
                        placeholder="Seleccione un cliente"
                    >
                        {clientes.map(c => (
                            <Option key={c.id} value={c.id}>{c.nombre}</Option>
                        ))}
                    </Select>
                    {alquiler.clienteId && (
                        <Button icon={<RightOutlined />} onClick={() => navigate(`/clientes/${alquiler.clienteId}`)} />
                    )}
                </Space.Compact>

                {clienteSeleccionado && (
                    <Space style={{ marginTop: 8 }}>
                        {clienteSeleccionado.telefono && (
                            <Tooltip title="Llamar">
                                <a href={`tel:${clienteSeleccionado.telefono}`}>
                                    <PhoneOutlined />
                                </a>
                            </Tooltip>
                        )}
                        {clienteSeleccionado.telefono && (
                            <Tooltip title="WhatsApp">
                                <a href={`https://wa.me/${clienteSeleccionado.telefono.replace(/\D/g, '')}`} target="_blank" rel="noreferrer">
                                    <WhatsAppOutlined />
                                </a>
                            </Tooltip>
                        )}
                        {clienteSeleccionado.email && (
                            <Tooltip title="Email">
                                <a href={`mailto:${clienteSeleccionado.email}`}>
                                    <MailOutlined />
                                </a>
                            </Tooltip>
                        )}
                    </Space>
                )}
            </Form.Item>

            {/* EMPRESA */}
            <Form.Item label="Empresa">
                <Space.Compact style={{ width: '100%' }}>
                    <Select
                        style={{ flex: 1 }}
                        value={alquiler.empresaId || undefined}
                        onChange={(value) => setAlquiler(prev => ({ ...prev, empresaId: value }))}
                        placeholder="Seleccione una empresa"
                    >
                        {empresas.map(e => (
                            <Option key={e.id} value={e.id}>{e.razonSocial}</Option>
                        ))}
                    </Select>
                    {alquiler.empresaId && (
                        <Button icon={<RightOutlined />} onClick={() => navigate(`/empresas/${alquiler.empresaId}`)} />
                    )}
                </Space.Compact>
            </Form.Item>

            {/* CONTENEDOR */}
            <Form.Item label="Contenedor">
                <Space.Compact style={{ width: '100%' }}>
                    <Select
                        style={{ flex: 1 }}
                        value={alquiler.contenedorId || undefined}
                        onChange={(value) => setAlquiler(prev => ({ ...prev, contenedorId: value }))}
                        placeholder="Seleccione un contenedor"
                    >
                        {contenedores.map(ct => (
                            <Option key={ct.id} value={ct.id}>{ct.codigo}</Option>
                        ))}
                    </Select>
                    {alquiler.contenedorId && (
                        <Button icon={<RightOutlined />} onClick={() => navigate(`/contenedores/${alquiler.contenedorId}`)} />
                    )}
                </Space.Compact>
            </Form.Item>

            {/* UBICACIÓN */}
            <Form.Item label="Ubicación">
                <Input
                    value={alquiler.ubicacion || ''}
                    onChange={(e) => setAlquiler(prev => ({ ...prev, ubicacion: e.target.value }))}
                    onBlur={() => obtenerCoordenadas(alquiler.ubicacion)}
                />
                {alquiler.coordenadas && alquiler.coordenadas !== "0, 0" && (
                    <Button
                        icon={<EnvironmentOutlined />}
                        size="small"
                        style={{ marginTop: 8 }}
                        onClick={() =>
                            window.open(`https://www.google.com/maps/search/?api=1&query=${alquiler.coordenadas}`, "_blank")
                        }
                    >
                        Ver en mapa
                    </Button>
                )}
            </Form.Item>

            {/* MONTO */}
            <Form.Item label="Monto inicial">
                <Input
                    type="number"
                    prefix="$"
                    value={alquiler.montoInicial || ''}
                    onChange={(e) => setAlquiler(prev => ({ ...prev, montoInicial: e.target.value }))}
                />
            </Form.Item>

            {/* FECHAS */}
            <Form.Item label="Fecha de inicio">
                <DatePicker
                    style={{ width: '100%' }}
                    value={alquiler.fechaInicio ? dayjs(alquiler.fechaInicio) : null}
                    onChange={(date) =>
                        setAlquiler(prev => ({
                            ...prev,
                            fechaInicio: date ? date.format('YYYY-MM-DD') : null,
                        }))
                    }
                />
                {alquiler.fechaInicio && (
                    <Text type="secondary" style={{ marginTop: 8, display: 'block' }}>
                        <ClockCircleOutlined /> Comenzó hace {dayjs(alquiler.fechaInicio).fromNow()}
                    </Text>
                )}
            </Form.Item>

            <Form.Item label="Fecha de fin">
                <DatePicker
                    style={{ width: '100%' }}
                    value={alquiler.fechaFin ? dayjs(alquiler.fechaFin) : null}
                    onChange={(date) =>
                        setAlquiler(prev => ({
                            ...prev,
                            fechaFin: date ? date.format('YYYY-MM-DD') : null,
                        }))
                    }
                />
                {alquiler.fechaFin && (
                    <Text type="secondary" style={{ marginTop: 8, display: 'block' }}>
                        <ClockCircleOutlined /> Faltan {dayjs(alquiler.fechaFin).toNow(true)}
                    </Text>
                )}
            </Form.Item>

            <Divider />

            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                Actualizar
            </Button>

            {cargandoMapa && <Spin style={{ marginTop: 10 }} />}
        </Form>
    );
};

export default FormEditarAlquiler;
