import React, { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import ModalFacturacion from "./ModalFacturacion";
import EmisorService from "../../../services/EmisorService";

const BotonFacturacion = ({ alquiler, setAlquiler, navigate }) => {
    const [visible, setVisible] = useState(false);
    const [emisores, setEmisores] = useState([]);
    const [loading, setLoading] = useState(false);

    const cargarEmisores = async () => {
        try {
            setLoading(true);
            const res = await EmisorService.obtenerTodos();
            setEmisores(res.data);
        } catch (error) {
            message.error("No se pudieron cargar los emisores");
        } finally {
            setLoading(false);
        }
    };

    const handleOpen = () => {
        setVisible(true);
        cargarEmisores();
    };

    return (
        <>
            <Button
                icon={<CalendarOutlined />}
                style={{ height: '50px' }}
                onClick={handleOpen}
            >
                Facturación
            </Button>

            <ModalFacturacion
                visible={visible}
                onClose={() => setVisible(false)}
                alquiler={alquiler}
                setAlquiler={setAlquiler}
                emisores={emisores}
                navigate={navigate}
                loadingEmisores={loading}
            />
        </>
    );
};

export default BotonFacturacion;
