import { useEffect, useState } from "react";
import { Button, Modal, message } from "antd";
import { FundOutlined } from "@ant-design/icons";
import PagoChart from "../../../pago/sub/PagoChart";
import PagoListView from "../../../pago/sub/PagoListView";
import AlquilerService from "../../../../services/AlquilerService";
import Loader from "../../../sub/Loader";

const ListaPagosModal = ({ alquilerId }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [pagos, setPagos] = useState([]);
    const [loading, setLoading] = useState(false);
    const abrirModal = async () => {
        setModalVisible(true);
        setLoading(true);
        try {
            const pagosRes = await AlquilerService.obtenerPagos(alquilerId);
            setPagos(pagosRes.data);
        } catch (error) {
            message.error("No se pudieron cargar los pagos");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button
                icon={<FundOutlined />}
                style={{ height: "50px" }}
                onClick={abrirModal}
            >
                Pagos
            </Button>

            <Modal
                title="Lista de pagos"
                open={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
                maskClosable
                closable
                width={700}
            >
                {loading ? (
                    <Loader mensaje="Cargando pagos..." />
                ) : (
                    <>
                        <PagoChart pagos={pagos} />
                        <PagoListView pagos={pagos} onClose={() => setModalVisible(false)} />
                    </>
                )}
            </Modal>
        </>
    );
};

export default ListaPagosModal;
