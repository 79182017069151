import React, { useState, useMemo } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from "recharts";
import { Button, Card } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { motion, AnimatePresence } from "framer-motion";
import dayjs from "dayjs";
import "dayjs/locale/es";

dayjs.locale("es");

const PagoChartView = ({ pagos }) => {
    const [visible, setVisible] = useState(true); // Ahora inicia visible

    // Procesar pagos por mes
    const data = useMemo(() => {
        const resumen = pagos.reduce((acc, pago) => {
            const mes = dayjs(pago.fechaPago).format("MMM YYYY");
            acc[mes] = (acc[mes] || 0) + pago.montoPagado;
            return acc;
        }, {});

        return Object.entries(resumen).map(([mes, monto]) => ({
            mes,
            monto: monto.toFixed(2)
        }));
    }, [pagos]);

    return (
        <>
            {/* FAB para ocultar o mostrar el gráfico */}
            <Button
                type="primary"
                shape="circle"
                icon={visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                size="large"
                onClick={() => setVisible(!visible)}
                style={{
                    position: "fixed",
                    bottom: 20,
                    right: 20,
                    zIndex: 1000,
                    boxShadow: "0 4px 6px rgba(0,0,0,0.2)"
                }}
            />

            {/* Contenedor del gráfico con animación */}
            <AnimatePresence>
                {visible && (
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                        style={{ marginBottom: "1rem" }}
                    >
                        <Card title="Ganancia Mensual">
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                    <XAxis dataKey="mes" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="monto" fill="#1890ff" name="Ganancia (AR$)" />
                                </BarChart>
                            </ResponsiveContainer>
                        </Card>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default PagoChartView;
