import React, {useEffect, useState} from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
    Form, Input, Button, Typography, message,
    Upload, Space, Divider, List, Tooltip, Modal
} from "antd";
import {UploadOutlined, FileTextOutlined, RightOutlined, ContainerOutlined, SearchOutlined} from "@ant-design/icons";
import EmpresaService from "../../services/EmpresaService";
import dayjs from "dayjs";
import {Document, Page} from 'react-pdf';
import AlquileresDeEmpresaModal from "./AlquileresDeEmpresaModal";

const {Title, Text} = Typography;

const EmpresaForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [documentos, setDocumentos] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [nombresDocs, setNombresDocs] = useState([]);

    /* Para buscar en CUIT online*/
    const [cuitInput, setCuitInput] = useState('');
    const [razonInput, setRazonInput] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [busquedaCuitOnline, setBusquedaCuitOnline] = useState('');

    useEffect(() => {
        if (id) {
            setLoading(true);
            EmpresaService.obtenerPorId(id)
                .then((res) => {
                    const empresa = res.data;
                    form.setFieldsValue(empresa);
                    setDocumentos(empresa.documentos || []);

                    setNombresDocs([]);
                    setFileList([]);
                })
                .catch(() => message.error("Error al cargar empresa"))
                .finally(() => setLoading(false));
        }
    }, [id, form]);


    const handleAgregarDocumento = () => {
        setFileList([...fileList, null]);
        setNombresDocs([...nombresDocs, ""]);
    };

    const handleChangeNombre = (index, value) => {
        const nuevos = [...nombresDocs];
        nuevos[index] = value;
        setNombresDocs(nuevos);
    };

    const handleArchivoChange = (index, {file}) => {
        const nuevos = [...fileList];
        nuevos[index] = file;
        setFileList(nuevos);
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("razonSocial", values.razonSocial);
            formData.append("direccion", values.direccion);
            formData.append("cuit", values.cuit);
            formData.append("condicionIva", values.condicionIva || "Responsable Inscripto");

            fileList.forEach((file, i) => {
                if (file) {
                    formData.append("archivos", file);
                    formData.append(`nombresDocumentos[${i}]`, nombresDocs[i] || `Documento ${i + 1}`);
                }
            });


            if (id) {
                await EmpresaService.actualizar(id, formData);
                message.success("Empresa actualizada correctamente");
            } else {
                await EmpresaService.guardar(formData);
                message.success("Empresa creada correctamente");
            }
            navigate("/empresas");
        } catch {
            message.error("Error al guardar empresa");
        } finally {
            setLoading(false);
        }
    };
    const handlePdfLoadError = (err) => {
        console.warn("Error cargando PDF", err);
    };

    const renderPdfPreview = (url) => (
        <div style={{borderRadius: 4, padding: 4, width: "100%"}}>
            <Document
                file={url}
                onLoadError={handlePdfLoadError}
                loading="Cargando vista previa del PDF..."
                error={
                    <Button
                        type="primary"
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        size="small"
                        icon={<FileTextOutlined/>}
                    >
                        Ver documento PDF
                    </Button>
                }
            >
                <Page pageNumber={1} width={400}/>
            </Document>
        </div>
    );
    const handleEliminarEmpresa = () => {
        EmpresaService.obtenerAlquileresVinculados(id)
            .then(response => {
                const vinculados = response.data;
                if (vinculados.length > 0) {
                    Modal.warning({
                        title: "No se puede eliminar la empresa",
                        content: (
                            <>
                                <Text>Esta empresa está vinculada a los siguientes contratos:</Text>
                                <List
                                    size="small"
                                    dataSource={vinculados}
                                    style={{ marginTop: 12 }}
                                    renderItem={(a) => (
                                        <List.Item
                                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                        >
                                            <Text>
                                                {a.apodo
                                                    ? a.apodo
                                                    : <>Contrato de {a.contenedor?.codigo}</> || a.cliente?.nombre || 'Contrato sin nombre'}
                                            </Text>
                                            <Button
                                                size="small"
                                                type="link"
                                                href={`/alquileres/${a.id}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                                            >
                                                Ver <RightOutlined />
                                            </Button>

                                        </List.Item>
                                    )}
                                />
                            </>
                        )
                    });
                } else {
                    Modal.confirm({
                        title: "¿Eliminar empresa?",
                        content: "Esta acción no se puede deshacer.",
                        okText: "Sí, eliminar",
                        okType: "danger",
                        cancelText: "Cancelar",
                        onOk: () => {
                            EmpresaService.eliminar(id)
                                .then(() => {
                                    message.success("Empresa eliminada");
                                    navigate("/empresas");
                                })
                                .catch(() => message.error("Error al eliminar la empresa"));
                        }
                    });
                }
            })
            .catch(() => message.error("No se pudo verificar si tiene contratos asociados"));
    };

    return (
        <div style={{maxWidth: 500, margin: "auto", padding: "6rem 2rem 3rem 4rem"}}>
            <Title level={2}>{id ? "Editar empresa" : "Crear empresa"}</Title>

            {id && <AlquileresDeEmpresaModal empresaId={id}/>}

            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{razonSocial: "", direccion: "", cuit: "", condicionIva: "Responsable Inscripto"}}
            >
                <Form.Item
                    label="Razón social"
                    name="razonSocial"
                    rules={[{ required: true, message: "Campo obligatorio" }]}
                >
                    <Input
                        placeholder="La lupa busca en CUIT Online"
                        value={razonInput}
                        onChange={(e) => {
                            setRazonInput(e.target.value);
                            form.setFieldsValue({ razonSocial: e.target.value });
                        }}
                        suffix={
                            <Tooltip title="Buscar en CUIT Online">
                                <SearchOutlined
                                    onClick={() => {
                                        if (razonInput.trim()) {
                                            setBusquedaCuitOnline(razonInput.trim());
                                            setModalVisible(true);
                                        }
                                    }}
                                    style={{
                                        cursor: razonInput.trim() ? 'pointer' : 'not-allowed',
                                        color: '#1890ff',
                                    }}
                                />
                            </Tooltip>
                        }
                    />
                </Form.Item>

                <Form.Item label="Dirección" name="direccion" rules={[{required: true, message: "Campo obligatorio"}]}>
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="CUIT"
                    name="cuit"
                    rules={[
                        { required: true, message: 'Ingrese el CUIT' },
                        {
                            pattern: /^\d{2}-\d{8}-\d{1}$/,
                            message: 'El CUIT debe tener el formato XX-XXXXXXXX-X',
                        },
                    ]}
                >
                    <Input
                        placeholder="La lupa busca en CUIT Online"
                        value={cuitInput}
                        onChange={(e) => {
                            setCuitInput(e.target.value);
                            form.setFieldsValue({ cuit: e.target.value });
                        }}
                        suffix={
                            <Tooltip title="Buscar en CUIT Online">
                                <SearchOutlined
                                    onClick={() => {
                                        if (cuitInput.trim()) {
                                            setBusquedaCuitOnline(cuitInput.trim());
                                            setModalVisible(true);
                                        }
                                    }}
                                    style={{
                                        cursor: cuitInput.trim() ? 'pointer' : 'not-allowed',
                                        color: '#1890ff',
                                    }}
                                />
                            </Tooltip>
                        }
                    />
                </Form.Item>


                <Form.Item label="Condición IVA" name="condicionIva">
                    <Input/>
                </Form.Item>

                <Divider/>

                {/* Documentos ya cargados */}
                {documentos.length > 0 && (
                    <>
                        <Title level={5}>Documentos ya cargados</Title>
                        <List
                            bordered
                            size="small"
                            dataSource={documentos}
                            renderItem={(doc) => (
                                <List.Item style={{display: "flex", flexDirection: "column", alignItems: "start"}}>
                                    <Space size="small" align="center" style={{marginBottom: 4}}>
                                        <FileTextOutlined style={{fontSize: 18}}/>
                                        <a href={doc.urlArchivo} target="_blank" rel="noopener noreferrer">
                                            {doc.nombre}
                                        </a>
                                        <Text type="secondary" style={{fontSize: 12}}>
                                            (Subido el {dayjs(doc.fechaSubida).format("DD/MM/YYYY")})
                                        </Text>
                                    </Space>

                                    {/* Vista previa */}
                                    {doc.urlArchivo?.toLowerCase().includes(".pdf")
                                        ? renderPdfPreview(doc.urlArchivo)
                                        : (
                                            <img
                                                src={doc.urlArchivo}
                                                alt="Vista previa"
                                                style={{maxWidth: "100%", maxHeight: 200, borderRadius: 4}}
                                            />
                                        )}
                                </List.Item>

                            )}
                        />
                        <Divider/>
                    </>
                )}


                {/* Nuevos documentos */}
                <Title level={5}>Agregar documentos</Title>

                {fileList.map((file, index) => (
                    <div key={index}
                         style={{border: "1px solid #f0f0f0", borderRadius: 8, padding: 12, marginBottom: 16}}>
                        <Form.Item
                            label="Descripción"
                            required
                            style={{marginBottom: 8}}
                        >
                            <Input
                                placeholder="Ej: Contrato, habilitación, etc."
                                value={nombresDocs[index]}
                                onChange={(e) => handleChangeNombre(index, e.target.value)}
                            />
                        </Form.Item>

                        <Form.Item label="Archivo" required>
                            <Upload
                                beforeUpload={() => false}
                                showUploadList={{showRemoveIcon: false}}
                                onChange={(info) => handleArchivoChange(index, info)}
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined/>}>
                                    {file ? "Archivo seleccionado" : "Seleccionar archivo"}
                                </Button>
                            </Upload>
                        </Form.Item>

                        <Button danger size="small" onClick={() => {
                            const nuevosArchivos = [...fileList];
                            const nuevosNombres = [...nombresDocs];
                            nuevosArchivos.splice(index, 1);
                            nuevosNombres.splice(index, 1);
                            setFileList(nuevosArchivos);
                            setNombresDocs(nuevosNombres);
                        }}>
                            Eliminar documento
                        </Button>
                    </div>
                ))}

                <Button onClick={handleAgregarDocumento} type="dashed" block icon={<UploadOutlined/>}>
                    Agregar documento
                </Button>

                <Divider/>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Guardar
                    </Button>
                    {id && (
                        <Button danger onClick={handleEliminarEmpresa} style={{marginLeft: "10px"}}>
                            Eliminar
                        </Button>
                    )}
                    <Button onClick={() => navigate("/empresas")} style={{marginLeft: "10px"}}>
                        Volver
                    </Button>
                </Form.Item>

            </Form>
            <Modal
                title="Buscar en CUIT Online"
                open={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
                width={800}
                style={{ top: 80 }}
                bodyStyle={{ padding: 0 }}
            >
                <iframe
                    src={`https://www.cuitonline.com/search.php?q=${encodeURIComponent(busquedaCuitOnline)}`}
                    width="100%"
                    height="600"
                    frameBorder="0"
                    title="CUIT Online"
                />
            </Modal>

        </div>
    );
};

export default EmpresaForm;
