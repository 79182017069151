import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import PresupuestoService from '../../services/PresupuestoService';
import ClienteService from '../../services/ClienteService';
import EmpresaService from '../../services/EmpresaService';
import {Form, Input, Select, Button, message} from 'antd';

const {Option} = Select;

const PresupuestoForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm(); // 1. Crear una instancia de formulario
    const [presupuesto, setPresupuesto] = useState({
        clienteId: '',
        empresaId: '',
        servicio: 'Alquiler de contenedor refrigerado (reefer) de 40 pies (AR $500.000 + impuestos).',
        transporte: 'No incluido.',
        plazoDeEntrega: 'Una vez confirmada la operación, se acuerda con el cliente la fecha de entrega.',
        valor: '500000',
    });
    const [clientes, setClientes] = useState([]);
    const [empresas, setEmpresas] = useState([]);

    useEffect(() => {
        ClienteService.obtenerTodos().then(response => {
            setClientes(response.data);
        }).catch(error => {
            message.error('Error fetching clientes:', error);
        });

        EmpresaService.obtenerTodos().then(response => {
            setEmpresas(response.data);
        }).catch(error => {
            message.error('Error fetching empresas:', error);
        });

        if (id) {
            PresupuestoService.obtenerPorId(id).then(response => {
                const data = response.data;
                setPresupuesto({
                    ...data,
                    clienteId: data.cliente ? data.cliente.id : "",
                    empresaId: data.empresa ? data.empresa.id : ""
                });
            }).catch(error => {
                message.error('Error fetching presupuesto:', error);
            });
        }
    }, [id]);

    useEffect(() => {
        form.setFieldsValue(presupuesto);
    }, [presupuesto, form]);

    const handleChangeCliente = value => {
        setPresupuesto(prevState => ({
            ...prevState,
            clienteId: value
        }));
    };

    const handleChangeEmpresa = value => {
        setPresupuesto(prevState => ({
            ...prevState,
            empresaId: value
        }));
    };

    const handleVerCliente = () => {
        if (presupuesto.clienteId) {
            navigate(`/clientes/${presupuesto.clienteId}`);
        } else {
            alert('Por favor, seleccione un cliente primero.');
        }
    };

    const handleCrearCliente = () => {
        navigate(`/clientes/nuevo`);
    };

    const handleVerEmpresa = () => {
        if (presupuesto.empresaId) {
            navigate(`/empresas/${presupuesto.empresaId}`);
        } else {
            alert('Por favor, seleccione una empresa primero.');
        }
    };

    const handleCrearEmpresa = () => {
        navigate(`/empresas/nuevo`);
    };

    const handleSubmit = (values) => {
        const presupuestoData = {
            cliente: {id: values.clienteId},
            empresa: {id: values.empresaId},
            servicio: values.servicio,
            transporte: values.transporte,
            plazoDeEntrega: values.plazoDeEntrega,
            valor: values.valor,
        };

        if (id) {
            PresupuestoService.actualizar(id, presupuestoData).then(() => {
                navigate('/presupuestos');
            }).catch(error => {
                message.error('Error al guardar presupuesto:', error);
            });
        } else {
            PresupuestoService.guardar(presupuestoData).then(() => {
                navigate('/presupuestos');
            }).catch(error => {
                message.error('Error al crear presupuesto:', error);
            });
        }
    };

    const handleVerPresupuesto = async () => {
        if (id)
            navigate(`/presupuestos/${id}/ver`);
        else
            alert("El presupuesto debe ser creado para verse");
    };

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <h2>{id ? 'Editar Presupuesto' : 'Crear Presupuesto'}</h2>
            <Form
                form={form} // 3. Asignar la instancia del formulario
                layout="vertical"
                onFinish={handleSubmit}
            >
                <Form.Item
                    label={presupuesto.clienteId ? "Cliente" : "Seleccionar cliente"}
                    name="clienteId"
                    rules={[{required: true, message: 'Por favor, seleccione un cliente.'}]}
                    style={{marginBottom: 0}}
                >
                    <Select
                        value={presupuesto.clienteId || undefined}
                        onChange={handleChangeCliente}
                    >
                        {clientes.map(cliente => (
                            <Option key={cliente.id} value={cliente.id}>
                                {cliente.nombre}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Button type="link" onClick={handleVerCliente}>Ver</Button>
                <Button type="link" onClick={handleCrearCliente}>Crear</Button>

                <Form.Item
                    label={presupuesto.empresaId ? "Empresa" : "Seleccionar empresa"}
                    name="empresaId"
                    rules={[{required: true, message: 'Por favor, seleccione una empresa.'}]}
                    style={{marginBottom: 0}}
                >
                    <Select
                        value={presupuesto.empresaId || undefined}
                        onChange={handleChangeEmpresa}
                    >
                        {empresas.map(empresa => (
                            <Option key={empresa.id} value={empresa.id}>
                                {empresa.razonSocial}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Button type="link" onClick={handleVerEmpresa}>Ver</Button>
                <Button type="link" onClick={handleCrearEmpresa}>Crear</Button>


                <Form.Item
                    label="Servicio"
                    name="servicio"
                    rules={[{required: true, message: 'Por favor, ingrese el servicio.'}]}
                >
                    <Input.TextArea
                        value={presupuesto.servicio || ""}
                        onChange={e => setPresupuesto({...presupuesto, servicio: e.target.value})}
                    />
                </Form.Item>

                <Form.Item
                    label="Transporte"
                    name="transporte"
                    rules={[{required: true, message: 'Por favor, ingrese el transporte.'}]}
                >
                    <Input.TextArea
                        value={presupuesto.transporte || ""}
                        onChange={e => setPresupuesto({...presupuesto, transporte: e.target.value})}
                    />
                </Form.Item>

                <Form.Item
                    label="Plazo de entrega"
                    name="plazoDeEntrega"
                    rules={[{required: true, message: 'Por favor, ingrese el plazo de entrega.'}]}
                >
                    <Input.TextArea
                        value={presupuesto.plazoDeEntrega || ""}
                        onChange={e => setPresupuesto({...presupuesto, plazoDeEntrega: e.target.value})}
                    />
                </Form.Item>

                <Form.Item
                    label="Valor"
                    name="valor"
                    rules={[{required: true, message: 'Por favor, ingrese el valor.'}]}
                >
                    <Input
                        value={presupuesto.valor || ""}
                        onChange={e => setPresupuesto({...presupuesto, valor: e.target.value})}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">Guardar</Button>
                    <Button type="default" onClick={handleVerPresupuesto} style={{marginLeft: '10px'}}>
                        Ver presupuesto
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default PresupuestoForm;
