import React, { useState, useEffect } from 'react';
import { Typography, InputNumber, Button, Divider, Tag, Collapse, Input, theme, message, Space } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import PeriodoService from "../../../services/PeriodoService";
import PagosListPeriodo from "./PagosListPeriodo";
import ConfiguracionSistemaService from '../../../services/ConfiguracionSistemaService';

const { Text } = Typography;
const { Panel } = Collapse;

const PeriodosAlquiler = ({ alquilerId, periodos, pagos, navigate }) => {
    const { token } = theme.useToken();

    const formatoFecha = new Intl.DateTimeFormat("es-AR", {
        weekday: "short",
        day: "2-digit",
        month: "long",
        year: "numeric"
    });

    const formatoPesos = new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
        minimumFractionDigits: 0
    });

    const [montosEditados, setMontosEditados] = useState({});
    const [itemsPeriodo, setItemsPeriodo] = useState([]);
    const [penalidadPorcentaje, setPenalidadPorcentaje] = useState(null);

    useEffect(() => {
        const cargarConfiguracion = async () => {
            try {
                const resConfig = await ConfiguracionSistemaService.obtener();
                setPenalidadPorcentaje(resConfig.data.penalidadMoraPorcentual);
            } catch (error) {
                message.error('Error al cargar configuración');
                console.error('Error al cargar configuración:', error);
            }
        };

        cargarConfiguracion();
    }, [alquilerId]);

    useEffect(() => {
        if (periodos.length > 0 && penalidadPorcentaje !== null) {
            const itemsCalculados = calcularItemsPeriodo(periodos, pagos, penalidadPorcentaje);
            setItemsPeriodo(itemsCalculados);
        }
    }, [periodos, pagos, penalidadPorcentaje]);


    const calcularItemsPeriodo = (periodos, pagos, penalidadPorcentaje) => {
        const pagosOrdenados = pagos.map(p => ({ ...p })).sort((a, b) => new Date(a.fechaPago) - new Date(b.fechaPago));
        let indicePago = 0;

        return periodos.map(periodo => {
            let acumuladoPeriodo = 0;
            let fechaUltimoPago = null;
            const pagosAsignados = [];

            while (indicePago < pagosOrdenados.length && acumuladoPeriodo < periodo.monto) {
                const pagoActual = pagosOrdenados[indicePago];
                const necesario = periodo.monto - acumuladoPeriodo;
                const aplicado = Math.min(necesario, pagoActual.montoPagado);

                acumuladoPeriodo += aplicado;
                pagosAsignados.push({
                    ...pagoActual,
                    aplicado
                });

                pagoActual.montoPagado -= aplicado;
                fechaUltimoPago = pagoActual.fechaPago;

                if (pagoActual.montoPagado <= 0) {
                    indicePago++;
                }
            }

            const hoy = dayjs();
            const fechaInicio = dayjs(periodo.fechaInicio);
            const estaCompleto = acumuladoPeriodo >= periodo.monto;
            const fechaCompletoPeriodo = estaCompleto ? dayjs(fechaUltimoPago) : null;
            const pagadoFueraDeTermino = estaCompleto && fechaCompletoPeriodo.isAfter(fechaInicio);

            let diasMora = 0, penalidad = 0;
            if (pagadoFueraDeTermino) {
                diasMora = fechaCompletoPeriodo.diff(fechaInicio, 'days');
                penalidad = periodo.monto * penalidadPorcentaje / 100 * diasMora;
            } else if (!estaCompleto && fechaInicio.isBefore(hoy)) {
                diasMora = hoy.diff(fechaInicio, 'days');
                penalidad = (periodo.monto - acumuladoPeriodo) * penalidadPorcentaje / 100 * diasMora;
            }

            return {
                periodo,
                acumuladoPago: acumuladoPeriodo,
                pagadoFueraDeTermino,
                diasMora,
                penalidadAcumulada: penalidad,
                montoPenalizado: penalidad,
                pagosAsignados
            };
        });
    };

    const handleMontoChange = (periodoId, value) => {
        setMontosEditados(prev => ({
            ...prev,
            [periodoId]: value
        }));
    };

    const actualizarMontoPeriodo = async (periodoId, periodoActualizado) => {
        try {
            await PeriodoService.actualizar(periodoId, periodoActualizado);
        } catch (error) {
            console.error("Error al actualizar el monto", error);
            throw error;
        }
    };

    const handleGuardarMonto = async (periodoId) => {
        const periodoActual = periodos.find(p => p.id === periodoId);
        if (!periodoActual) return message.error("No se encontró el período");

        try {
            await actualizarMontoPeriodo(periodoId, {
                ...periodoActual,
                monto: montosEditados[periodoId]
            });
            message.success("Monto actualizado");
            window.location.reload();
        } catch {
            message.error("Error al guardar");
        }
    };

    return (
        <div style={{padding: '10px'}}>
            {periodos.length === 0 ? (
                <Text>No hay períodos registrados.</Text>
            ) : (
                <Collapse
                    bordered={false}
                    accordion={false}
                    style={{marginTop: 16}}
                >
                    {itemsPeriodo.slice().reverse().map((item) => {
                    const {periodo, acumuladoPago, factura} = item;
                        const fechaInicio = dayjs(periodo.fechaInicio);
                        const fechaFin = dayjs(periodo.fechaFin);
                        const isPagoCompleto = periodo.monto - acumuladoPago === 0;
                        const isPagoVencido = !isPagoCompleto && dayjs().isAfter(fechaFin);

                        const pagosDelPeriodo = pagos.filter(pago => {
                            const fechaPago = dayjs(pago.fechaPago);
                            return fechaPago.isAfter(fechaInicio.subtract(1, 'day')) &&
                                fechaPago.isBefore(fechaFin.add(1, 'day'));
                        });

                        const isPagoMoroso = item.pagadoFueraDeTermino;

                        const tagEstado = isPagoCompleto && !isPagoMoroso ? (
                            <Tag color="green" style={{ fontSize: 16 }}>
                                <CheckCircleOutlined /> Pagado
                            </Tag>
                        ) : isPagoCompleto && isPagoMoroso ? (
                            <Tag color="gold" style={{ fontSize: 16 }}>
                                <ExclamationCircleOutlined /> Pago moroso
                            </Tag>
                        ) : isPagoVencido ? (
                            <Tag color="red" style={{ fontSize: 16 }}>
                                <CloseCircleOutlined /> Vencido
                            </Tag>
                        ) : (
                            <Tag color="orange" style={{ fontSize: 16 }}>
                                <ExclamationCircleOutlined /> Pendiente
                            </Tag>
                        );

                        return (
                            <Panel
                                key={periodo.id}
                                header={
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                        <span style={{textTransform: "uppercase"}}>
                                            {formatoFecha.format(fechaInicio.toDate())}
                                        </span>
                                        {tagEstado}
                                    </div>
                                }
                                style={{
                                    marginBottom: 16,
                                    background: token.colorBgContainer,
                                    borderRadius: 8,
                                    border: `1px solid ${token.colorBorder}`
                                }}
                            >
                                <Space direction="vertical" size="middle" style={{width: '100%'}}>
                                    {/* Monto editable */}
                                    <Space.Compact style={{width: '100%'}}>
                                        <Input
                                            value="Monto"
                                            readOnly
                                            style={{
                                                width: '80px',
                                                backgroundColor: token.colorFillSecondary,
                                                textAlign: 'center',
                                            }}
                                        />
                                        <InputNumber
                                            style={{flex: 1}}
                                            value={montosEditados[periodo.id] ?? periodo.monto}
                                            onChange={(v) => handleMontoChange(periodo.id, v)}
                                            formatter={(v) => `AR$ ${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                            parser={(v) => v.replace(/AR\$|\./g, "")}
                                            step={100000}
                                            min={0}
                                        />
                                        <Button type="primary" onClick={() => handleGuardarMonto(periodo.id)}>
                                            Guardar
                                        </Button>
                                    </Space.Compact>

                                    {/* Pagado + Pendiente + Total */}
                                    <div style={{ textAlign: "center", marginTop: 16 }}>
                                        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                                            {/* Total del período */}
                                            <Text strong style={{ fontSize: 16, color: token.colorTextSecondary }}>
                                                Total período: {formatoPesos.format(periodo.monto + Number(item.penalidadAcumulada))}
                                            </Text>

                                            {/* Total pagado */}
                                            <Text strong style={{ fontSize: 16, color: token.colorSuccess }}>
                                                Pagado: {formatoPesos.format(acumuladoPago)}
                                            </Text>

                                            {/* Pendiente */}
                                            {!isPagoCompleto && (
                                                <Text strong style={{ fontSize: 16, color: token.colorError }}>
                                                    Pendiente: {formatoPesos.format((periodo.monto + Number(item.penalidadAcumulada)) - acumuladoPago)}
                                                </Text>
                                            )}

                                            {/* Aviso de mora si aplica */}
                                            {item.pagadoFueraDeTermino && (
                                                <Text type="warning" style={{ display: "block", marginTop: 8 }}>
                                                    Se demoró {item.diasMora} día{item.diasMora !== 1 && 's'} en pagar este período. El próximo debería incluir un recargo de {formatoPesos.format(item.montoPenalizado)}.
                                                </Text>
                                            )}
                                        </Space>
                                    </div>





                                    {/* Pagos */}
                                    {pagosDelPeriodo.length > 0 ? (
                                        <>
                                            <Divider/>
                                            <PagosListPeriodo pagos={pagosDelPeriodo} formatoPesos={formatoPesos}/>
                                        </>
                                    ) : (
                                        <div style={{textAlign: "center"}}>
                                            <Tag color={isPagoVencido ? "red-inverse" : ""}>
                                                <ExclamationCircleOutlined/> Sin pagos
                                            </Tag>
                                        </div>
                                    )}

                                    <Divider/>

                                    {/* Factura */}
                                    {factura ? (
                                        <Button block type="dashed" onClick={() => navigate(`/facturas/${factura.id}`)}>
                                            Ver factura
                                        </Button>
                                    ) : (
                                        <Button block type="dashed"
                                                onClick={() => navigate(`/facturas/nuevo/alquiler/periodo/${periodo.id}`)}>
                                            Facturar
                                        </Button>
                                    )}
                                </Space>
                            </Panel>
                        );
                    })}
                </Collapse>

            )}
        </div>
    );
};

export default PeriodosAlquiler;
