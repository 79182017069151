import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { List, Typography, Button, message } from 'antd';
import FormulariosService from "../../services/FormulariosService";
import SolicitudesClienteView from "./SolicitudClienteListView";
// Asegúrate de que la ruta y el nombre del archivo sean correctos

const { Title } = Typography;

const SolicitudesClienteList = () => {
    const [solicitudes, setSolicitudes] = useState([]);

    useEffect(() => {
        FormulariosService.obtenerTodos()
            .then(response => {
                setSolicitudes(response.data);
            })
            .catch(error => {
                message.error('Error fetching solicitudes:', error);
                message.error('Error al obtener la lista de solicitudes de clientes');
            });
    }, []);

    return (
        <div style={{ maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem' }}>
            <Title level={2}>Lista de solicitudes de clientes</Title>
            <SolicitudesClienteView solicitudes={solicitudes} />
            <div style={{ marginTop: '1rem' }}>
                <Link to="/formularios/alta-cliente">
                    <Button type="primary">Crear nueva solicitud de cliente</Button>
                </Link>
            </div>
        </div>
    );
}

export default SolicitudesClienteList;
