import { List, Typography, Space, Button, Tag, Tooltip } from "antd";
import {
    FileTextOutlined,
    FileImageOutlined,
    ArrowRightOutlined
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/es"; // Para mostrar meses en español
dayjs.locale("es");

const PagosListPeriodo = ({ pagos, formatoPesos }) => {
    const navigate = useNavigate(); // Hook para navegación

    const renderPagoItem = (pago) => {
        const fechaPago = dayjs(pago.fechaPago);

        return (
            <List.Item style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Space>
                    <Typography.Text>{fechaPago.format("DD/MM/YYYY")}:</Typography.Text>
                    <Typography.Text strong>{formatoPesos.format(pago.montoPagado)}</Typography.Text>

                    {/* Icono para comprobante si existe */}
                    {pago.urlComprobante && (
                        pago.urlComprobante.match(/\.(jpg|jpeg|png|gif)$/) ? (
                                <FileImageOutlined style={{ color: "#1890ff" }} />
                            ) : (
                                <FileTextOutlined style={{ color: "#1890ff" }} />
                            )

                    )}
                </Space>

                {/* Ícono de acceso a detalles */}
                <Button
                    type="link"
                    icon={<ArrowRightOutlined style={{ fontSize: "16px" }} />}
                    onClick={() => navigate(`/pagos/${pago.id}`)}
                />
            </List.Item>
        );
    };

    return (
        <>
            {pagos.length > 0 ? (
                <List
                    dataSource={pagos}
                    renderItem={renderPagoItem}
                    size="small"
                    bordered
                />
            ) : (
                <Typography.Text style={{ fontSize: "14px" }}>
                    No hay pagos registrados para este período.
                </Typography.Text>
            )}
        </>
    );
};

export default PagosListPeriodo;
