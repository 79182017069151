import { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import ModalAlquiler from "./ModalAlquiler";
import ClienteService from "../../../services/ClienteService";
import ContenedorService from "../../../services/ContenedorService";
import EmpresaService from "../../../services/EmpresaService";

const BotonFormAlquiler = ({ alquiler, setAlquiler, cargandoMapa, navigate }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [clientes, setClientes] = useState([]);
    const [empresas, setEmpresas] = useState([]);
    const [contenedores, setContenedores] = useState([]);
    const [loading, setLoading] = useState(false);

    const abrirModal = async () => {
        setModalVisible(true);
        setLoading(true);
        try {
            const [clientesRes, empresasRes, contenedoresRes] = await Promise.all([
                ClienteService.obtenerTodos(),
                EmpresaService.obtenerTodos(),
                ContenedorService.obtenerTodos()
            ]);
            setClientes(clientesRes.data);
            setEmpresas(empresasRes.data);
            setContenedores(contenedoresRes.data);
        } catch (e) {
            console.error("Error cargando datos de edición", e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button
                icon={<EditOutlined />}
                onClick={abrirModal}
                style={{ height: 50 }}
            >
                Información
            </Button>

            <ModalAlquiler
                alquiler={alquiler}
                setAlquiler={setAlquiler}
                clientes={clientes}
                empresas={empresas}
                contenedores={contenedores}
                cargando={loading}
                open={modalVisible}
                setOpen={setModalVisible}
                cargandoMapa={cargandoMapa}
                navigate={navigate}
            />
        </>
    );
};

export default BotonFormAlquiler;
