// src/components/sub/EstadoAlquiler.js
import React from 'react';
import {Modal, Button, Row, Col, Typography, Tooltip, message, Form} from 'antd';
import AlquilerService from "../../../services/AlquilerService";


const EstadoAlquiler = ({ alquiler, setAlquiler }) => {
    const { Text } = Typography;

    const toggleAlquilerEstado = () => {
        Modal.confirm({
            title: `¿Seguro que deseas ${alquiler.activo ? 'desactivar' : 'activar'} este alquiler?`,
            onOk: () => {
                AlquilerService.activarDesactivar(alquiler.id);
                setAlquiler({ ...alquiler, activo: !alquiler.activo });
                message.success(`El alquiler ha sido ${alquiler.activo ? 'desactivado' : 'activado'}.`);
            }
        });
    };

    return (
        <Form.Item>
            <div style={{
                background: alquiler.activo ? '#f6ffed' : '#fff2f0',
                border: `1px solid ${alquiler.activo ? '#b7eb8f' : '#ffccc7'}`,
                borderRadius: '8px',
                padding: '16px',
            }}>
                <Row align="middle" justify="space-between" gutter={[16, 0]}>
                    <Col>
                        <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                            <i
                                className={
                                    alquiler.activo
                                        ? "bi bi-check-circle-fill"
                                        : "bi bi-exclamation-circle-fill"
                                }
                                style={{
                                    fontSize: '24px',
                                    color: alquiler.activo ? '#52c41a' : '#ff4d4f'
                                }}
                            />
                            <Text
                                style={{
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    color: alquiler.activo ? '#52c41a' : '#ff4d4f',
                                    margin: 0
                                }}
                            >
                                {alquiler.activo ? 'Alquiler activo' : 'Alquiler inactivo'}
                            </Text>
                        </div>
                    </Col>
                    <Col>
                        <div style={{display: 'flex', alignItems: 'center', gap: '12px'}}>
                            <Button
                                type={alquiler.activo ? 'default' : 'primary'}
                                danger={alquiler.activo}
                                onClick={toggleAlquilerEstado}
                                icon={alquiler.activo ?
                                    <i className="bi bi-pause-circle"/> :
                                    <i className="bi bi-play-circle"/>
                                }
                            >
                                {alquiler.activo ? 'Desactivar' : 'Activar'}
                            </Button>
                            <Tooltip
                                title="Mientras el contenedor esté en el domicilio, el alquiler debe estar activo.">
                                <i className="bi bi-info-circle"
                                   style={{
                                       fontSize: '16px',
                                       color: alquiler.activo ? '#52c41a' : '#ff4d4f',
                                       cursor: 'pointer'
                                   }}
                                />
                            </Tooltip>
                        </div>
                    </Col>
                </Row>
            </div>
        </Form.Item>
    );
};

export default EstadoAlquiler;