import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import ClienteService from "../../services/ClienteService";
import {
    List,
    Typography,
    Button,
    message,
    Space,
    Tooltip,
    Input,
    Card
} from "antd";
import {
    PhoneOutlined,
    MailOutlined,
    EnvironmentOutlined,
    WhatsAppOutlined,
    SearchOutlined,
    FileOutlined,
} from "@ant-design/icons";

const {Title} = Typography;

const ClienteList = () => {
    const [clientes, setClientes] = useState([]);
    const [filteredClientes, setFilteredClientes] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        ClienteService.obtenerTodos()
            .then(response => {
                const sorted = [...response.data].sort((a, b) =>
                    a.nombre.localeCompare(b.nombre)
                );
                setClientes(sorted);
                setFilteredClientes(sorted);
            })
            .catch(error => {
                console.error("Error fetching clientes:", error);
                message.error("No se pudo obtener la lista de clientes.");
            });
    }, []);

    useEffect(() => {
        const filtered = clientes.filter(cliente =>
            cliente.nombre.toLowerCase().includes(search.toLowerCase()) ||
            cliente.cuit?.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredClientes(filtered);
    }, [search, clientes]);

    return (
        <div style={{maxWidth: 500, margin: "auto", padding: "6rem 2rem 3rem 4rem"}}>
            <Title level={2}>Lista de clientes</Title>

            <Input
                placeholder="Buscar por nombre o CUIT"
                prefix={<SearchOutlined/>}
                value={search}
                onChange={e => setSearch(e.target.value)}
                style={{marginBottom: "1.5rem"}}
            />

            <List
                grid={{gutter: 8, column: 1}}
                dataSource={filteredClientes}
                renderItem={(cliente, index) => (
                    <List.Item>
                        <Card
                            bodyStyle={{paddingTop: 10, paddingBottom: 10}}
                            title={
                                <Link
                                    to={`/clientes/${cliente.id}`}
                                    style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        display: "inline-block",
                                        maxWidth: "calc(100% - 100px)"
                                    }}
                                >
                                    {index + 1}. {cliente.nombre}
                                </Link>
                            }

                            extra={<span style={{color: "#888"}}>{cliente.cuit}</span>}
                        >
                            <Space size="large">
                                <Tooltip title={cliente.telefono ? "Llamar" : "Teléfono no disponible"}>
                                    {cliente.telefono ? (
                                        <a href={`tel:${cliente.telefono}`}>
                                            <PhoneOutlined style={{ fontSize: 20 }} />
                                        </a>
                                    ) : (
                                        <span style={{ opacity: 0.1, cursor: "not-allowed" }}>
                                            <PhoneOutlined style={{ fontSize: 20 }} />
                                        </span>
                                    )}
                                </Tooltip>

                                <Tooltip title={cliente.telefono ? "Enviar WhatsApp" : "WhatsApp no disponible"}>
                                    {cliente.telefono ? (
                                        <a
                                            href={`https://wa.me/${cliente.telefono.replace(/\D/g, "")}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <WhatsAppOutlined style={{ fontSize: 20 }} />
                                        </a>
                                    ) : (
                                        <span style={{ opacity: 0.1, cursor: "not-allowed" }}>
                                            <WhatsAppOutlined style={{ fontSize: 20 }} />
                                        </span>
                                    )}
                                </Tooltip>

                                <Tooltip title={cliente.email ? "Enviar email" : "Email no disponible"}>
                                    {cliente.email ? (
                                        <a href={`mailto:${cliente.email}`}>
                                            <MailOutlined style={{ fontSize: 20 }} />
                                        </a>
                                    ) : (
                                        <span style={{ opacity: 0.1, cursor: "not-allowed" }}>
                                            <MailOutlined style={{ fontSize: 20 }} />
                                        </span>
                                    )}
                                </Tooltip>

                                <Tooltip title={cliente.direccion ? "Ver en Google Maps" : "Dirección no disponible"}>
                                    {cliente.direccion ? (
                                        <a
                                            href={`https://www.google.com/maps?q=${encodeURIComponent(cliente.direccion)}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <EnvironmentOutlined style={{ fontSize: 20 }} />
                                        </a>
                                    ) : (
                                        <span style={{ opacity: 0.1, cursor: "not-allowed" }}>
                                            <EnvironmentOutlined style={{ fontSize: 20 }} />
                                        </span>
                                    )}
                                </Tooltip>

                                <Tooltip title={cliente.cuit ? "Info del CUIT" : "CUIT no disponible"}>
                                    {cliente.cuit ? (
                                        <a
                                            href={`https://www.cuitonline.com/search.php?q=${cliente.cuit}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <SearchOutlined style={{ fontSize: 20 }} />
                                        </a>
                                    ) : (
                                        <span style={{ opacity: 0.1, cursor: "not-allowed" }}>
                                            <SearchOutlined style={{ fontSize: 20 }} />
                                        </span>
                                    )}
                                </Tooltip>
                                <Tooltip title={(cliente.urlDniFrontal || cliente.urlDniTrasero) ? "DNI cargado" : "Sin DNI"}>
                                    {(cliente.urlDniFrontal || cliente.urlDniTrasero) ? (
                                        <FileOutlined style={{ fontSize: 20, color: "#673ab7" }} />
                                    ) : (
                                        <span style={{ opacity: 0.1 }}>
                                        <FileOutlined style={{ fontSize: 20 }} />
                                    </span>
                                    )}
                                </Tooltip>

                            </Space>


                        </Card>
                    </List.Item>
                )}
            />

            <div style={{marginTop: "2rem", textAlign: "center"}}>
                <Link to="/clientes/nuevo">
                    <Button type="primary">Crear nuevo cliente</Button>
                </Link>
            </div>
        </div>
    );
};

export default ClienteList;
