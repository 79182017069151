// src/services/PagoService.js
import axiosInstance from '../utils/axiosInstance';
import config from '../config';
import axiosInstanceMultipart from "../utils/axiosInstanceMultipart";


const API_URL = `${config.API_URL}/pagos`;

class PagoService {
    obtenerTodos() {
        return axiosInstance.get(API_URL);
    }

    obtenerPorId(id) {
        return axiosInstance.get(`${API_URL}/${id}`);
    }

    guardar(pago) {
        return axiosInstanceMultipart.post(API_URL, pago);
    }

    actualizar(id, pago) {
        return axiosInstanceMultipart.put(`${API_URL}/${id}`, pago);
    }

    eliminar(id) {
        return axiosInstance.delete(`${API_URL}/${id}`);
    }
}

export default new PagoService();