import React, {useState, useEffect} from 'react';
import ModeloContratoService from '../../services/ModeloContratoService';
import {useParams, useNavigate} from 'react-router-dom';
import {Form, Input, Button, Typography, message, Modal, theme} from 'antd';

const {Title} = Typography;
const {TextArea} = Input;

const ModeloContratoForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [modeloContrato, setModeloContrato] = useState({titulo: '', contenido: ''});
    const [isModalVisible, setIsModalVisible] = useState(false);

    const {token} = theme.useToken(); // 🎯 para usar colores del theme actual

    useEffect(() => {
        if (id) {
            ModeloContratoService.obtenerPorId(id).then(response => {
                setModeloContrato(response.data);
            }).catch(error => {
                message.error('Error al obtener el modelo de contrato');
            });
        }
    }, [id]);

    const handleSubmit = async () => {
        try {
            if (id) {
                await ModeloContratoService.actualizar(id, modeloContrato);
            } else {
                await ModeloContratoService.guardar(modeloContrato);
            }
            message.success('Modelo guardado exitosamente');
            navigate('/modelosContrato');
        } catch (error) {
            message.error('Error al guardar el modelo');
        }
    };

    const handleContentChange = (e) => {
        setModeloContrato({...modeloContrato, contenido: e.target.value});
    };

    const handlePlaceholderInsert = (placeholder) => {
        const textArea = document.getElementById("contenido-textarea");
        const startPos = textArea.selectionStart;
        const endPos = textArea.selectionEnd;
        const currentContent = modeloContrato.contenido;

        const newContent = currentContent.slice(0, startPos) + placeholder + currentContent.slice(endPos);

        setModeloContrato({...modeloContrato, contenido: newContent});

        setTimeout(() => {
            textArea.selectionStart = textArea.selectionEnd = startPos + placeholder.length;
            textArea.focus();
        }, 0);

        setIsModalVisible(false);
    };

    const handleDelete = async () => {
        Modal.confirm({
            title: 'Eliminar modelo de contrato',
            content: '¿Estás seguro de que deseas eliminar este modelo?',
            okText: 'Eliminar',
            cancelText: 'Cancelar',
            onOk: async () => {
                try {
                    await ModeloContratoService.eliminar(id);
                    message.warning('Modelo eliminado.');
                    navigate('/modelosContrato');
                } catch (error) {
                    message.error(`Error al eliminar el modelo: ${error.message}`);
                }
            },
        });
    };

    const getContenidoResaltado = (contenido) => {
        const bgColor = "yellow"; // 🟢 verde suave según tema
        const textColor = "black"; // 🧑‍🎨 texto adaptable
        return contenido
            .replace(/({[^}]+})/g, `<span style="background-color: ${bgColor}; font-weight: bold; color: ${textColor}; padding: 2px 4px; border-radius: 3px;">$1</span>`)
            .replace(/\n/g, '<br/>');
    };

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2}>{id ? modeloContrato.titulo : 'Crear modelo de contrato'}</Title>
            <Form layout="vertical" onFinish={handleSubmit}>
                <Form.Item label="Título" required>
                    <Input
                        value={modeloContrato.titulo}
                        onChange={e => setModeloContrato({...modeloContrato, titulo: e.target.value})}
                        placeholder="Ingrese el título del contrato"
                    />
                </Form.Item>

                <Form.Item label="Contenido del contrato" required>
                    <TextArea
                        id="contenido-textarea"
                        value={modeloContrato.contenido}
                        onChange={handleContentChange}
                        rows={10}
                        placeholder="Ingrese el contenido del contrato"
                        style={{resize: 'vertical', padding: '10px', fontSize: '16px'}}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">Guardar</Button>
                    <Button style={{marginLeft: 10}} onClick={() => setIsModalVisible(true)}>
                        Insertar campo
                    </Button>
                    {id && (
                        <Button danger onClick={handleDelete} style={{marginLeft: 10}}>
                            Eliminar
                        </Button>
                    )}
                </Form.Item>
                <Title level={3}>Vista previa</Title>
                <div style={{
                    border: `1px solid ${token.colorBorder}`,
                    borderRadius: token.borderRadiusLG,
                    padding: 12,
                    marginTop: 16,
                    fontSize: 14,
                    whiteSpace: 'pre-wrap',
                    lineHeight: 1.5,
                    color: token.colorText,
                }}>
                    <div
                        dangerouslySetInnerHTML={{__html: getContenidoResaltado(modeloContrato.contenido)}}
                        style={{
                            marginTop: 8,
                            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif"
                        }}
                    />
                </div>


            </Form>

            {/* Modal de placeholders */}
            <Modal
                title="Seleccionar un campo"
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <p>Al tocar un botón, se va a escribir un código en el modelo que representa lo que va a ir cuando se
                    genere el contrato real.</p>

                {[
                    {grupo: 'Empresa', campos: ['NOMBRE_EMPRESA', 'DIRECCION_EMPRESA', 'CUIT_EMPRESA']},
                    {grupo: 'Cliente', campos: ['NOMBRE_CLIENTE', 'DIRECCION_CLIENTE', 'CUIT_CLIENTE']},
                    {grupo: 'Garante', campos: ['NOMBRE_GARANTE', 'DIRECCION_GARANTE', 'CUIT_GARANTE']},
                    {grupo: 'El que factura', campos: ['NOMBRE_EMISOR', 'DIRECCION_EMISOR', 'CUIT_EMISOR']},
                    {grupo: 'Contenedor', campos: ['CODIGO_CONTENEDOR', 'LONGITUD_CONTENEDOR', 'TIPO_CONTENEDOR']},
                    {grupo: 'Alquiler', campos: ['FECHA_INICIO', 'UBICACION', 'MONTO']},
                ].map(({grupo, campos}) => (
                    <>
                        <Title level={4}>{grupo}</Title>
                        <div key={grupo} style={{marginBottom: 12}}>
                            {campos.map(nombre => (
                                <Button
                                    key={nombre}
                                    style={{margin: '0 8px 8px 0'}}
                                    onClick={() => handlePlaceholderInsert(`{${nombre}}`)}
                                >
                                    {nombre.replace(grupo.toUpperCase(), '').replace('_', ' ').toLowerCase()}
                                </Button>
                            ))}
                        </div>
                    </>
                ))}
            </Modal>
        </div>
    );
};

export default ModeloContratoForm;
