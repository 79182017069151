import axiosInstanceMultipart from "../utils/axiosInstanceMultipart";
import axiosInstance from "../utils/axiosInstance";
import config from "../config";

const API_URL = `${config.API_URL}/alquileres`;

class AlquilerService {
    obtenerTodos() {
        return axiosInstance.get(API_URL);
    }

    obtenerPorId(id) {
        return axiosInstance.get(`${API_URL}/${id}`);
    }

    /*
    obtenerFullPorId(id) {
        return axiosInstanceMultipart.get(`${API_URL}/${id}/full`);
    }
     */

    guardar(alquiler) {
        return axiosInstance.post(API_URL, alquiler);
    }

    actualizar(id, alquiler) {
        return axiosInstance.put(`${API_URL}/${id}`, alquiler);
    }

    eliminar(id) {
        return axiosInstance.delete(`${API_URL}/${id}`);
    }

    obtenerPagos(id) {
        return axiosInstance.get(`${API_URL}/${id}/pagos`);
    }

    activarDesactivar(id) {
        return axiosInstance.put(`${API_URL}/${id}/activarDesactivar`);
    }

    verContrato(id, contratoId) {
        return axiosInstance.get(`${API_URL}/${id}/contrato/${contratoId}/ver`);
    }

    async subirContrato(id, formData) {
        try {
            console.log("FormData antes de envío:", formData.get("archivo")); // Verifica si el archivo está en el FormData

            const response = await axiosInstanceMultipart.put(`${API_URL}/${id}/contrato`, formData);
            console.log("Respuesta del backend:", response.data);
            return response;
        } catch (error) {
            console.error("Error al subir el contrato:", error);
            throw error;
        }
    }

    obtenerContrato(id, config = {}) {
        return axiosInstance.get(`${API_URL}/${id}/ver-contrato`, config);
    }


}

export default new AlquilerService();
