import React, { useState, useEffect, useRef } from "react";
import {
    MapContainer,
    TileLayer,
    Marker,
    CircleMarker,
    Polyline,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { message } from "antd";
import ContenedorPopup from "./ContenedorPopup";
import AlquilerService from "../../services/AlquilerService";
import Loader from "../sub/Loader";
import { obtenerContenedoresConUltimoAlquiler } from "./obtenerContenedoresConUltimoAlquiler";

const obtenerIcono = (clave) =>
    new L.Icon({
        iconUrl: `/reefer/${clave.slice(0, 3)}.png`,
        iconSize: [48, 48],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowUrl: "/reefer/shadow.png",
        shadowSize: [50, 50],
        shadowAnchor: [12, 41],
    });

const MapaDirecciones = ({ isDarkMode }) => {
    const [alquileres, setAlquileres] = useState([]);
    const [trazo, setTrazo] = useState([]);
    const [puntos, setPuntos] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [filtroContenedor, setFiltroContenedor] = useState(null);
    const [contenedoresConUltimoAlquiler, setContenedoresConUltimoAlquiler] = useState([]);
    const mapRef = useRef();

    // Cargar alquileres y generar marcadores
    useEffect(() => {
        const cargarDatos = async () => {
            try {
                const response = await AlquilerService.obtenerTodos();

                const conCoordenadas = response.data.filter(a => a.coordenadas);
                const ordenados = conCoordenadas.sort(
                    (a, b) => new Date(a.fechaInicio) - new Date(b.fechaInicio)
                );

                const marcadores = ordenados.map(alquiler => {
                    const [lat, lon] = alquiler.coordenadas.split(",").map(coord => parseFloat(coord.trim()));
                    return { alquiler, lat, lon };
                });

                setAlquileres(marcadores);
                setContenedoresConUltimoAlquiler(obtenerContenedoresConUltimoAlquiler(ordenados));
            } catch (error) {
                message.error("Error obteniendo alquileres");
            } finally {
                setCargando(false);
            }
        };

        cargarDatos();
    }, []);

    // Enfocar al contenedor seleccionado
    useEffect(() => {
        if (!filtroContenedor || alquileres.length === 0) return;

        const relacionados = alquileres
            .filter(m => m.alquiler.contenedor.id === filtroContenedor)
            .sort((a, b) => new Date(b.alquiler.fechaInicio) - new Date(a.alquiler.fechaInicio));

        const ultimo = relacionados[0];
        if (ultimo && mapRef.current) {
            mapRef.current.setView([ultimo.lat, ultimo.lon], 15);
        }
    }, [filtroContenedor, alquileres]);

    const manejarClickContenedor = (contenedorId) => {
        const relacionados = alquileres.filter(m => m.alquiler.contenedor.id === contenedorId);
        const ordenados = relacionados.sort(
            (a, b) => new Date(a.alquiler.fechaInicio) - new Date(b.alquiler.fechaInicio)
        );

        const recorrido = ordenados.map(m => [m.lat, m.lon]);
        setTrazo(recorrido);
        setPuntos(ordenados);

        if (mapRef.current && recorrido.length > 0) {
            mapRef.current.fitBounds(L.latLngBounds(recorrido), { padding: [50, 50] });
        }
    };

    // Obtener IDs de alquileres que no son los últimos
    const alquileresAgrupados = alquileres.reduce((acc, curr) => {
        const id = curr.alquiler.contenedor.id;
        acc[id] = acc[id] ? [...acc[id], curr] : [curr];
        return acc;
    }, {});

    const idsAlquileresAntiguos = new Set(
        Object.values(alquileresAgrupados).flatMap(lista =>
            lista.length > 1 ? lista.slice(0, -1).map(m => m.alquiler.id) : []
        )
    );

    const alquileresFiltrados = filtroContenedor
        ? alquileres.filter(m => m.alquiler.contenedor.id === filtroContenedor)
        : alquileres;

    if (cargando) return <Loader mensaje="Cargando ubicaciones..." />;

    return (
        <div style={{ height: "100%" }}>
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 0,
                }}
            >
                <MapContainer
                    key={isDarkMode ? "dark" : "light"}
                    center={[-34.6037, -58.3816]}
                    zoom={12}
                    whenCreated={(map) => (mapRef.current = map)}
                    style={{ width: "100%", height: "100%" }}
                >
                    <TileLayer
                        url={
                            isDarkMode
                                ? "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                                : "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                        }
                    />

                    {trazo.length > 0 && (
                        <Polyline positions={trazo} color="blue" weight={2} />
                    )}

                    {alquileresFiltrados.map((marcador, index) => {
                        const { alquiler, lat, lon } = marcador;
                        const clave = alquiler.contenedor.clave || "default";
                        const esAnterior = idsAlquileresAntiguos.has(alquiler.id);

                        if (esAnterior) {
                            const esPrimero = puntos[0]?.alquiler.id === alquiler.id;
                            const esUltimo = puntos[puntos.length - 1]?.alquiler.id === alquiler.id;
                            const color = esPrimero
                                ? "green"
                                : esUltimo
                                    ? "red"
                                    : "gray";

                            return (
                                <CircleMarker
                                    key={`circle-${alquiler.id}`}
                                    center={[lat, lon]}
                                    radius={8}
                                    pathOptions={{
                                        color,
                                        fillColor: color,
                                        fillOpacity: 0.7,
                                    }}
                                    eventHandlers={{
                                        click: () => manejarClickContenedor(alquiler.contenedor.id),
                                    }}
                                />
                            );
                        }

                        return (
                            <Marker
                                key={`marker-${alquiler.id}`}
                                position={[lat, lon]}
                                icon={obtenerIcono(clave)}
                                eventHandlers={{
                                    click: () => manejarClickContenedor(alquiler.contenedor.id),
                                }}
                            >
                                <ContenedorPopup alquiler={alquiler} puntos={puntos} />
                            </Marker>
                        );
                    })}
                </MapContainer>
            </div>
        </div>
    );
};

export default MapaDirecciones;
