// src/components/ContenedorForm.js
import React, {useState, useEffect} from 'react';
import {useParams, useNavigate, Link} from 'react-router-dom';
import {CheckCircleOutlined, ClockCircleOutlined, DeleteOutlined, DownOutlined, RightOutlined} from '@ant-design/icons';
import {
    Form,
    Input,
    Select,
    Checkbox,
    Button,
    Typography,
    Space,
    List,
    Card,
    message,
    Modal,
    DatePicker,
    Row,
    Col,
    Menu,
    Dropdown
} from 'antd';
import ContenedorService from '../../services/ContenedorService';
import moment from 'moment';
import {v4 as uuidv4} from 'uuid';
import Loader from "../sub/Loader";
import BotonHistorialContenedor from "./historialContenedor/BotonHistorialContenedor";


const tipos = ['REEFER', 'SECO'];
const clases = ['HIGH_CUBE', 'LOW_CUBE', 'OTRO'];
const longitudes = ['VEINTE_PIES', 'CUARENTA_PIES', 'OTRO'];

const {Text, Title} = Typography;
const {Option} = Select;

const ContenedorForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [reportes, setReportes] = useState([]);

    const [isModalVerReporteVisible, setIsModalVerReporteVisible] = useState(false);
    const [selectedReporte, setSelectedReporte] = useState(null);

    const [contenedor, setContenedor] = useState({
        codigo: '',
        tipo: '',
        clase: '',
        longitud: '',
        descripcion: '',
        disponible: true,
    });

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [nuevoReporte, setNuevoReporte] = useState({
        id: uuidv4(),
        descripcion: '',
        fechaReporte: moment().format('YYYY-MM-DD'),  // Fecha actual en el formato deseado
        resuelto: false
    });

    const [cargando, setCargando] = useState(false);

    useEffect(() => {
        if (id) {
            setCargando(true);
            Promise.all([
                ContenedorService.obtenerPorId(id).then(response => setContenedor(response.data)),
                ContenedorService.obtenerReportes(id).then(response => setReportes(response.data))
            ]).catch(() => message.error('Error al obtener datos del contenedor'))
                .finally(() => setCargando(false));
        }
    }, [id]);


    const handleVerMas = (reporte) => {
        setSelectedReporte(reporte);
        setIsModalVerReporteVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVerReporteVisible(false);
        setSelectedReporte(null);
    };

    const handleSubmit = () => {
        if (!contenedor.codigo.trim()) {
            message.warning('El código es obligatorio');
            return;
        }
        if (!contenedor.tipo || !contenedor.clase || !contenedor.longitud) {
            message.warning('Todos los campos son obligatorios');
        }
    };

    const handleDelete = () => {
        ContenedorService.obtenerAlquileresVinculados(id)
            .then(response => {
                const vinculados = response.data;
                if (vinculados.length > 0) {
                    Modal.warning({
                        title: 'No se puede eliminar el contenedor',
                        icon: null,
                        content: (
                            <>
                                <Text>Este contenedor está vinculado a los siguientes contratos:</Text>
                                <List
                                    size="small"
                                    dataSource={vinculados}
                                    style={{ marginTop: 12 }}
                                    renderItem={(a) => (
                                        <List.Item
                                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                        >
                                            <Text>
                                                {a.apodo
                                                    ? <b>{a.apodo}</b>
                                                    : a.empresa?.razonSocial || a.cliente?.nombre || 'Contrato sin nombre'}
                                            </Text>
                                            <Button
                                                size="small"
                                                type="link"
                                                href={`/alquileres/${a.id}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                                            >
                                                Ver <RightOutlined />
                                            </Button>

                                        </List.Item>
                                    )}
                                />
                            </>
                        )
                    });

                } else {
                    Modal.confirm({
                        title: '¿Estás seguro?',
                        content: 'Esta acción eliminará el contenedor permanentemente.',
                        okText: 'Sí, eliminar',
                        okType: 'danger',
                        cancelText: 'Cancelar',
                        onOk: () => {
                            ContenedorService.eliminar(id)
                                .then(() => {
                                    message.success('Contenedor eliminado');
                                    navigate('/contenedores');
                                })
                                .catch(() => message.error('Error al eliminar el contenedor'));
                        }
                    });
                }
            })
            .catch(() => message.error('No se pudo verificar si hay alquileres vinculados'));
    };


    const handleNuevoReporte = () => {
        setIsModalVisible(true);
    };

    const handleSaveReporte = () => {
        const reporte = {
            id: uuidv4(),  // Genera un nuevo UUID para el reporte
            descripcion: nuevoReporte.descripcion,
            fechaReporte: nuevoReporte.fechaReporte,
            resuelto: nuevoReporte.resuelto,
        };

        ContenedorService.agregarReporte(id, reporte)
            .then(() => {
                message.success('Reporte guardado exitosamente');
                setIsModalVisible(false);
                setNuevoReporte({descripcion: '', fechaReporte: moment().format('YYYY-MM-DD'), resuelto: false});
                ContenedorService.obtenerReportes(id).then((response) => setReportes(response.data));
            })
            .catch(() => message.error('Error al guardar el reporte'));
    };

    const actualizarEstado = (reporteId) => {
        message.warning('Actualizando estado...');

        // Buscar el reporte por el id en lugar de id
        const reporte = reportes.find((rep) => rep.id === reporteId);

        if (reporte) {
            const nuevoEstado = !reporte.resuelto;

            // Llamada a la función del servicio para actualizar el estado del reporte
            ContenedorService.actualizarReporte(id, reporteId, {...reporte, resuelto: nuevoEstado})
                .then(() => {
                    message.success('Estado actualizado correctamente');
                    setReportes(prev => prev.map(rep =>
                        rep.id === reporteId ? {...rep, resuelto: nuevoEstado} : rep
                    ));
                })
                .catch(() => message.error('Error al actualizar el estado del reporte'));

        } else {
            message.error('Reporte no encontrado');
        }
    };


    const handleEliminarReporte = (reporteId) => {
        ContenedorService.eliminarReporte(id, reporteId)
            .then(() => {
                message.success('Reporte eliminado exitosamente');
                ContenedorService.obtenerReportes(id).then((response) => setReportes(response.data));
            })
            .catch(() => message.error('Error al eliminar el reporte'));
    };

    if (cargando) {
        return <Loader mensaje="Cargando contenedor..."/>;
    }

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2}>{id ? 'Contenedor ' + contenedor.codigo : 'Crear Contenedor'}</Title>
            {id &&
                <>
                    <Form.Item>
                        {contenedor.disponible ? (
                            <Text style={{fontSize: '1.5rem', fontWeight: 'bold', color: 'lightgreen'}}>
                                Disponible <CheckCircleOutlined/>
                            </Text>
                        ) : (
                            <Text type="danger" style={{fontSize: '1.5rem', fontWeight: 'bold', color: 'red'}}>
                                No disponible <ClockCircleOutlined/>
                            </Text>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <BotonHistorialContenedor contenedor={contenedor} text={"Historial"}/>
                    </Form.Item>
                </>
            }
            <Form layout="vertical" onFinish={handleSubmit}>
                <Form.Item label="Código">
                    <Input
                        value={contenedor.codigo}
                        onChange={(e) => setContenedor({...contenedor, codigo: e.target.value})}
                    />
                </Form.Item>
                <Form.Item label="Tipo">
                    <Select value={contenedor.tipo}
                            onChange={(value) => setContenedor({...contenedor, tipo: value})}>
                        {tipos.map((tipo) => (
                            <Option key={tipo} value={tipo}>
                                {tipo}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Clase">
                    <Select value={contenedor.clase}
                            onChange={(value) => setContenedor({...contenedor, clase: value})}>
                        {clases.map((clase) => (
                            <Option key={clase} value={clase}>
                                {clase}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Longitud">
                    <Select value={contenedor.longitud}
                            onChange={(value) => setContenedor({...contenedor, longitud: value})}>
                        {longitudes.map((longitud) => (
                            <Option key={longitud} value={longitud}>
                                {longitud}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Descripción">
                    <Input.TextArea value={contenedor.descripcion}
                                    onChange={(e) => setContenedor({...contenedor, descripcion: e.target.value})}/>
                </Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Guardar
                    </Button>
                    {id && (
                        <Button danger onClick={handleDelete}>
                            Eliminar
                        </Button>
                    )}
                    <Button onClick={() => navigate('/contenedores')}>Volver</Button>
                </Space>
            </Form>

            {id && (
                <div style={{marginTop: '20px'}}>
                    <Title level={3}>Reportes</Title>
                    <Button type="dashed" onClick={handleNuevoReporte}>
                        Crear nuevo reporte
                    </Button>
                    <List
                        dataSource={reportes}
                        renderItem={(reporte, index) => (
                            <List.Item>
                                <Row style={{width: '100%'}} align="middle" gutter={[16, 0]}>
                                    {/* Columna de numeración */}
                                    <Col style={{width: '100%'}}>
                                        <Text style={{
                                            fontWeight: 'bold',
                                            fontSize: '1rem',
                                            color: 'gray'
                                        }}>{index + 1}. </Text>
                                        <b>{reporte.fechaReporte}</b>
                                    </Col>
                                    <Col>
                                        <Text
                                            style={{whiteSpace: 'pre-line'}}>{reporte.descripcion.length > 50 ? `${reporte.descripcion.substring(0, 50)}...` : reporte.descripcion}</Text>
                                        {reporte.descripcion.length > 50 && (
                                            <Button
                                                type="link"
                                                onClick={() => handleVerMas(reporte)}
                                                style={{
                                                    color: '#1890ff',
                                                    paddingLeft: 0,
                                                    paddingRight: 0
                                                }} // Estilo 'primary'
                                            >
                                                Ver más
                                            </Button>
                                        )}
                                    </Col>
                                    {/* Columna del ícono de estado */}
                                    <Col>
                                        <Text type={reporte.resuelto ? 'success' : 'warning'} style={{fontSize: 12}}>
                                            {reporte.resuelto ? 'Resuelto' : 'Pendiente'}
                                        </Text>
                                    </Col>
                                    <Col>
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    <Menu.Item onClick={() => actualizarEstado(reporte.id)}>
                                                        {reporte.resuelto ? 'Marcar como pendiente' : 'Marcar como resuelto'}
                                                    </Menu.Item>
                                                    <Menu.Item onClick={() => handleEliminarReporte(reporte.id)} danger>
                                                        Eliminar
                                                    </Menu.Item>
                                                </Menu>
                                            }
                                            trigger={['click']}
                                        >
                                            <Button type="link" icon={<DownOutlined/>}/>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </List.Item>
                        )}
                    />

                    {/* Modal para mostrar el contenido completo */}
                    {selectedReporte && (
                        <Modal
                            title="Reporte completo"
                            visible={isModalVerReporteVisible}
                            onCancel={handleCloseModal}
                            footer={null}
                            width={800}
                        >
                            <div>
                                <Title level={4}>{selectedReporte.fechaReporte}</Title>
                                <Text style={{whiteSpace: 'pre-line'}}>{selectedReporte.descripcion}</Text>
                            </div>
                        </Modal>
                    )}
                </div>
            )}

            <Modal
                title="Crear Nuevo Reporte"
                open={isModalVisible}
                onOk={handleSaveReporte}
                onCancel={() => setIsModalVisible(false)}
            >
                <Form layout="vertical">
                    <Form.Item label="Descripción">
                        <Input.TextArea
                            value={nuevoReporte.descripcion}
                            onChange={(e) => setNuevoReporte({...nuevoReporte, descripcion: e.target.value})}
                        />
                    </Form.Item>
                    <Form.Item label="Fecha del Reporte">
                        <DatePicker
                            value={moment(nuevoReporte.fechaReporte)}  // Convierte la fecha a un objeto moment
                            onChange={(date) =>
                                setNuevoReporte({
                                    ...nuevoReporte,
                                    fechaReporte: date ? date.format('YYYY-MM-DD') : null  // Actualiza la fecha en el estado
                                })
                            }
                        />
                    </Form.Item>


                    <Form.Item label="Resuelto">
                        <Checkbox
                            checked={nuevoReporte.resuelto}
                            onChange={(e) => setNuevoReporte({...nuevoReporte, resuelto: e.target.checked})}
                        >
                            Resuelto
                        </Checkbox>
                    </Form.Item>

                </Form>
            </Modal>
        </div>
    );
};

export default ContenedorForm;
