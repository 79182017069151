import React, {useState, useEffect} from 'react';
import {List, Button, Typography, Layout, message, Input, Select, Row, Col, Space} from 'antd';
import AlquilerService from '../../services/AlquilerService';
import {Link} from 'react-router-dom';
import {SortAscendingOutlined, SortDescendingOutlined} from '@ant-design/icons';
import TextoProximoPago from "./pago/pagoProximo/TextoProximoPago";
import dayjs from "dayjs";

const {Title} = Typography;
const {Content} = Layout;
const {Search} = Input;
const {Option} = Select;

const ListAlquiler = () => {
    const [alquileres, setAlquileres] = useState([]);
    const [filteredAlquileres, setFilteredAlquileres] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [activo, setActivo] = useState(null);
    const [orden, setOrden] = useState(null);

    useEffect(() => {
        AlquilerService.obtenerTodos()
            .then(res => {
                setAlquileres(res.data);
            })
            .catch(err => {
                message.error('Error fetching alquileres');
            });
    }, []);

    useEffect(() => {
        applyFilters();
    }, [alquileres, searchTerm, activo, orden]);


    const applyFilters = () => {
        let result = [...alquileres];

        if (searchTerm) {
            const term = searchTerm.toLowerCase();
            result = result.filter(a =>
                a.cliente?.nombre?.toLowerCase().includes(term) ||
                a.contenedor?.codigo?.toLowerCase().includes(term)
            );
        }

        if (activo !== null) {
            result = result.filter(a => a.activo === activo);
        }

        switch (orden) {
            case 'fechaInicioAsc':
                result.sort((a, b) => dayjs(a.fechaInicio).diff(dayjs(b.fechaInicio)));
                break;
            case 'fechaInicioDesc':
                result.sort((a, b) => dayjs(b.fechaInicio).diff(dayjs(a.fechaInicio)));
                break;
            case 'pagoProximoAsc':
                result.sort((a, b) =>
                    dayjs(a.fechaProximoPago).diff(dayjs()) - dayjs(b.fechaProximoPago).diff(dayjs())
                );
                break;
            case 'pagoProximoDesc':
                result.sort((a, b) =>
                    dayjs(b.fechaProximoPago).diff(dayjs()) - dayjs(a.fechaProximoPago).diff(dayjs())
                );
                break;
        }

        setFilteredAlquileres(result);
    };

    const limpiarFiltros = () => {
        setSearchTerm('');
        setActivo(null);
        setOrden(null);
    };


    return (
        <Layout style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Content style={{maxWidth: '1200px', margin: '0 auto'}}>
                <Title level={2} style={{textAlign: 'center'}}>Lista de Alquileres</Title>

                {/* Controles de búsqueda y filtros */}
                <Row gutter={[16, 16]} style={{marginBottom: 16, flexWrap: 'wrap'}}>
                    <Col flex="auto" style={{minWidth: '180px'}}>
                        <Search
                            placeholder="Buscar..."
                            allowClear
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Col>

                    <Col flex="auto" style={{minWidth: '150px'}}>
                        <Select
                            placeholder="Filtrar por estado"
                            value={activo}
                            onChange={(value) => setActivo(value)}
                            style={{width: '100%'}}
                            allowClear
                        >
                            <Option value={true}>Activos</Option>
                            <Option value={false}>Inactivos</Option>
                        </Select>
                    </Col>

                    <Col flex="auto" style={{minWidth: '200px'}}>
                        <Select
                            placeholder="Ordenar por"
                            value={orden}
                            onChange={(value) => setOrden(value)}
                            style={{width: '100%'}}
                            allowClear
                        >
                            <Option value="fechaInicioAsc"><SortAscendingOutlined/> Fecha de inicio</Option>
                            <Option value="fechaInicioDesc"><SortDescendingOutlined/> Fecha de inicio</Option>
                            <Option value="pagoProximoAsc"><SortAscendingOutlined/> Próximo pago</Option>
                            <Option value="pagoProximoDesc"><SortDescendingOutlined/> Próximo pago</Option>
                        </Select>
                    </Col>

                    <Col flex="none">
                        <Button size="small" onClick={limpiarFiltros} style={{marginTop: 2}}>
                            Limpiar
                        </Button>
                    </Col>
                </Row>


                {/* Alquileres filtrados */}
                <List
                    itemLayout="horizontal"
                    dataSource={filteredAlquileres}
                    renderItem={alquiler => (
                        <List.Item
                            style={{
                                marginBottom: '16px',
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                                minWidth: '350px',
                                padding: '16px',
                            }}
                            actions={[
                                <Link to={`/alquileres/${alquiler.id}`}>
                                    <Button type="link" style={{fontWeight: 'bold'}}>Ver</Button>
                                </Link>
                            ]}
                        >
                            <List.Item.Meta
                                title={
                                    <div style={{fontSize: '16px'}}>
                                        {alquiler.id && (
                                            alquiler.apodo ? (
                                                <span>{alquiler.apodo}</span>
                                            ) : (
                                                <span style={{
                                                    display: 'block',
                                                    color: alquiler.cliente ? 'inherit' : 'red'
                                                }}>
                                                    {alquiler.cliente ? alquiler.cliente.nombre : "SIN CLIENTE"}
                                                </span>
                                            )
                                        )}
                                        <span
                                            style={{display: 'block', color: alquiler.contenedor ? 'inherit' : 'red'}}>
                                                        {alquiler.contenedor ? alquiler.contenedor.codigo : "SIN CONTENEDOR"}
                                        </span>
                                    </div>
                                }
                                description={<TextoProximoPago fecha={alquiler.fechaProximoPago} type={"short"}/>}
                            />
                        </List.Item>
                    )}
                />

                <div style={{textAlign: 'center'}}>
                    <Link to="/alquileres/nuevo">
                        <Button type="primary" size="large" style={{borderRadius: '8px'}}>Crear nuevo alquiler</Button>
                    </Link>
                </div>
            </Content>
        </Layout>
    );
};

export default ListAlquiler;
